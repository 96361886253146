import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '../../hooks/common';
import { getEdgeDeviceByGenUnit } from '../../state/edgeDevices/edgeDeviceSlice';
import EdgeDeviceTelemetry from './EdgeDeviceTelemetry';
import AddEdgeDevice from './AddEdgeDevice';
import EdgeDeviceSpecs from './EdgeDeviceSpecs';
import DataCard from '../common/DataCard';

interface Props {
  generationUnitId: string;
}

const EdgeDeviceActivityCard = ({ generationUnitId }: Props) => {
  const edgeDevice = useAppSelector((state) =>
    getEdgeDeviceByGenUnit(state, generationUnitId),
  );

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography component="h3" variant="subtitle1" gutterBottom>
          Edge device
        </Typography>
      </Grid>
      <Grid item>
        {edgeDevice ? (
          <DataCard>
            <Grid container direction="row">
              <Grid item xs={12}>
                <Grid container direction="row">
                  <Grid item xs={10}>
                    <Typography component="h4" variant="h4">
                      {edgeDevice.manufacturer}
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Grid container justifyContent="flex-end">
                      <EdgeDeviceSpecs edgeDeviceId={edgeDevice.id} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <EdgeDeviceTelemetry
                  key={edgeDevice.id}
                  device={edgeDevice}
                />
              </Grid>
            </Grid>
          </DataCard>
        ) : (
          <AddEdgeDevice generationUnitId={generationUnitId} />
        )}
      </Grid>
    </Grid>
  );
};

export default EdgeDeviceActivityCard;
