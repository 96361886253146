import dayjs from 'dayjs';
import { useState } from 'react';
import Card from '@mui/material/Card';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import {
  DowntimeEventType,
  downtimeEventTypes,
  DowntimeEventViewModel,
  EventStatus,
  UpdatedDowntimeEvent,
} from '../../../state/events/eventTypes';
import { ModalForm } from '../../common/modalStyles';
import { useAppSelector } from '../../../hooks/common';
import DowntimeEventFields, {
  DowntimeEventFormValues,
} from './DowntimeEventFields';
import UpdateEventFormActions from '../UpdateEventFormActions';
import { RequestState } from '../../../state/requestTypes';
import { getElectrolyzerSelectOptsByGenerationUnitId } from '../../../state/electrolyzers/electrolyzerSlice';

interface Props {
  event: DowntimeEventViewModel;
  handleSubmit: (formValues: UpdatedDowntimeEvent) => Promise<void>;
  disabled: boolean;
  requestState: RequestState;
  handleClose: () => void;
}

const StatusFormControl = styled(FormControl)(() => ({
  minWidth: 130,
}));

const UpdateDowntimeEventForm = ({
  event,
  handleSubmit,
  handleClose,
  requestState,
  disabled,
}: Props) => {
  const electrolyzerSelectOpts = useAppSelector((state) =>
    getElectrolyzerSelectOptsByGenerationUnitId(
      state,
      event.generationUnitId,
    ),
  );

  const [
    downtimeEventSpecificFormValues,
    setDowntimeEventSpecificFormValues,
  ] = useState<DowntimeEventFormValues>({
    expectedStart: new Date(event.start),
    expectedEnd: new Date(event.end),
    type: event.type,
    electrolyzerId: event.electrolyzerId,
  });

  const [statusFormValue, setStatusFormValue] = useState<EventStatus>(
    event.status,
  );

  return (
    <ModalForm
      style={{ maxWidth: '1100px' }}
      onSubmit={async (e) => {
        e.preventDefault();

        await handleSubmit({
          electrolyzerId:
            downtimeEventSpecificFormValues.electrolyzerId as string,
          type: downtimeEventSpecificFormValues.type as DowntimeEventType,
          id: event.id,
          status: statusFormValue,
          expectedStart: dayjs(
            downtimeEventSpecificFormValues.expectedStart,
          )
            .second(0)
            .toISOString(),
          expectedEnd: dayjs(
            downtimeEventSpecificFormValues.expectedEnd,
          )
            .second(0)
            .toISOString(),
        });
      }}
    >
      <Card variant="outlined">
        <CardContent>
          <Grid container direction="row" spacing={1}>
            <DowntimeEventFields
              setFormValues={(formValues) => {
                setDowntimeEventSpecificFormValues((prevState) => ({
                  ...prevState,
                  ...formValues,
                }));
              }}
              formValues={downtimeEventSpecificFormValues}
              electrolyzerSelectOpts={electrolyzerSelectOpts}
              disabled={disabled}
              typeSelectOpts={Object.values(downtimeEventTypes)}
            />
          </Grid>
        </CardContent>
      </Card>
      <StatusFormControl>
        <InputLabel id="status-select-label">Status</InputLabel>
        <Select
          labelId="status-select-label"
          label="Status"
          value={statusFormValue}
          onChange={(e) =>
            setStatusFormValue(
              (e.target as HTMLSelectElement).value as EventStatus,
            )
          }
        >
          <MenuItem value={EventStatus.DRAFT}>
            {EventStatus.DRAFT}
          </MenuItem>
          <MenuItem value={EventStatus.CONFIRMED}>
            {EventStatus.CONFIRMED}
          </MenuItem>
        </Select>
      </StatusFormControl>

      <UpdateEventFormActions
        handleClose={handleClose}
        successMessage="Updated successfully"
        requestState={requestState}
      />
    </ModalForm>
  );
};

export default UpdateDowntimeEventForm;
