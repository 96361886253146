import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '../components/common/Paper';
import Stack from '@mui/material/Stack';

import { styled } from '@mui/material/styles';

import PowerCostEstimatorForm, {
  FormValues,
} from '../components/powerCostEstimator/PowerCostEstimatorForm';
import PowerCostResultCard from '../components/powerCostEstimator/PowerCostResultCard';
import { useAppDispatch, useAppSelector } from '../hooks/common';
import {
  fetchPowerCostEstimateAsync,
  getFetchPowerCostEstimateState,
  getPowerCostEstimate,
} from '../state/powerCostEstimate/powerCostEstimateSlice';
import { localDateToUtc } from '../components/common/dateConverter';
import { RequestStatuses } from '../state/requestTypes';
import ProductionChart from '../components/common/ProductionChart';
import { getSelectedGenerationUnitId } from '../state/generationUnits/generationUnitsSlice';
import Errors from '../components/common/Errors';
import { getUserValuesUnit } from '../state/user/userSlice';

const ChartContainer = styled('div')(() => ({
  /* Setting overfllow to hidden fixes a bug with the rechart legend styling
  that causes a scrollbar to appear at the bottom of the component displaying
  the cost estimator form and chart. */
  overflow: 'hidden',
  height: '220px',
}));

const PowerCostEstimator = () => {
  const dispatch = useAppDispatch();
  const userValuesUnit = useAppSelector(getUserValuesUnit);
  const estimate = useAppSelector((state) =>
    getPowerCostEstimate(state, userValuesUnit),
  );
  const fetchPowerCostEstimateState = useAppSelector(
    getFetchPowerCostEstimateState,
  );
  const selectedGenerationUnitId = useAppSelector(
    getSelectedGenerationUnitId,
  );

  const isLoading =
    fetchPowerCostEstimateState.status === RequestStatuses.pending;
  const error =
    fetchPowerCostEstimateState.status === RequestStatuses.rejected;

  const handleSubmit = (formValues: FormValues) => {
    dispatch(
      fetchPowerCostEstimateAsync({
        start: localDateToUtc(formValues.start).toISOString(),
        deadline: localDateToUtc(formValues.deadline).toISOString(),
        h2QuantityKg: parseInt(formValues.h2QuantityKg, 10),
        generationUnitId: selectedGenerationUnitId,
        ...(formValues.incentiveEligibleMassKg !== '' && {
          incentiveEligibleMassKg: parseInt(
            formValues.incentiveEligibleMassKg,
          ),
        }),
      }),
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="h1"
          component="h1"
          gutterBottom
          align="center"
        >
          Power Cost Calculator
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <Grid container spacing={2} direction="row">
            <Grid item xs={12} md={6}>
              <Stack spacing={2}>
                <PowerCostEstimatorForm
                  handleSubmit={handleSubmit}
                  isLoading={isLoading}
                  key={selectedGenerationUnitId}
                />
                {error && (
                  <Errors
                    errors={fetchPowerCostEstimateState.errors}
                  />
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={2} justifyContent="space-between">
                {estimate && (
                  <>
                    <PowerCostResultCard
                      estimate={estimate}
                      valuesUnit={userValuesUnit}
                    />
                    <ChartContainer>
                      <ProductionChart
                        data={estimate.productionPlanChartData}
                      />
                    </ChartContainer>
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default PowerCostEstimator;
