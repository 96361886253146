import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/common';
import EntityModal, { Operation } from '../common/EntityModal';
import { userHasEditPermission } from '../../state/user/userSlice';
import { StorageUnitViewModel } from '../../state/storageUnits/storageUnitTypes';
import StorageUnitFields from './StorageUnitFields';
import {
  clearUpdateStorageUnitStatus,
  getUpdateStorageUnitState,
  updateStorageUnitAsync,
} from '../../state/storageUnits/storageUnitSlice';
import { getElectrolyzerSelectOptsByGenerationUnitId } from '../../state/electrolyzers/electrolyzerSlice';
import { pickAll } from 'ramda';

const initialFormValues = {
  name: '',
  type: '',
  serialNumber: '',
  manufacturer: '',
  minCapacityKg: 0,
  totalCapacityKg: 0,
  electrolyzerIds: [''],
};

const getFormValues = (storageUnit: StorageUnitViewModel) =>
  pickAll(
    [
      'name',
      'type',
      'serialNumber',
      'manufacturer',
      'minCapacityKg',
      'totalCapacityKg',
      'electrolyzerIds',
    ],
    storageUnit,
  ) as StorageUnitViewModel;

interface Props {
  storageUnit: StorageUnitViewModel;
  generationUnitId: string;
}

export default function UpdateStorageUnit({
  storageUnit,
  generationUnitId,
}: Props) {
  const dispatch = useAppDispatch();
  const requestStatus = useAppSelector(getUpdateStorageUnitState);
  const electrolyzerSelectOpts = useAppSelector((state) =>
    getElectrolyzerSelectOptsByGenerationUnitId(
      state,
      generationUnitId,
    ),
  );
  const [formValues, setFormValues] =
    React.useState(initialFormValues);

  useEffect(() => {
    setFormValues(getFormValues(storageUnit));
  }, [storageUnit]);

  const canEdit = useAppSelector(userHasEditPermission);

  const editBtnLabel = () => {
    if (!canEdit) return 'You do not have permission to edit';
    return 'Edit technical specs';
  };

  const [error, setError] = React.useState('');
  return (
    <div>
      <EntityModal
        btnConfig={{
          label: editBtnLabel(),
          iconOnly: true,
        }}
        title="Edit Technical Specs"
        titleId="update-storage-unit-title"
        requestState={requestStatus}
        error={error}
        clearStatusAction={() =>
          dispatch(clearUpdateStorageUnitStatus())
        }
        clearFormValues={() => {}}
        disabled={!canEdit}
        handleSubmit={() => {
          try {
            setError('');

            dispatch(
              updateStorageUnitAsync({
                ...formValues,
                id: storageUnit.id,
              }),
            );
          } catch (error) {
            setError(
              'Failed to dispatch storage unit form data for submission.',
            );
          }
        }}
        operation={Operation.update}
      >
        <>
          {storageUnit && (
            <StorageUnitFields
              setFormValues={setFormValues}
              formValues={formValues}
              electrolyzerSelectOpts={electrolyzerSelectOpts}
            />
          )}
        </>
      </EntityModal>
    </div>
  );
}
