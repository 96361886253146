import React from 'react';
import { Autocomplete, Grid, TextField } from '@mui/material';
import DatePicker from '../../common/DatePicker';
import { ElectrolyzerSelectOptsViewModel } from '../../../state/electrolyzers/electrolyzerTypes';
import { DowntimeTypeSelectOptsViewModel } from '../../../state/events/eventTypes';
import calculateExpectedEnd from '../helpers/calculateExpectedEnd';

export interface DowntimeEventFormValues {
  expectedStart: null | Date;
  expectedEnd: null | Date;
  type: null | string;
  electrolyzerId: null | string;
}

interface Props {
  disabled?: boolean;
  setFormValues: (formValues: DowntimeEventFormValues) => void;
  formValues: DowntimeEventFormValues;
  typeSelectOpts: DowntimeTypeSelectOptsViewModel[];
  electrolyzerSelectOpts: ElectrolyzerSelectOptsViewModel[];
}

const DowntimeEventFields = ({
  disabled,
  setFormValues,
  formValues,
  typeSelectOpts,
  electrolyzerSelectOpts,
}: Props) => (
  <>
    <Grid item>
      <DatePicker
        disabled={disabled}
        label="Start"
        timeIntervals={15}
        value={formValues.expectedStart}
        handleChange={(value) =>
          setFormValues({
            ...formValues,
            expectedStart: value,
            expectedEnd: calculateExpectedEnd(
              value,
              formValues.expectedEnd,
            ),
          })
        }
      />
    </Grid>
    <Grid item>
      <DatePicker
        disabled={disabled}
        label="End"
        timeIntervals={15}
        value={formValues.expectedEnd}
        handleChange={(value) =>
          setFormValues({
            ...formValues,
            expectedEnd: value,
          })
        }
      />
    </Grid>
    <Grid item>
      <Autocomplete
        limitTags={1}
        id="electrolyzer"
        disabled={disabled}
        options={electrolyzerSelectOpts}
        getOptionLabel={(electrolyzer) => electrolyzer.label || ''}
        isOptionEqualToValue={(option, value) =>
          option.value === value.value
        }
        value={
          electrolyzerSelectOpts.find(
            ({ value }) => formValues.electrolyzerId === value,
          ) || null
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Electrolyzer"
            variant="filled"
            value={
              electrolyzerSelectOpts.find(
                ({ value }) => formValues.electrolyzerId === value,
              ) || null
            }
            required
            inputProps={{
              ...params.inputProps,
              required: formValues.electrolyzerId === null,
            }}
          />
        )}
        ChipProps={{ sx: { height: 25 } }}
        onChange={(e, electrolyzer) => {
          setFormValues({
            ...formValues,
            electrolyzerId: electrolyzer ? electrolyzer.value : null,
          });
        }}
        sx={{ width: 300 }}
      />
    </Grid>
    <Grid item>
      <Autocomplete
        id="type"
        options={typeSelectOpts}
        getOptionLabel={(type) => type.label || ''}
        isOptionEqualToValue={(option, value) =>
          option.value === value.value
        }
        onChange={(e, type) =>
          setFormValues({
            ...formValues,
            type: type ? type.value : null,
          })
        }
        value={
          typeSelectOpts.find(
            ({ value }) => formValues.type === value,
          ) || null
        }
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Type"
            value={
              typeSelectOpts.find(
                (o) => o.value === formValues.type,
              ) || null
            }
            required
            variant="filled"
            inputProps={{
              ...params.inputProps,
              required: formValues.type === null,
            }}
          />
        )}
        sx={{ width: 180 }}
      />
    </Grid>
  </>
);

export default DowntimeEventFields;
