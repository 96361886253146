import React, { useCallback } from 'react';
import { Modal } from '@mui/material';
import { ModalContents } from '../common/modalStyles';
import ModalTitle from '../common/ModalTitle';
import { useAppSelector, useAppDispatch } from '../../hooks/common';
import {
  getUpdateEventState,
  getEventById,
  clearUpdateEventStatus,
  updateEventAsync,
} from '../../state/events/eventSlice';
import {
  DowntimeEventViewModel,
  EventType,
  OfftakeEventViewModel,
  UpdatedDowntimeEvent,
  UpdatedOfftakeEvent,
} from '../../state/events/eventTypes';
import UpdateOfftakeEventForm from './offtake/UpdateOfftakeEventForm';
import { fetchRollingScheduleByGenerationUnitIdAsync } from '../../state/rollingSchedules/rollingSchedulesSlice';
import parseRequestState from '../../state/common/requestStateParser';
import UpdateDowntimeEventForm from './downtime/UpdateDowntimeEventForm';
import { rollingScheduleApi } from '../../app/services/rollingSchedule';

interface Props {
  open: boolean;
  onCloseModal: () => void;
  eventId: string;
  generationUnitId: string;
}

const UpdateEventModal = ({
  open,
  eventId,
  generationUnitId,
  onCloseModal,
}: Props) => {
  const event = useAppSelector((state) =>
    getEventById(state, eventId),
  );

  const dispatch = useAppDispatch();
  const requestState = useAppSelector(getUpdateEventState);
  const { isPending, isFulfilled } = parseRequestState(requestState);

  const { initiate: fetchRollingScheduleStatus } =
    rollingScheduleApi.endpoints.getRollingScheduleStatus;

  const handleClose = useCallback(() => {
    if (isPending) return;
    onCloseModal();
    dispatch(clearUpdateEventStatus());
  }, [isPending, onCloseModal, dispatch]);

  if (!event) return null;

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContents
        style={{
          width: '80%',
          height: '55%',
        }}
      >
        <ModalTitle
          text={`Update ${event.eventType}`}
          id="update-event-modal-title"
        />
        {event.eventType === EventType.OFFTAKE && (
          <UpdateOfftakeEventForm
            event={event as OfftakeEventViewModel}
            handleSubmit={async (formValues: UpdatedOfftakeEvent) => {
              await dispatch(updateEventAsync(formValues));
              await dispatch(
                fetchRollingScheduleByGenerationUnitIdAsync(
                  generationUnitId,
                ),
              );
              await dispatch(
                fetchRollingScheduleStatus(generationUnitId, {
                  forceRefetch: true,
                }),
              );
            }}
            disabled={isPending || isFulfilled}
            handleClose={handleClose}
            requestState={requestState}
          />
        )}
        {event.eventType === EventType.DOWNTIME && (
          <UpdateDowntimeEventForm
            event={event as DowntimeEventViewModel}
            handleSubmit={async (
              formValues: UpdatedDowntimeEvent,
            ) => {
              await dispatch(updateEventAsync(formValues));
              await dispatch(
                fetchRollingScheduleByGenerationUnitIdAsync(
                  generationUnitId,
                ),
              );
              await dispatch(
                fetchRollingScheduleStatus(generationUnitId, {
                  forceRefetch: true,
                }),
              );
            }}
            disabled={isPending || isFulfilled}
            handleClose={handleClose}
            requestState={requestState}
          />
        )}
      </ModalContents>
    </Modal>
  );
};

export default UpdateEventModal;
