import React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import { storageUnitTypes } from './constants';
import { ElectrolyzerSelectOptsViewModel } from '../../state/electrolyzers/electrolyzerTypes';

interface FormValues {
  name: string;
  type: string;
  serialNumber: string;
  manufacturer: string;
  minCapacityKg: number;
  totalCapacityKg: number;
  electrolyzerIds: string[];
}

interface Props {
  disabled?: boolean;
  setFormValues: (formValues: FormValues) => void;
  formValues: FormValues;
  electrolyzerSelectOpts: ElectrolyzerSelectOptsViewModel[];
}

const StorageUnitFields = ({
  disabled,
  setFormValues,
  formValues,
  electrolyzerSelectOpts,
}: Props) => (
  <Stack spacing={3}>
    <TextField
      fullWidth
      id="name"
      required
      onInput={(e) =>
        setFormValues({
          ...formValues,
          name: (e.target as HTMLTextAreaElement).value,
        })
      }
      label="Name"
      variant="filled"
      value={formValues.name}
      disabled={disabled}
    />
    <FormControl fullWidth>
      <InputLabel id="select-storage-unit-type-label" required>
        Type
      </InputLabel>
      <Select
        labelId="select-storage-unit-type-label"
        id="type"
        required
        value={formValues.type}
        label="Type"
        onChange={(e) =>
          setFormValues({
            ...formValues,
            type: (e.target as HTMLSelectElement).value,
          })
        }
        disabled={disabled}
      >
        {storageUnitTypes.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
    <TextField
      fullWidth
      id="serial-number"
      required
      onInput={(e) =>
        setFormValues({
          ...formValues,
          serialNumber: (e.target as HTMLTextAreaElement).value,
        })
      }
      label="Serial No."
      variant="filled"
      value={formValues.serialNumber}
      disabled={disabled}
    />
    <TextField
      fullWidth
      id="manufacturer"
      required
      onInput={(e) =>
        setFormValues({
          ...formValues,
          manufacturer: (e.target as HTMLTextAreaElement).value,
        })
      }
      label="Manufacturer"
      variant="filled"
      value={formValues.manufacturer}
      disabled={disabled}
    />
    <TextField
      fullWidth
      id="minCapacityKg"
      required
      type="number"
      onInput={(e) =>
        setFormValues({
          ...formValues,
          minCapacityKg: Number(
            (e.target as HTMLTextAreaElement).value,
          ),
        })
      }
      label="Min Capacity (kg)"
      variant="filled"
      value={formValues.minCapacityKg}
      disabled={disabled}
    />
    <TextField
      fullWidth
      id="totalCapacityKg"
      required
      type="number"
      onInput={(e) =>
        setFormValues({
          ...formValues,
          totalCapacityKg: Number(
            (e.target as HTMLTextAreaElement).value,
          ),
        })
      }
      label="Total Capacity (kg)"
      variant="filled"
      value={formValues.totalCapacityKg}
      disabled={disabled}
    />
    <Autocomplete
      multiple
      id="electrolyzers"
      options={electrolyzerSelectOpts}
      value={electrolyzerSelectOpts.filter(({ value }) =>
        formValues.electrolyzerIds.includes(value),
      )}
      onChange={(e, electrolyzerOpts) =>
        electrolyzerOpts &&
        setFormValues({
          ...formValues,
          electrolyzerIds: electrolyzerOpts.map(
            (electrolyzerOpt) => electrolyzerOpt.value,
          ),
        })
      }
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Electrolyzers"
          value={formValues.electrolyzerIds}
          required
          variant="filled"
          inputProps={{
            ...params.inputProps,
            required:
              formValues.electrolyzerIds.length < 1 ||
              formValues.electrolyzerIds[0] === '',
          }}
        />
      )}
    />
  </Stack>
);

export default StorageUnitFields;
