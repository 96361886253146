import React from 'react';

import {
  addUnitAsync,
  getAddUnitState,
  clearAddUnitStatus,
} from '../../state/generationUnits/generationUnitsSlice';
import { getOptionGenerators as getForecastSourceOptionsGenerators } from '../../state/forecastSources/forecastSourcesSlice';

import { useAppDispatch, useAppSelector } from '../../hooks/common';
import EntityModal from '../common/EntityModal';
import GenerationUnitFields from './GenerationUnitFields';

const initialFormValues = {
  name: '',
  lat: '',
  long: '',
  forecastSources: [''],
  maxBalanceOfPlantKw: '',
  productionIssueEmailList: '',
  maxGridImportKw: '',
  renewableIncentivePencePerKg: '',
};

export default function AddGenerationUnit() {
  const dispatch = useAppDispatch();
  const requestState = useAppSelector(getAddUnitState);
  const [formValues, setFormValues] =
    React.useState(initialFormValues);
  const forecastSourceOptions = useAppSelector(
    getForecastSourceOptionsGenerators,
  );

  return (
    <div>
      <EntityModal
        btnConfig={{ label: 'Add generation unit' }}
        title="Add a new generation unit"
        titleId="add-generation-unit-title"
        requestState={requestState}
        clearStatusAction={() => dispatch(clearAddUnitStatus())}
        clearFormValues={() => setFormValues(initialFormValues)}
        handleSubmit={() => {
          const {
            name,
            lat,
            long,
            forecastSources,
            maxBalanceOfPlantKw,
            productionIssueEmailList,
            renewableIncentivePencePerKg,
          } = formValues;

          dispatch(
            addUnitAsync({
              name,
              lat: parseFloat(lat),
              long: parseFloat(long),
              maxBalanceOfPlantKw: parseInt(maxBalanceOfPlantKw),
              forecastSources,
              productionIssueEmailList:
                productionIssueEmailList.trim(),
              renewableIncentivePencePerKg: parseFloat(
                renewableIncentivePencePerKg,
              ),
            }),
          );
        }}
      >
        <GenerationUnitFields
          setFormValues={setFormValues}
          formValues={formValues}
          forecastSourceOptions={forecastSourceOptions}
        />
      </EntityModal>
    </div>
  );
}
