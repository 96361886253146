import agent from 'axios';
import qs from 'qs';
import * as R from 'ramda';
import { handleApiError } from '../common/apiErrorHandler';
import {
  EventPlanningInputs,
  BestEventTimeOptions,
} from './planEventTypes';

interface FetchBestEventTimesResponse {
  data: { eventPlanOptions: BestEventTimeOptions };
}

export async function fetchBestEventTimes(
  eventPlanningInputs: EventPlanningInputs,
) {
  return await handleApiError<{
    data: BestEventTimeOptions;
  }>(
    async () =>
      agent
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/generation-units/${
            eventPlanningInputs.generationUnitId
          }/best-event-times?${qs.stringify(
            R.omit(['generationUnitId'], eventPlanningInputs),
          )}`,
        )
        .then(({ data }: FetchBestEventTimesResponse) => ({
          data: data && data.eventPlanOptions,
        })),
    'Failed to find the best event times',
  );
}
