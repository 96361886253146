import React from 'react';
import dayjs from 'dayjs';
import * as R from 'ramda';
import { useTheme } from '@mui/material/styles';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
  ComposedChart,
} from 'recharts';
import Alert from '@mui/material/Alert';
import { useAppSelector } from '../../../hooks/common';
import {
  getFetchLatestForecastState,
  getLatestGridPriceForecastChartData,
} from '../../../state/forecasts/forecastsSlice';
import { RequestStatuses } from '../../../state/requestTypes';
import { getXAxisValuesForForecastViewWindow } from '../../../state/charts/chartsSlice';
import fullWidthChartStyles from '../../common/fullWidthChartStyles';
import ChartTooltip from '../../common/ChartTooltip';
import Errors from '../../common/Errors';

interface Props {
  generationUnitId: string;
  forecastSourcePlatformId: string;
}

const GridPriceForecastChart = ({
  generationUnitId,
  forecastSourcePlatformId,
}: Props) => {
  const theme = useTheme();

  const forecastChartData = useAppSelector((state) =>
    getLatestGridPriceForecastChartData(
      state,
      generationUnitId,
      forecastSourcePlatformId,
    ),
  );

  const forecastFetchState = useAppSelector((state) =>
    getFetchLatestForecastState(state),
  );

  const xAxisValues = useAppSelector(
    getXAxisValuesForForecastViewWindow,
  );

  if (!xAxisValues) {
    return (
      <Alert severity="error">Unable to format chart data</Alert>
    );
  }

  const renderTooltip = ({
    active,
    payload,
  }: TooltipProps<number, string>) => {
    if (active && payload) {
      const { price, periodStartLabel, periodEndLabel } =
        payload[0]?.payload;
      return (
        <ChartTooltip>
          <p className="label">
            Price of {price} pence/kWh <br />
            between {periodStartLabel} and {periodEndLabel}
          </p>
        </ChartTooltip>
      );
    }
    return <></>;
  };

  if (forecastChartData) {
    return (
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart
          {...fullWidthChartStyles()}
          data={forecastChartData}
        >
          <CartesianGrid
            strokeWidth={0.5}
            strokeDasharray="2 2"
            stroke={theme.palette.grey[500]}
          />
          <XAxis
            domain={[xAxisValues[0], R.last(xAxisValues) as number]}
            type="number"
            ticks={xAxisValues}
            dataKey="periodStart"
            allowDataOverflow={true}
            stroke={theme.palette.grey[300]}
            tickFormatter={(date) => dayjs(date).format('HH:mm ddd')}
          />
          <YAxis stroke={theme.palette.grey[300]} />
          <Legend />
          <Tooltip
            content={renderTooltip}
            wrapperStyle={{ outline: 'none' }}
          />
          <Line
            name="Price (pence/kWh)"
            dataKey="price"
            type="stepAfter"
            stroke={theme.palette.warning.light}
            dot={false}
            activeDot={{ fill: theme.palette.secondary.light }}
            strokeWidth={2}
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  }

  if (forecastFetchState.status === RequestStatuses.rejected) {
    return <Errors errors={forecastFetchState.errors} />;
  }

  return <></>;
};

export default GridPriceForecastChart;
