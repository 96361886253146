import dayjs from 'dayjs';

const FIFTEEN_MINUTES_IN_MS = 15 * 60 * 1000;

const getExpectedEndFormValue = (
  expectedStart: string,
  expectedEnd: string,
): Date => {
  const originalDiff = dayjs(expectedEnd).diff(
    expectedStart,
    'seconds',
  );

  const newDate = dayjs().add(originalDiff, 'seconds').toDate();

  return new Date(
    Math.ceil(newDate.getTime() / FIFTEEN_MINUTES_IN_MS) *
      FIFTEEN_MINUTES_IN_MS,
  );
};

export default getExpectedEndFormValue;
