import React, { MouseEvent, useEffect } from 'react';
import { styled, Grid, Typography } from '@mui/material';
import OfftakeEventRow from './offtake/OfftakeEventRow';
import Paper from '../common/Paper';
import { useAppDispatch, useAppSelector } from '../../hooks/common';
import {
  clearDeleteEventStatus,
  clearEndOfftakeEventStatus,
  deleteEventAsync,
  endEventAsync,
  fetchEventsByGenerationUnitIdAsync,
  getDeleteOfftakeEventState,
  getEndEventState,
  getEventsByGenerationUnitId,
  startEventAsync,
} from '../../state/events/eventSlice';
import { GenerationUnitViewModel } from '../../state/generationUnits/generationUnitTypes';
import FailureNotification from '../common/FailureNotification';
import { RequestStatuses } from '../../state/requestTypes';
import { fetchRollingScheduleByGenerationUnitIdAsync } from '../../state/rollingSchedules/rollingSchedulesSlice';
import { EventType } from '../../state/events/eventTypes';
import DowntimeEventRow from './downtime/DowntimeEventRow';
import EventTableFilters from './EventTableFilters';
import useEventsTable from './useEventsTable';
import { getUserValuesUnit } from '../../state/user/userSlice';
import StartEventModal from './offtake/StartEventModal';
import UpdateEventModal from './UpdateEventModal';
import { rollingScheduleApi } from '../../app/services/rollingSchedule';

interface Props {
  generationUnit: GenerationUnitViewModel;
}

const EventHeaders = styled(Grid)(() => ({
  padding: '8px 36px 8px 16px',
}));

const TableColumnHeading = ({ name }: { name: string }) => (
  <Typography component="h3" variant="subtitle1" fontWeight="bold">
    {name}
  </Typography>
);

const EventsTable = ({ generationUnit }: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchEventsByGenerationUnitIdAsync(generationUnit.id));
  }, [dispatch, generationUnit.id]);

  const userValuesUnit = useAppSelector(getUserValuesUnit);

  const events = useAppSelector((state) =>
    getEventsByGenerationUnitId(
      state,
      generationUnit.id,
      userValuesUnit,
    ),
  );

  const deleteState = useAppSelector(getDeleteOfftakeEventState);
  const endEventState = useAppSelector(getEndEventState);

  const { initiate: fetchRollingScheduleStatus } =
    rollingScheduleApi.endpoints.getRollingScheduleStatus;

  const {
    rows,
    prepareRow,
    updatePastEventsFilter,
    updateEventStatusFilter,
    statusFilterState,
  } = useEventsTable(events);

  const handleStartEvent = async (formValues: {
    eventId: string;
    expectedEnd: string;
    h2QuantityKg?: number;
    renewableIncentiveEligibleMassKg?: number;
  }) => {
    await dispatch(startEventAsync(formValues));
    await dispatch(
      fetchRollingScheduleByGenerationUnitIdAsync(generationUnit.id),
    );
    await dispatch(
      fetchRollingScheduleStatus(generationUnit.id, {
        forceRefetch: true,
      }),
    );
  };

  const [startEventModalId, setStartEventModalId] =
    React.useState('');

  const [updateEventModalId, setUpdateEventModalId] =
    React.useState('');

  if (!events.length)
    return (
      <Grid container direction="column" alignItems="center">
        <span>No offtake or downtime is booked for this unit.</span>
      </Grid>
    );

  return (
    <>
      <Grid item xs={12}>
        <Paper>
          <EventTableFilters
            updateEventStatusFilter={updateEventStatusFilter}
            updatePastEventsFilter={updatePastEventsFilter}
            statusFilterState={statusFilterState}
          />
          <EventHeaders container>
            <Grid item xs={2} paddingLeft={5}>
              <TableColumnHeading name="Status" />
            </Grid>
            <Grid item xs={2} paddingLeft={4.6}>
              <TableColumnHeading name="Start" />
            </Grid>
            <Grid item xs={2} paddingLeft={4.1}>
              <TableColumnHeading name="End" />
            </Grid>
            <Grid item xs={2} paddingLeft={3.5}>
              <TableColumnHeading name="Type" />
            </Grid>
            <Grid item xs={1} paddingLeft={3.5}>
              <TableColumnHeading name="Details" />
            </Grid>
            <Grid item xs={1} lg={3}></Grid>
          </EventHeaders>
          {rows.map((row) => {
            prepareRow(row);
            const event = row.original;
            return event.eventType === EventType.OFFTAKE ? (
              <OfftakeEventRow
                key={event.id}
                event={event}
                isPendingDeletion={
                  deleteState.entityId === event.id &&
                  deleteState.status === RequestStatuses.pending
                }
                handleDelete={async (e: MouseEvent) => {
                  e.stopPropagation();
                  await dispatch(deleteEventAsync(event.id));
                  await dispatch(
                    fetchRollingScheduleByGenerationUnitIdAsync(
                      generationUnit.id,
                    ),
                  );
                  await dispatch(
                    fetchRollingScheduleStatus(generationUnit.id, {
                      forceRefetch: true,
                    }),
                  );
                }}
                isPendingEnd={
                  endEventState.entityId === event.id &&
                  endEventState.status === RequestStatuses.pending
                }
                handleEnd={async (e: MouseEvent) => {
                  e.stopPropagation();
                  await dispatch(endEventAsync(event.id));
                  await dispatch(
                    fetchRollingScheduleByGenerationUnitIdAsync(
                      generationUnit.id,
                    ),
                  );
                  await dispatch(
                    fetchRollingScheduleStatus(generationUnit.id, {
                      forceRefetch: true,
                    }),
                  );
                }}
                onClickStart={async (e: MouseEvent) => {
                  e.stopPropagation();
                  setStartEventModalId(event.id);
                }}
                onClickEdit={async (e: MouseEvent) => {
                  e.stopPropagation();
                  setUpdateEventModalId(event.id);
                }}
                valuesUnit={userValuesUnit}
              />
            ) : (
              <DowntimeEventRow
                key={event.id}
                event={event}
                isPendingDeletion={
                  deleteState.entityId === event.id &&
                  deleteState.status === RequestStatuses.pending
                }
                isPendingEnd={
                  endEventState.entityId === event.id &&
                  endEventState.status === RequestStatuses.pending
                }
                handleEnd={async (e: MouseEvent) => {
                  e.stopPropagation();
                  await dispatch(endEventAsync(event.id));
                  await dispatch(
                    fetchRollingScheduleByGenerationUnitIdAsync(
                      generationUnit.id,
                    ),
                  );
                }}
                handleDelete={async (e: MouseEvent) => {
                  e.stopPropagation();
                  await dispatch(deleteEventAsync(event.id));
                  await dispatch(
                    fetchRollingScheduleByGenerationUnitIdAsync(
                      generationUnit.id,
                    ),
                  );
                }}
                onClickStart={async (e: MouseEvent) => {
                  e.stopPropagation();
                  setStartEventModalId(event.id);
                }}
                onClickEdit={async (e: MouseEvent) => {
                  e.stopPropagation();
                  setUpdateEventModalId(event.id);
                }}
              />
            );
          })}
        </Paper>
      </Grid>
      <FailureNotification
        errors={deleteState.errors}
        handleClose={() => dispatch(clearDeleteEventStatus())}
      />
      <FailureNotification
        errors={endEventState.errors}
        handleClose={() => dispatch(clearEndOfftakeEventStatus())}
      />
      <StartEventModal
        eventId={startEventModalId}
        open={Boolean(startEventModalId)}
        onCloseModal={() => setStartEventModalId('')}
        handleStart={handleStartEvent}
      />
      <UpdateEventModal
        eventId={updateEventModalId}
        generationUnitId={generationUnit.id}
        open={Boolean(updateEventModalId)}
        onCloseModal={() => setUpdateEventModalId('')}
      />
    </>
  );
};

export default EventsTable;
