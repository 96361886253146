import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import * as R from 'ramda';
import { RootState } from '../../app/store';
import {
  addElectrolyzer,
  fetchElectrolyzers,
  updateElectrolyzer,
} from './electrolyzersAPI';
import userFriendlyLoadingTime from '../userFriendlyLoadingTime';
import { RequestStatuses, RequestState } from '../requestTypes';
import {
  NewElectrolyzer,
  ElectrolyzerViewModel,
  ElectrolyzersViewModel,
  UpdatedElectrolyzer,
  ElectrolyzerSelectOptsViewModel,
} from './electrolyzerTypes';
import entitySliceBuilder from '../common/entitySliceCreator';
import { EntityState } from '../common/entitySliceCreator';
import { RejectValue } from '../common/errorTypes';
import { executeWithRejectValue } from '../common/executeWithRejectValue';
import createElectrolyzerDisplayName from './displayNameCreator';

export interface ElectrolyzerState {
  addEntityState: RequestState;
  updateEntityState: RequestState;
  entities: { [key: string]: ElectrolyzerViewModel } | {};
}

const initialState = {
  entities: {},
  addEntityState: {
    status: RequestStatuses.idle,
    errors: [],
  },
  updateEntityState: {
    status: RequestStatuses.idle,
    errors: [],
  },
};

export const fetchElectrolyzersAsync = createAsyncThunk<
  ElectrolyzersViewModel,
  void,
  RejectValue
>(
  'electrolyzers/fetchElectrolyzers',
  async (_0, { rejectWithValue }) => {
    return await executeWithRejectValue(async () => {
      const response = await fetchElectrolyzers();
      return response.data;
    }, rejectWithValue);
  },
);

const timeout = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const addElectrolyzerAsync = createAsyncThunk<
  ElectrolyzerViewModel,
  NewElectrolyzer,
  RejectValue
>(
  'electrolyzers/addElectrolyzer',
  async (newElectrolyzer: NewElectrolyzer, { rejectWithValue }) => {
    return await executeWithRejectValue(async () => {
      const response = await addElectrolyzer(newElectrolyzer);
      await timeout(userFriendlyLoadingTime);
      return response.data;
    }, rejectWithValue);
  },
);

export const updateElectrolyzerAsync = createAsyncThunk<
  ElectrolyzerViewModel,
  UpdatedElectrolyzer,
  RejectValue
>(
  'electrolyzers/updateElectrolyzer',
  async (
    updatedElectrolyzer: UpdatedElectrolyzer,
    { rejectWithValue },
  ) => {
    return await executeWithRejectValue(async () => {
      const response = await updateElectrolyzer(updatedElectrolyzer);
      await timeout(userFriendlyLoadingTime);
      return response.data;
    }, rejectWithValue);
  },
);

export const clearAddElectrolyzerStatus = createAction(
  'electrolyzers/clearAddElectrolyzerStatus',
);

export const clearUpdateElectrolyzerStatus = createAction(
  'electrolyzers/clearUpdateElectrolyzerStatus',
);

const { entityReducers, extraEntityReducers } = entitySliceBuilder<
  NewElectrolyzer,
  ElectrolyzerViewModel
>(
  addElectrolyzerAsync,
  fetchElectrolyzersAsync,
  clearAddElectrolyzerStatus.type,
);

export const electrolyzersSlice = createSlice({
  name: 'electrolyzers',
  initialState,
  reducers: {
    ...entityReducers,
    clearUpdateElectrolyzerStatus: (state: EntityState) => {
      state.updateEntityState = {
        status: RequestStatuses.idle,
        errors: [],
      };
    },
  },
  extraReducers: (builder) => {
    extraEntityReducers(
      builder
        .addCase(
          updateElectrolyzerAsync.pending,
          (state: ElectrolyzerState) => {
            state.updateEntityState = {
              status: RequestStatuses.pending,
              errors: [],
            };
          },
        )
        .addCase(
          updateElectrolyzerAsync.rejected,
          (state: ElectrolyzerState, action) => {
            state.updateEntityState = {
              status: RequestStatuses.rejected,
              errors: action.payload?.errors,
            };
          },
        )
        .addCase(
          updateElectrolyzerAsync.fulfilled,
          (state, action: PayloadAction<ElectrolyzerViewModel>) => {
            if (action.payload) {
              state.entities = {
                ...state.entities,
                [action.payload.id]: action.payload,
              };
            }

            state.updateEntityState = {
              status: RequestStatuses.fulfilled,
              errors: [],
            };
          },
        ),
    );
  },
});

export const getAddElectrolyzerState = (
  state: RootState,
): RequestState => state.electrolyzers.addEntityState;

export const getUpdateElectrolyzerState = (
  state: RootState,
): RequestState => state.electrolyzers.updateEntityState;

export const getElectrolyzerEntities = (
  state: RootState,
): { [id: string]: ElectrolyzerViewModel } =>
  state.electrolyzers.entities;

export const getElectrolyzers = (
  state: RootState,
): ElectrolyzerViewModel[] =>
  Object.values(state.electrolyzers.entities);

export const getElectrolyzerById = (
  state: RootState,
  id: string,
): ElectrolyzerViewModel | null =>
  R.path([id], state.electrolyzers.entities) || null;

export const getElectrolyzerDisplayNameById = (
  state: RootState,
  id: string,
): string => {
  const electrolyzer = getElectrolyzerById(state, id);
  return createElectrolyzerDisplayName(electrolyzer);
};

export const getElectrolyzersByGenerationUnitId = (
  state: RootState,
  generationUnitId: string,
): ElectrolyzerViewModel[] | null => {
  const allElectrolyzers = Object.values(
    state.electrolyzers.entities,
  ) as ElectrolyzerViewModel[] | [];

  return allElectrolyzers.filter(
    (electrolyzer) =>
      electrolyzer.generationUnitId === generationUnitId,
  );
};

export const getElectrolyzerSelectOptsByGenerationUnitId = (
  state: RootState,
  generationUnitId: string | undefined,
): ElectrolyzerSelectOptsViewModel[] => {
  const allElectrolyzers = Object.values(
    state.electrolyzers.entities,
  ) as ElectrolyzerViewModel[] | [];

  return allElectrolyzers
    .filter((e) => e.generationUnitId === generationUnitId)
    .map((e) => ({ value: e.id, label: e.serialNumber }));
};

export default electrolyzersSlice.reducer;
