import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { EventType } from '../../../../state/events/eventTypes';
import { BestEventTimeOption } from '../../../../state/planEvent/planEventTypes';
import DowntimeEventDetails from './DowntimeEventDetails';

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  minHeight: '150px',
}));

interface Props {
  eventOption: BestEventTimeOption;
}

const SelectedEventDetails = ({ eventOption }: Props) => {
  const [event] = eventOption.eventsToCreate;

  return (
    <>
      <Typography variant="h4" gutterBottom>
        Selected event details
      </Typography>
      <StyledPaper>
        {event.eventType === EventType.DOWNTIME ? (
          <DowntimeEventDetails event={event} />
        ) : (
          <Typography variant="body1" component="span">
            Placeholder for offtake event details
          </Typography>
        )}
      </StyledPaper>
    </>
  );
};

export default SelectedEventDetails;
