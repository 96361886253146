import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/common';
import EntityModal, { Operation } from '../common/EntityModal';
import GenerationUnitFields from './GenerationUnitFields';
import { GenerationUnitViewModel } from '../../state/generationUnits/generationUnitTypes';
import {
  clearUpdateUnitStatus,
  getUpdateUnitState,
  updateUnitAsync,
} from '../../state/generationUnits/generationUnitsSlice';
import { getOptionGenerators as getForecastSourceOptionsGenerators } from '../../state/forecastSources/forecastSourcesSlice';
import { userHasEditPermission } from '../../state/user/userSlice';

const initialFormValues = {
  name: '',
  lat: '',
  long: '',
  forecastSources: [''],
  maxBalanceOfPlantKw: '',
  maxGridImportKw: '',
  productionIssueEmailList: '',
  renewableIncentivePencePerKg: '',
};

const getFormValues = (
  generationUnit: GenerationUnitViewModel | null,
) => {
  if (generationUnit) {
    const [lat, long] = generationUnit.latlng;
    return {
      name: generationUnit.name,
      lat: lat.toString(),
      long: long.toString(),
      maxBalanceOfPlantKw:
        generationUnit.maxBalanceOfPlantKw.toString(),
      forecastSources: generationUnit.forecastSources.map(
        (fs) => fs.id,
      ),
      productionIssueEmailList:
        generationUnit.productionIssueEmailList || '',
      maxGridImportKw: generationUnit.maxGridImportKw
        ? generationUnit.maxGridImportKw.toString()
        : '',
      renewableIncentivePencePerKg:
        generationUnit.renewableIncentivePencePerKg
          ? generationUnit.renewableIncentivePencePerKg.toString()
          : '',
    };
  }
  return initialFormValues;
};
interface Props {
  generationUnit: GenerationUnitViewModel;
}

export default function UpdateGenerationUnit({
  generationUnit,
}: Props) {
  const dispatch = useAppDispatch();
  const requestStatus = useAppSelector(getUpdateUnitState);

  const [formValues, setFormValues] =
    React.useState(initialFormValues);
  const forecastSourceOptions = useAppSelector(
    getForecastSourceOptionsGenerators,
  );

  useEffect(() => {
    setFormValues(getFormValues(generationUnit));
  }, [generationUnit]);

  const canEdit = useAppSelector(userHasEditPermission);

  const editBtnLabel = () => {
    if (!canEdit) return 'You do not have permission to edit';
    return 'Edit technical specs';
  };

  const [error, setError] = React.useState('');
  return (
    <div>
      <EntityModal
        btnConfig={{
          label: editBtnLabel(),
          iconOnly: true,
        }}
        title="Edit Technical Specs"
        titleId="update-generation-unit-title"
        requestState={requestStatus}
        error={error}
        clearStatusAction={() => dispatch(clearUpdateUnitStatus())}
        clearFormValues={() => {}}
        disabled={!canEdit}
        handleSubmit={() => {
          try {
            setError('');
            const {
              name,
              lat,
              long,
              maxBalanceOfPlantKw,
              maxGridImportKw,
              forecastSources,
              productionIssueEmailList,
              renewableIncentivePencePerKg,
            } = formValues;

            dispatch(
              updateUnitAsync({
                id: generationUnit.id,
                name,
                lat: parseFloat(lat),
                long: parseFloat(long),
                maxBalanceOfPlantKw: parseInt(maxBalanceOfPlantKw),
                forecastSources,
                productionIssueEmailList:
                  productionIssueEmailList.trim(),
                maxGridImportKw:
                  maxGridImportKw.trim() === ''
                    ? null
                    : parseInt(maxGridImportKw),
                renewableIncentivePencePerKg: parseFloat(
                  renewableIncentivePencePerKg,
                ),
              }),
            );
          } catch (error) {
            setError(
              'Failed to transform generation unit form data for submission.',
            );
          }
        }}
        operation={Operation.update}
      >
        <>
          {generationUnit && (
            <GenerationUnitFields
              setFormValues={setFormValues}
              formValues={formValues}
              forecastSourceOptions={forecastSourceOptions}
            />
          )}
        </>
      </EntityModal>
    </div>
  );
}
