import {
  createAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import { path } from 'ramda';

import { RootState } from '../../app/store';
import userFriendlyLoadingTime from '../userFriendlyLoadingTime';
import { RequestState, RequestStatuses } from '../requestTypes';
import entitySliceBuilder from '../common/entitySliceCreator';
import {
  ForecastSourceOptionsViewModel,
  ForecastSourcesViewModel,
  ForecastSourceViewModel,
  NewForecastSource,
} from './forecastSourceTypes';
import {
  addForecastSource,
  fetchForecastSources,
} from './forecastSourcesAPI';
import { RejectValue } from '../common/errorTypes';
import { executeWithRejectValue } from '../common/executeWithRejectValue';
import { EntityState } from '../common/entitySliceCreator';

interface ForecastSourcesState extends EntityState {
  entities: { [key: string]: ForecastSourceViewModel } | {};
  addEntityState: RequestState;
}

const initialState: ForecastSourcesState = {
  entities: {},
  addEntityState: {
    status: RequestStatuses.idle,
    errors: [],
  },
};

const timeout = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const fetchForecastSourcesAsync = createAsyncThunk<
  ForecastSourcesViewModel,
  void,
  RejectValue
>(
  'forecastSources/fetchForecastSources',
  async (_0, { rejectWithValue }) => {
    return await executeWithRejectValue(async () => {
      const response = await fetchForecastSources();
      return response.data;
    }, rejectWithValue);
  },
);

export const addForecastSourceAsync = createAsyncThunk<
  ForecastSourceViewModel,
  NewForecastSource,
  RejectValue
>(
  'forecastSources/addForecastSource',
  async (
    { platformId, name, type }: NewForecastSource,
    { rejectWithValue },
  ) => {
    return await executeWithRejectValue(async () => {
      const response = await addForecastSource(
        platformId,
        name,
        type,
      );
      await timeout(userFriendlyLoadingTime);
      return response.data;
    }, rejectWithValue);
  },
);

export const clearAddForecastSourceStatus = createAction(
  'forecastSources/clearAddForecastSourceStatus',
);

const { entityReducers, extraEntityReducers } = entitySliceBuilder<
  NewForecastSource,
  ForecastSourceViewModel
>(
  addForecastSourceAsync,
  fetchForecastSourcesAsync,
  clearAddForecastSourceStatus.type,
);

export const generatorSlice = createSlice({
  name: 'forecastSources',
  initialState,
  reducers: {
    ...entityReducers,
  },
  extraReducers: (builder) => {
    extraEntityReducers(builder);
  },
});

export const getGenerator = (
  state: RootState,
  generatorId?: string,
): ForecastSourceViewModel | undefined =>
  generatorId
    ? path(['forecastSources', 'entities', generatorId], state)
    : undefined;

export const getOptionGenerators = (
  state: RootState,
): ForecastSourceOptionsViewModel => {
  const forecastSources: { [key: string]: ForecastSourceViewModel } =
    state.forecastSources.entities;
  const forecastSourceOptions: { [key: string]: string } = {};
  forecastSources &&
    Object.keys(forecastSources).forEach(
      (key) =>
        (forecastSourceOptions[forecastSources[key].name] = key),
    );
  return forecastSourceOptions;
};

export const getAddForecastSourceState = (
  state: RootState,
): RequestState => state.forecastSources.addEntityState;

export default generatorSlice.reducer;
