const FIFTEEN_MINUTES_IN_MS = 15 * 60 * 1000;

const calculateExpectedEnd = (
  expectedStart: Date | null,
  currentExpectedEnd: Date | null,
): Date | null => {
  if (!expectedStart) {
    return currentExpectedEnd;
  }

  if (
    currentExpectedEnd &&
    currentExpectedEnd.getTime() >= expectedStart.getTime()
  ) {
    return currentExpectedEnd;
  }

  const startPlusFifteenMins =
    expectedStart.getTime() + FIFTEEN_MINUTES_IN_MS;

  const roundedUpToQuarterHour =
    Math.ceil(startPlusFifteenMins / FIFTEEN_MINUTES_IN_MS) *
    FIFTEEN_MINUTES_IN_MS;

  return new Date(roundedUpToQuarterHour);
};

export default calculateExpectedEnd;
