export const enum EventStatus {
  DRAFT = 'DRAFT',
  CONFIRMED = 'CONFIRMED',
  DELETED = 'DELETED',
  STARTED = 'STARTED',
  NEVER_STARTED = 'NEVER_STARTED',
  SUSPENDED = 'SUSPENDED',
  ENDED = 'ENDED',
  AUTO_ENDED = 'AUTO_ENDED',
}

export const eventStatuses = [
  EventStatus.DRAFT,
  EventStatus.CONFIRMED,
  EventStatus.STARTED,
  EventStatus.NEVER_STARTED,
  EventStatus.ENDED,
  EventStatus.AUTO_ENDED,
  EventStatus.DELETED,
  EventStatus.SUSPENDED,
];

export enum DowntimeEventType {
  MAINTENANCE = 'maintenance',
}

export const downtimeEventTypes = {
  [DowntimeEventType.MAINTENANCE]: {
    value: DowntimeEventType.MAINTENANCE,
    label: 'maintenance',
  },
};

export interface DowntimeTypeSelectOptsViewModel {
  label: string;
  value: string;
}

export const enum EventType {
  DOWNTIME = 'downtime',
  OFFTAKE = 'offtake',
}

interface BaseEventViewModel {
  id: string;
  generationUnitId: string;
  orgId: string;
  status: EventStatus;
  eventType: EventType;
  start: string;
  end: string;
  deferralIsOverdue: boolean;
}

export interface NewOfftakeEvent {
  expectedStart: string;
  expectedEnd: string;
  h2QuantityKg: number;
  renewableIncentiveEligibleMassKg?: number;
  offtakerId: string;
  h2IsRequiredUpFront: boolean;
}

export interface UpdatedOfftakeEvent extends NewOfftakeEvent {
  id: string;
  status: EventStatus;
}

export interface UpdatedDowntimeEvent extends NewDowntimeEvent {
  id: string;
  status: EventStatus;
}

interface BaseOfftakeEventViewModel extends BaseEventViewModel {
  offtakerId: string;
  h2IsRequiredUpFront: boolean;
  eventType: EventType.OFFTAKE;
}

export interface OfftakeEventViewModel
  extends BaseOfftakeEventViewModel {
  h2QuantityKg: number;
  renewableIncentiveEligibleMassKg: number | null;
}

export interface TransformedOfftakeEventViewModel
  extends BaseOfftakeEventViewModel {
  h2Quantity: number;
  incentivisedQuantity?: number;
}

export interface NewDowntimeEvent {
  expectedStart: string;
  expectedEnd: string;
  electrolyzerId: string;
  type: DowntimeEventType;
}

export interface DowntimeEventViewModel extends BaseEventViewModel {
  eventType: EventType.DOWNTIME;
  electrolyzerId: string;
  type: DowntimeEventType;
}

export type EventViewModel =
  | DowntimeEventViewModel
  | OfftakeEventViewModel;

export type TransformedEventViewModel =
  | DowntimeEventViewModel
  | TransformedOfftakeEventViewModel;

export interface NewEvents {
  offtakeEvents: NewOfftakeEvent[];
  downtimeEvents: NewDowntimeEvent[];
}

export interface RollingScheduleChartEventData {
  offtake: {
    id: string;
    start: number;
    end: number;
    name: string;
    abbreviation: string;
    h2Quantity: number;
    eventType: EventType.OFFTAKE;
  }[];
  downtime: {
    id: string;
    start: number;
    end: number;
    electrolyzerDisplayName: string;
    type: DowntimeEventType;
    eventType: EventType.DOWNTIME;
  }[];
}

export const enum EventsActions {
  HALT_PRODUCTION = 'halt_production',
}
