import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DataCard from '../common/DataCard';
import AddStorageUnit from './AddStorageUnit';
import { useAppSelector } from '../../hooks/common';
import { getStorageUnitsByGenerationUnitId } from '../../state/storageUnits/storageUnitSlice';
import { storageUnitTypes } from './constants';
import Chip from '@mui/material/Chip';
import StorageUnitSpecs from './StorageUnitSpecs';
import StorageUnitTelemetry from './StorageUnitTelemetry';
import { getUserValuesUnit } from '../../state/user/userSlice';

interface Props {
  generationUnitId: string;
}

const StorageUnitsActivityCard = ({ generationUnitId }: Props) => {
  const userValuesUnit = useAppSelector(getUserValuesUnit);

  const storageUnits = useAppSelector((state) =>
    getStorageUnitsByGenerationUnitId(
      state,
      generationUnitId,
      userValuesUnit,
    ),
  );

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography
              component="h3"
              variant="subtitle1"
              gutterBottom
            >
              Connected storage
            </Typography>
          </Grid>
          <Grid item>
            <AddStorageUnit generationUnitId={generationUnitId} />
          </Grid>
        </Grid>
      </Grid>
      {storageUnits &&
        storageUnits.map((storageUnit) => (
          <Grid item key={storageUnit.id} sx={{ paddingTop: 1 }}>
            <DataCard>
              <Grid container direction="row">
                <Grid item xs={12}>
                  <Grid container direction="row">
                    <Grid item xs={10}>
                      <Typography component="h4" variant="h4">
                        {storageUnit.manufacturer}
                        <Chip
                          variant="outlined"
                          size="small"
                          sx={{ ml: 1 }}
                          label={
                            storageUnitTypes.find(
                              (sut) => sut.id === storageUnit.type,
                            )?.name || '-'
                          }
                        />
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Grid container justifyContent="flex-end">
                        <StorageUnitSpecs
                          storageUnitId={storageUnit.id}
                          generationUnitId={generationUnitId}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid key={storageUnit.id} item xs={12}>
                  <StorageUnitTelemetry
                    storageUnit={storageUnit}
                    valuesUnit={userValuesUnit}
                  />
                </Grid>
              </Grid>
            </DataCard>
          </Grid>
        ))}
      {(!storageUnits || storageUnits.length === 0) && (
        <Grid item>
          <Typography
            sx={{
              fontSize: '11px',
              fontStyle: 'italic',
            }}
          >
            No storage units connected
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default StorageUnitsActivityCard;
