import {
  createAction,
  createAsyncThunk,
  createSlice,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit';
import { path } from 'ramda';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import { RootState } from '../../app/store';
import {
  fetchGenerationUnits,
  addGenerationUnit,
  updateGenerationUnit,
} from './generationUnitsAPI';
import userFriendlyLoadingTime from '../userFriendlyLoadingTime';
import { RequestStatuses, RequestState } from '../requestTypes';
import {
  GenerationUnitForecastSource,
  GenerationUnitsViewModel,
  GenerationUnitViewModel,
  NewGenerationUnit,
  UpdatedGenerationUnit,
} from './generationUnitTypes';
import { EntityState } from '../common/entitySliceCreator';
import { ForecastSourceType } from '../forecastSources/forecastSourceTypes';
import { RejectValue } from '../common/errorTypes';
import { executeWithRejectValue } from '../common/executeWithRejectValue';

dayjs.extend(duration);

interface GenerationUnitsState {
  entities: { [key: string]: GenerationUnitViewModel } | {};
  addEntityState: RequestState;
  updateEntityState: RequestState;
  selectedEntity: string;
}

const initialState: GenerationUnitsState = {
  entities: {},
  addEntityState: {
    status: RequestStatuses.idle,
    errors: [],
  },
  updateEntityState: {
    status: RequestStatuses.idle,
    errors: [],
  },
  selectedEntity: '',
};

const timeout = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const fetchUnitsAsync = createAsyncThunk<
  GenerationUnitsViewModel,
  void,
  RejectValue
>(
  'generationUnits/fetchGenerationUnits',
  async (_0, { rejectWithValue }) => {
    return await executeWithRejectValue(async () => {
      const response = await fetchGenerationUnits();
      return response.data;
    }, rejectWithValue);
  },
);

export const addUnitAsync = createAsyncThunk<
  GenerationUnitViewModel,
  NewGenerationUnit,
  RejectValue
>(
  'generationUnits/addGenerationUnit',
  async (newUnit: NewGenerationUnit, { rejectWithValue }) => {
    return await executeWithRejectValue(async () => {
      const response = await addGenerationUnit(newUnit);
      await timeout(userFriendlyLoadingTime);
      return response.data;
    }, rejectWithValue);
  },
);

export const updateUnitAsync = createAsyncThunk<
  GenerationUnitViewModel,
  UpdatedGenerationUnit,
  RejectValue
>(
  'generationUnits/updateGenerationUnit',
  async (updatedUnit: UpdatedGenerationUnit, { rejectWithValue }) => {
    return await executeWithRejectValue(async () => {
      const response = await updateGenerationUnit(updatedUnit);
      await timeout(userFriendlyLoadingTime);
      return response.data;
    }, rejectWithValue);
  },
);

export const clearAddUnitStatus = createAction(
  'generationUnits/clearAddUnitStatus',
);

export const clearUpdateUnitStatus = createAction(
  'generationUnits/clearUpdateUnitStatus',
);

export const selectGenerationUnitById = createAction<string>(
  'generationUnits/selectGenerationUnitById',
);

export const generatorSlice = createSlice({
  name: 'generationUnits',
  initialState,
  reducers: {
    clearAddUnitStatus: (state: EntityState) => {
      state.addEntityState = {
        status: RequestStatuses.idle,
        errors: [],
      };
    },
    clearUpdateUnitStatus: (state: EntityState) => {
      state.updateEntityState = {
        status: RequestStatuses.idle,
        errors: [],
      };
    },
    selectGenerationUnitById: (
      state: EntityState,
      action: PayloadAction<string>,
    ) => {
      const generationUnitId = action.payload;
      if (state.entities[generationUnitId]) {
        state.selectedEntity = generationUnitId;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchUnitsAsync.fulfilled,
        (state: EntityState, action) => {
          state.entities = action.payload;

          const values = Object.values(action.payload);
          if (!state.selectedEntity && values.length) {
            state.selectedEntity = values.sort(
              alphabeticallyByName,
            )[0].id;
          }
        },
      )
      .addCase(
        addUnitAsync.fulfilled,
        (state: EntityState, action) => {
          state.entities = {
            ...state.entities,
            [action.payload.id]: action.payload,
          };

          state.addEntityState = {
            status: RequestStatuses.fulfilled,
            errors: [],
          };

          state.selectedEntity = action.payload.id;
        },
      )
      .addCase(addUnitAsync.pending, (state: EntityState) => {
        state.addEntityState = {
          status: RequestStatuses.pending,
          errors: [],
        };
      })
      .addCase(
        addUnitAsync.rejected,
        (state: EntityState, action) => {
          state.addEntityState = {
            status: RequestStatuses.rejected,
            errors: action.payload?.errors,
          };
        },
      )
      .addCase(updateUnitAsync.pending, (state: EntityState) => {
        state.updateEntityState = {
          status: RequestStatuses.pending,
          errors: [],
        };
      })
      .addCase(
        updateUnitAsync.rejected,
        (state: EntityState, action) => {
          state.updateEntityState = {
            status: RequestStatuses.rejected,
            errors: action.payload?.errors,
          };
        },
      )
      .addCase(
        updateUnitAsync.fulfilled,
        (state, action: PayloadAction<GenerationUnitViewModel>) => {
          if (action.payload) {
            state.entities = {
              ...state.entities,
              [action.payload.id]: action.payload,
            };
          }

          state.updateEntityState = {
            status: RequestStatuses.fulfilled,
            errors: [],
          };
        },
      );
  },
});

export const getGenerator = (
  state: RootState,
  generationUnitId?: string,
): GenerationUnitViewModel | undefined =>
  generationUnitId
    ? path(['generationUnits', 'entities', generationUnitId], state)
    : undefined;

export const getGridForecastSource = (
  state: RootState,
  generationUnitId: string,
): GenerationUnitForecastSource | null => {
  const generationUnit = getGenerator(state, generationUnitId);

  if (generationUnit) {
    const { forecastSources } = generationUnit;
    const gridSource = forecastSources.find(
      ({ type }) => type === ForecastSourceType.GRID_PRICE,
    );

    return gridSource || null;
  }

  return null;
};

const alphabeticallyByName = (
  a: GenerationUnitViewModel,
  b: GenerationUnitViewModel,
) => (a.name > b.name ? 1 : -1);

export const getGenerationUnitsSortedByName = createSelector(
  (state: RootState) => state.generationUnits.entities,
  (entities: GenerationUnitsViewModel) =>
    Object.values(entities).sort(alphabeticallyByName),
);

export const getAddUnitState = (state: RootState): RequestState =>
  state.generationUnits.addEntityState;

export const getUpdateUnitState = (state: RootState): RequestState =>
  state.generationUnits.updateEntityState;

export const getSelectedGenerationUnitId = (state: RootState) =>
  state.generationUnits.selectedEntity;

const generationUnitHasIncentiveConfigured = (
  generationUnit: GenerationUnitViewModel | null | undefined,
): boolean => {
  if (generationUnit) {
    return Boolean(
      generationUnit.renewableIncentivePencePerKg &&
        generationUnit.renewableIncentivePencePerKg > 0,
    );
  }
  return false;
};

export const hasRenewableIncentiveConfigured = (
  state: RootState,
  generationUnitId: string,
) => {
  const generationUnit = getGenerator(state, generationUnitId);
  return generationUnitHasIncentiveConfigured(generationUnit);
};

export const getSelectedGenerationUnit = createSelector(
  (state: RootState) => state.generationUnits.entities,
  getSelectedGenerationUnitId,
  (
    entities: GenerationUnitsViewModel,
    selectedGenerationId: string,
  ) => {
    if (selectedGenerationId) {
      return entities[selectedGenerationId];
    }
    return null;
  },
);

export const selectedUnitHasIncentiveConfigured = createSelector(
  getSelectedGenerationUnit,
  (generationUnit) =>
    generationUnitHasIncentiveConfigured(generationUnit),
);

export default generatorSlice.reducer;
