import MuiPaper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

const Paper = styled(MuiPaper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  overflow: 'auto',
  flexDirection: 'column',
  marginBottom: '30px',
  backgroundImage: 'none',
  backgroundColor: theme.palette.divider,
}));

export default Paper;
