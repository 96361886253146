import { CSVLink } from 'react-csv';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/FileDownload';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import { RollingScheduleExportData } from '../../state/rollingSchedules/rollingScheduleTypes';

const filenameDateFormat = 'YYYY-MM-DD';

interface Props {
  generationUnitName: string;
  data: RollingScheduleExportData;
}

const ExportRollingScheduleData = ({
  generationUnitName,
  data,
}: Props) => {
  const start = data[0].periodStart;
  const end = data[data.length - 1].periodEnd;

  const filename = `production_plan__${dayjs(start).format(
    filenameDateFormat,
  )}--to--${dayjs(end).format(
    filenameDateFormat,
  )}__${generationUnitName}.csv`;

  const headers = [
    { label: 'Start', key: 'periodStart' },
    { label: 'End', key: 'periodEnd' },
    { label: 'Total cost (£)', key: 'totalCostInPounds' },
    {
      label: 'Subsidy discount (£)',
      key: 'subsidyDiscountInPounds',
    },
    { label: 'Total production (kg)', key: 'totalH2Production' },
    { label: 'Production rate (kg/h)', key: 'productionRate' },
  ];

  return (
    <CSVLink
      headers={headers}
      filename={filename}
      data={data}
      asyncOnClick={true}
    >
      <Tooltip title="Download upcoming production data">
        <IconButton size="small">
          <DownloadIcon />
        </IconButton>
      </Tooltip>
    </CSVLink>
  );
};

export default ExportRollingScheduleData;
