import { Snackbar, Alert, Box } from '@mui/material';
import { DetailedErrorMessage } from '../../state/common/errorTypes';

interface Props {
  handleClose: () => void;
  errors?: DetailedErrorMessage[];
}

const FailureNotification = ({ handleClose, errors }: Props) => {
  return (
    <Snackbar
      open={Boolean(errors?.length)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
    >
      <Box>
        {errors &&
          errors.map((error, index) => (
            <Alert key={index} severity="error" onClose={handleClose}>
              {error.message}
            </Alert>
          ))}
      </Box>
    </Snackbar>
  );
};

export default FailureNotification;
