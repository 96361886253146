import { DetailedErrorMessage } from './common/errorTypes';

export interface RequestState {
  status: RequestStatuses;
  entityId?: string;
  errors?: DetailedErrorMessage[];
}

export enum RequestStatuses {
  idle,
  pending,
  rejected,
  fulfilled,
}
