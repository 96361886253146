import * as R from 'ramda';
import { Stack, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { OfftakeEventViewModel } from '../../../state/events/eventTypes';
import DatePicker from '../../common/DatePicker';
import { ModalForm } from '../../common/modalStyles';
import getExpectedEndFormValue from '../expectedEndCalculator';
import UpdateEventFormActions from '../UpdateEventFormActions';
import { useAppSelector } from '../../../hooks/common';
import { hasRenewableIncentiveConfigured } from '../../../state/generationUnits/generationUnitsSlice';
import { RequestState } from '../../../state/requestTypes';

interface Props {
  event: OfftakeEventViewModel;
  handleSubmit: (formValues: {
    eventId: string;
    expectedEnd: string;
    h2QuantityKg: number;
    renewableIncentiveEligibleMassKg?: number;
  }) => Promise<void>;
  handleClose: () => void;
  onOpenDatePicker: () => void;
  onCloseDatePicker: () => void;
  disabled: boolean;
  requestState: RequestState;
}

const StartOfftakeEventForm = ({
  event,
  handleSubmit,
  handleClose,
  onOpenDatePicker,
  onCloseDatePicker,
  disabled,
  requestState,
}: Props) => {
  const showIncentive = useAppSelector((state) =>
    hasRenewableIncentiveConfigured(state, event.generationUnitId),
  );

  const [h2QuantityKgFormValue, setH2QuantityKg] = useState(
    event.h2QuantityKg.toString(),
  );

  const [incentiveEligibleKgFormValue, setIncentiveEligibleKg] =
    useState(
      !R.isNil(event.renewableIncentiveEligibleMassKg)
        ? event.renewableIncentiveEligibleMassKg.toString()
        : null,
    );

  const [expectedEndFormValue, setExpectedEnd] =
    useState<Date | null>(
      getExpectedEndFormValue(event.start, event.end),
    );

  return (
    <ModalForm
      onSubmit={async (e) => {
        e.preventDefault();

        await handleSubmit({
          eventId: event.id,
          expectedEnd: dayjs(expectedEndFormValue)
            .second(0)
            .toISOString(),
          h2QuantityKg: parseInt(h2QuantityKgFormValue, 10),
          ...(incentiveEligibleKgFormValue && {
            renewableIncentiveEligibleMassKg: parseInt(
              incentiveEligibleKgFormValue,
            ),
          }),
        });
      }}
    >
      <Stack spacing={3}>
        <TextField
          fullWidth
          id="h2QuantityKg"
          required
          type="number"
          onInput={(e) =>
            setH2QuantityKg((e.target as HTMLTextAreaElement).value)
          }
          label="H₂ Quantity (kg)"
          variant="filled"
          value={h2QuantityKgFormValue}
          disabled={disabled}
        />

        {showIncentive && (
          <TextField
            fullWidth
            id="incentiveEligibleQuantityKg"
            required
            type="number"
            onInput={(e) =>
              setIncentiveEligibleKg(
                (e.target as HTMLTextAreaElement).value,
              )
            }
            label="Incentivised H₂ Quantity (kg)"
            variant="filled"
            value={incentiveEligibleKgFormValue}
            disabled={disabled}
          />
        )}
        <DatePicker
          disabled={disabled}
          label="Expected end"
          timeIntervals={15}
          value={expectedEndFormValue}
          handleChange={setExpectedEnd}
          onOpen={onOpenDatePicker}
          onClose={onCloseDatePicker}
        />
      </Stack>
      <UpdateEventFormActions
        handleClose={handleClose}
        successMessage="Started successfully"
        requestState={requestState}
      />
    </ModalForm>
  );
};

export default StartOfftakeEventForm;
