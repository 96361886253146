import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useAppSelector } from '../hooks/common';
import { getSelectedGenerationUnit } from '../state/generationUnits/generationUnitsSlice';
import AddGenerationUnit from '../components/generationUnit/AddGenerationUnit';
import AddForecastSource from '../components/forecasts/sources/AddForecastSource';
import GenerationUnit from '../components/generationUnit/GenerationUnit';
import Paper from '../components/common/Paper';

const Dashboard = () => {
  const generationUnit = useAppSelector(getSelectedGenerationUnit);

  return (
    <>
      <Grid container justifyContent="flex-end">
        <Grid item xs={12}>
          {generationUnit ? (
            <Paper key={generationUnit.id} elevation={0}>
              <GenerationUnit unit={generationUnit} />
            </Paper>
          ) : (
            <Typography variant="body1" component="div" gutterBottom>
              You haven't added any generation units yet.
            </Typography>
          )}
        </Grid>
        <Grid container spacing={2} justifyContent="space-between">
          <AddGenerationUnit />
          <AddForecastSource />
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
