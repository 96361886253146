import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';

import TelemetryRequestStatus from '../common/TelemetryRequestStatus';
import { utcToLocalDate, getTimezone } from '../common/dateConverter';
import isUnauthorizedError from '../../state/common/unauthorizedError';
import { StorageUnitViewModel } from '../../state/storageUnits/storageUnitTypes';
import { useGetStorageUnitTelemetryQuery } from '../../app/services/telemetry';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import DataCard from '../common/DataCard';
import { convertToNm3 } from '../../state/common/valueUnitConvertor';
import { H2MeasurementUnit } from '../../common/types';

const StyledBox = styled('div')(() => ({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

interface StorageUnitTelemetryData {
  time: string;
  h2InStorageKg: number;
}

interface Props {
  storageUnit: StorageUnitViewModel;
  valuesUnit: H2MeasurementUnit;
}

const StorageUnitTelemetry = ({ storageUnit, valuesUnit }: Props) => {
  const convertTelemetryToDesiredUnit = (
    valuesUnit: string,
    telemetry: StorageUnitTelemetryData,
  ) => {
    if (!telemetry) return null;
    return valuesUnit === 'nm3'
      ? {
          ...telemetry,
          h2InStorageKg: convertToNm3(telemetry.h2InStorageKg),
        }
      : telemetry;
  };

  const [pollingInterval, setPollingInterval] = useState(6000);
  const telemetryRequest = useGetStorageUnitTelemetryQuery(
    storageUnit.id,
    {
      pollingInterval,
    },
  );

  const telemetry = convertTelemetryToDesiredUnit(
    valuesUnit,
    telemetryRequest.data?.storageUnit?.telemetry,
  );

  const displayFetchError = Boolean(
    telemetryRequest.isError ||
      (telemetryRequest.isFetching && telemetryRequest.error),
  );

  const isUnauthorized =
    displayFetchError && isUnauthorizedError(telemetryRequest.error);

  useEffect(() => {
    if (isUnauthorized) {
      setPollingInterval(0);
    } else {
      setPollingInterval(6000);
    }
  }, [setPollingInterval, isUnauthorized]);

  if (telemetryRequest.isLoading && !displayFetchError) {
    return <TelemetryRequestStatus loading={true} />;
  }

  if (telemetry && !displayFetchError) {
    const lastUpdated = utcToLocalDate(telemetry.time).format(
      'h:mm:ss A',
    );

    const percentage =
      (telemetry.h2InStorageKg / storageUnit.totalCapacityKg) * 100;

    return (
      <>
        <Grid container direction="row">
          <Grid item xs={12}>
            <DataCard>
              <Grid
                container
                direction="column"
                justifyContent="space-around"
                spacing={1}
              >
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    <Grid item>
                      <Box
                        sx={{
                          position: 'relative',
                          display: 'inline-flex',
                        }}
                      >
                        <CircularProgress
                          variant="determinate"
                          value={percentage}
                          size={60}
                        />
                        <StyledBox>
                          <Typography
                            component="h5"
                            variant="subtitle1"
                            textAlign="center"
                          >{`${Math.round(percentage)}%`}</Typography>
                        </StyledBox>
                      </Box>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography
                        component="h5"
                        variant="subtitle1"
                        gutterBottom
                        textAlign="center"
                      >
                        {telemetry.h2InStorageKg} /{' '}
                        {storageUnit.totalCapacityKg} {valuesUnit}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DataCard>
          </Grid>
        </Grid>
        <TelemetryRequestStatus
          lastUpdated={lastUpdated}
          timezone={getTimezone()}
        />
      </>
    );
  }

  return <TelemetryRequestStatus displayFetchError={true} />;
};

export default StorageUnitTelemetry;
