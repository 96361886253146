import { Tooltip } from '@mui/material';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

interface FormValues {
  manufacturer: string;
  serialNumber: string;
  efficiency: string;
  controlValue: string;
  maximumLoad: string;
  minRateChangeIntervalSeconds: number;
  minZeroProductionIntervalSeconds: number;
  minNonZeroProductionIntervalSeconds: number;
}

interface Props {
  disabled?: boolean;
  setFormValues: (formValues: FormValues) => void;
  formValues: FormValues;
}

const ElectrolyzerFields = ({
  disabled,
  setFormValues,
  formValues,
}: Props) => (
  <Stack spacing={3}>
    <TextField
      fullWidth
      id="name"
      required
      onInput={(e) =>
        setFormValues({
          ...formValues,
          manufacturer: (e.target as HTMLTextAreaElement).value,
        })
      }
      label="Manufacturer"
      variant="filled"
      value={formValues.manufacturer}
      disabled={disabled}
    />
    <TextField
      fullWidth
      id="serial-number"
      required
      onInput={(e) =>
        setFormValues({
          ...formValues,
          serialNumber: (e.target as HTMLTextAreaElement).value,
        })
      }
      label="Serial No."
      variant="filled"
      value={formValues.serialNumber}
      disabled={disabled}
    />
    <TextField
      fullWidth
      id="control-value"
      required
      multiline
      rows={10}
      onInput={(e) =>
        setFormValues({
          ...formValues,
          controlValue: (e.target as HTMLTextAreaElement).value,
        })
      }
      label="Control value details"
      variant="filled"
      value={formValues.controlValue}
      disabled={disabled}
    />
    <TextField
      fullWidth
      id="efficiency"
      required
      multiline
      rows={10}
      onInput={(e) =>
        setFormValues({
          ...formValues,
          efficiency: (e.target as HTMLTextAreaElement).value,
        })
      }
      label="Power production efficiency"
      variant="filled"
      value={formValues.efficiency}
      disabled={disabled}
    />
    <TextField
      fullWidth
      id="maximumLoad"
      multiline
      rows={5}
      onInput={(e) =>
        setFormValues({
          ...formValues,
          maximumLoad: (e.target as HTMLTextAreaElement).value,
        })
      }
      label="Maximum load"
      variant="filled"
      value={formValues.maximumLoad}
      disabled={disabled}
      required
    />
    <TextField
      fullWidth
      id="minRateChangeIntervalSeconds"
      required
      type="number"
      onInput={(e) =>
        setFormValues({
          ...formValues,
          minRateChangeIntervalSeconds: Number(
            (e.target as HTMLTextAreaElement).value,
          ),
        })
      }
      label="Min. interval between production rate changes (seconds)"
      variant="filled"
      value={formValues.minRateChangeIntervalSeconds}
      disabled={disabled}
    />
    <Tooltip title="The minimum amount of time that the electrolyzer not must produce H₂ for once it has stopped production.">
      <TextField
        fullWidth
        id="minZeroProductionIntervalSeconds"
        required
        type="number"
        onInput={(e) =>
          setFormValues({
            ...formValues,
            minZeroProductionIntervalSeconds: Number(
              (e.target as HTMLTextAreaElement).value,
            ),
          })
        }
        label="Min. continuous time electrolyzer must not be producing (seconds)"
        variant="filled"
        value={formValues.minZeroProductionIntervalSeconds}
        disabled={disabled}
      />
    </Tooltip>
    <Tooltip title="The minimum amount of time that the electrolyzer must produce H₂ for once it has started production.">
      <TextField
        fullWidth
        id="minNonZeroProductionIntervalSeconds"
        required
        type="number"
        onInput={(e) =>
          setFormValues({
            ...formValues,
            minNonZeroProductionIntervalSeconds: Number(
              (e.target as HTMLTextAreaElement).value,
            ),
          })
        }
        label="Min. continuous time electrolyzer must be producing (seconds)"
        variant="filled"
        value={formValues.minNonZeroProductionIntervalSeconds}
        disabled={disabled}
      />
    </Tooltip>
  </Stack>
);

export default ElectrolyzerFields;
