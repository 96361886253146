import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useAppDispatch, useAppSelector } from '../../hooks/common';
import { getXAxisValuesForRollingScheduleViewWindow } from '../../state/charts/chartsSlice';
import {
  fetchRollingScheduleByGenerationUnitIdAsync,
  getRollingScheduleCSVExportData,
  getScheduleProductionRateDataByGenerationUnitId,
} from '../../state/rollingSchedules/rollingSchedulesSlice';
import { GenerationUnitViewModel } from '../../state/generationUnits/generationUnitTypes';
import RollingScheduleChart from './RollingScheduleChart';
import { getEventDataForScheduleChart } from '../../state/events/eventSlice';
import { getStorageUnitTotalStaticCapacity } from '../../state/storageUnits/storageUnitSlice';
import { fetchLatestForecastsByGenUnitIdAsync } from '../../state/forecasts/forecastsSlice';
import { getUserValuesUnit } from '../../state/user/userSlice';
import ExportRollingScheduleData from './ExportRollingScheduleData';
import RollingScheduleStatus from './RollingScheduleStatus';

interface Props {
  generationUnit: GenerationUnitViewModel;
}

const ChartContainer = styled('div')(({ theme }) => ({
  height: '350px',
  background: theme.palette.background.default,
}));

const RollingSchedule = ({ generationUnit }: Props) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      try {
        dispatch(
          fetchRollingScheduleByGenerationUnitIdAsync(
            generationUnit.id,
          ),
        );

        dispatch(
          fetchLatestForecastsByGenUnitIdAsync(generationUnit.id),
        );
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, generationUnit.id]);

  const xAxisValues = useAppSelector(
    getXAxisValuesForRollingScheduleViewWindow,
  );

  const storageUnitCapacity = useAppSelector((state) =>
    getStorageUnitTotalStaticCapacity(state, generationUnit.id),
  );

  const userValuesUnit = useAppSelector(getUserValuesUnit);

  const scheduleProductionRateData = useAppSelector((state) =>
    getScheduleProductionRateDataByGenerationUnitId(
      state,
      generationUnit.id,
      userValuesUnit,
      storageUnitCapacity,
    ),
  );

  const exportData = useAppSelector((state) =>
    getRollingScheduleCSVExportData(state, generationUnit.id),
  );

  const eventData = useAppSelector((state) =>
    getEventDataForScheduleChart(
      state,
      generationUnit.id,
      userValuesUnit,
    ),
  );

  const showChart = scheduleProductionRateData !== null;

  return (
    <Card variant="outlined" sx={{ backgroundColor: 'divider' }}>
      <CardContent>
        <Grid
          container
          direction="row"
          spacing={1}
          justifyContent="space-between"
        >
          <Grid item>
            <Typography
              component="h3"
              variant="subtitle1"
              gutterBottom
            >
              Planned production
            </Typography>
          </Grid>
          <Grid item>
            {exportData && (
              <ExportRollingScheduleData
                data={exportData}
                generationUnitName={generationUnit.name}
              />
            )}
          </Grid>
        </Grid>
        {showChart && (
          <>
            <ChartContainer>
              <RollingScheduleChart
                xAxisValues={xAxisValues}
                scheduleProductionRateData={
                  scheduleProductionRateData
                }
                eventData={eventData}
                valuesUnit={userValuesUnit}
              />
            </ChartContainer>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <RollingScheduleStatus
                  generationUnitId={generationUnit.id}
                />
              </Grid>
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default RollingSchedule;
