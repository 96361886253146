import React from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

import { useAppSelector } from '../../hooks/common';
import { ModalContents } from '../common/modalStyles';
import UpdateStorageUnit from './UpdateStorageUnit';
import { getStorageUnitById } from '../../state/storageUnits/storageUnitSlice';
import { storageUnitTypes } from './constants';
import { Tooltip } from '@mui/material';

interface Props {
  storageUnitId: string;
  generationUnitId: string;
  open: boolean;
  handleClose: () => void;
}

const StorageUnitSpecsModal = ({
  open,
  handleClose,
  storageUnitId,
  generationUnitId,
}: Props) => {
  const storageUnit = useAppSelector((state) =>
    getStorageUnitById(state, storageUnitId),
  );

  const modalBody = (
    <ModalContents>
      {storageUnit && (
        <Stack spacing={1}>
          <Grid container justifyContent="flex-end">
            <UpdateStorageUnit
              storageUnit={storageUnit}
              generationUnitId={generationUnitId}
            />
          </Grid>
          <Typography variant="body1">
            <strong>Name: </strong>
            {storageUnit.name}
          </Typography>
          <Typography variant="body1">
            <strong>Type: </strong>
            {storageUnitTypes.find(
              (sut) => sut.id === storageUnit.type,
            )?.name || '-'}
          </Typography>
          <Typography variant="body1">
            <strong>Serial number: </strong>
            {storageUnit.serialNumber}
          </Typography>
          <Typography variant="body1">
            <strong>Manufacturer: </strong>
            {storageUnit.manufacturer}
          </Typography>
          <Tooltip
            title={
              <Typography variant="body1">
                Minimum volume of H2 required in order to keep the
                storage unit pressurised.
              </Typography>
            }
          >
            <Typography variant="body1">
              <strong>Min capacity (kg): </strong>
              {storageUnit.minCapacityKg}
            </Typography>
          </Tooltip>
          <Typography variant="body1">
            <strong>Total capacity (kg): </strong>
            {storageUnit.totalCapacityKg}
          </Typography>
          <Typography variant="body1">
            <strong>Connected electrolyzer id(s): </strong>
          </Typography>
          <ul>
            {storageUnit.electrolyzerIds.map((electrolyzerId) => (
              <li key={electrolyzerId}>
                <Typography variant="body1">
                  {electrolyzerId}
                </Typography>
              </li>
            ))}
          </ul>
          <Stack direction="row" justifyContent="end">
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>
          </Stack>
        </Stack>
      )}
    </ModalContents>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="storage-unit-technical-specifications"
      aria-describedby="technical-data-about-a-storage-unit"
    >
      {modalBody}
    </Modal>
  );
};

export default StorageUnitSpecsModal;
