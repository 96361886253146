import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import TelemetryRequestStatus from '../common/TelemetryRequestStatus';
import { useGetEdgeDeviceTelemetryQuery } from '../../app/services/telemetry';
import { EdgeDeviceViewModel } from '../../state/edgeDevices/edgeDeviceTypes';
import { utcToLocalDate, getTimezone } from '../common/dateConverter';
import isUnauthorizedError from '../../state/common/unauthorizedError';

interface EdgeDeviceTelemetryData {
  currentSchedule: {
    id: string;
  };
  time: string;
  canReceiveSchedule: boolean;
}

interface Props {
  device: EdgeDeviceViewModel;
}

const EdgeDeviceTelemetry = ({ device }: Props) => {
  const [pollingInterval, setPollingInterval] = useState(6000);

  const telemetryRequest = useGetEdgeDeviceTelemetryQuery(device.id, {
    pollingInterval,
  });
  const displayFetchError = Boolean(
    telemetryRequest.isError ||
      (telemetryRequest.isFetching && telemetryRequest.error),
  );

  const isUnauthorized =
    displayFetchError && isUnauthorizedError(telemetryRequest.error);

  useEffect(() => {
    if (isUnauthorized) {
      setPollingInterval(0);
    } else {
      setPollingInterval(6000);
    }
  }, [setPollingInterval, isUnauthorized]);

  const telemetry = telemetryRequest.data?.edgeDevice
    ?.telemetry as EdgeDeviceTelemetryData | null;

  if (telemetryRequest.isLoading && !displayFetchError) {
    return <TelemetryRequestStatus loading={true} />;
  }

  if (telemetry && !displayFetchError) {
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            Running schedule
          </Typography>
          <Typography variant="body2" data-testid="schedule-id">
            {telemetry.currentSchedule && telemetry.currentSchedule.id
              ? telemetry.currentSchedule.id
              : 'No running schedule'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TelemetryRequestStatus
            lastUpdated={utcToLocalDate(telemetry.time).format(
              'h:mm:ss A',
            )}
            timezone={getTimezone()}
          />
        </Grid>
      </Grid>
    );
  }

  return <TelemetryRequestStatus displayFetchError={true} />;
};

export default EdgeDeviceTelemetry;
