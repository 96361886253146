import Grid from '@mui/material/Grid';
import PlanDowntimeEventFormFields, {
  PlanDowntimeEventFormValues,
} from './PlanDowntimeEventFormFields';
import Errors from '../../../common/Errors';
import { DetailedErrorMessage } from '../../../../state/common/errorTypes';
import { ElectrolyzerSelectOptsViewModel } from '../../../../state/electrolyzers/electrolyzerTypes';

interface Props {
  setFormValues: (formValues: PlanDowntimeEventFormValues) => void;
  errors?: DetailedErrorMessage[];
  formValues: PlanDowntimeEventFormValues;
  electrolyzerSelectOpts: ElectrolyzerSelectOptsViewModel[];
}

const PlanDowntimeEventForm = ({
  errors,
  setFormValues,
  formValues,
  electrolyzerSelectOpts,
}: Props) => {
  return (
    <Grid container direction="column" spacing={2}>
      <PlanDowntimeEventFormFields
        electrolyzerSelectOpts={electrolyzerSelectOpts}
        formValues={formValues}
        setFormValues={setFormValues}
      />
      <Grid item>
        <Errors errors={errors} />
      </Grid>
    </Grid>
  );
};

export default PlanDowntimeEventForm;
