import { handleApiError } from '../common/apiErrorHandler';
import {
  ForecastSourcesViewModel,
  ForecastSourceViewModel,
} from './forecastSourceTypes';

const agent = require('axios');

interface FetchForecastSourcesResponse {
  data: { forecastSources: ForecastSourcesViewModel };
}

export async function fetchForecastSources() {
  return await handleApiError<{ data: ForecastSourcesViewModel }>(
    () =>
      agent
        .get(`${process.env.REACT_APP_BACKEND_URL}/forecast-sources`)
        .then(({ data }: FetchForecastSourcesResponse) => ({
          data: data.forecastSources,
        })),
    'Failed to fetch forecast sources',
  );
}

interface AddForecastSourceResponse {
  data: { forecastSource: ForecastSourceViewModel };
}

export async function addForecastSource(
  platformId: string,
  name: string,
  type: string,
) {
  return await handleApiError<{ data: ForecastSourceViewModel }>(
    () =>
      agent
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/forecast-sources`,
          {
            platformId,
            name,
            type,
          },
        )
        .then(({ data }: AddForecastSourceResponse) => ({
          data: data.forecastSource,
        })),
    'Failed to add forecast source',
  );
}
