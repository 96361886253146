import {
  createAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';
import * as R from 'ramda';

import { RootState } from '../../app/store';
import { addEdgeDevice, fetchEdgeDevices } from './edgeDevicesAPI';
import userFriendlyLoadingTime from '../userFriendlyLoadingTime';
import { RequestStatuses, RequestState } from '../requestTypes';
import {
  NewEdgeDevice,
  EdgeDeviceViewModel,
  EdgeDevicesViewModel,
} from './edgeDeviceTypes';
import entitySliceBuilder from '../common/entitySliceCreator';
import { RejectValue } from '../common/errorTypes';
import { executeWithRejectValue } from '../common/executeWithRejectValue';

export interface EdgeDeviceState {
  addEntityState: RequestState;
  entities: { [key: string]: EdgeDeviceViewModel } | {};
}

const initialState = {
  entities: {},
  addEntityState: {
    status: RequestStatuses.idle,
    errors: [],
  },
};

export const fetchDevicesAsync = createAsyncThunk<
  EdgeDevicesViewModel,
  void,
  RejectValue
>('edgeDevices/fetchEdgeDevices', async (_0, { rejectWithValue }) => {
  return await executeWithRejectValue(async () => {
    const response = await fetchEdgeDevices();
    return response.data;
  }, rejectWithValue);
});

const timeout = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const addDeviceAsync = createAsyncThunk<
  EdgeDeviceViewModel,
  NewEdgeDevice,
  RejectValue
>(
  'edgeDevices/addEdgeDevice',
  async (newEdgeDevice: NewEdgeDevice, { rejectWithValue }) => {
    return await executeWithRejectValue(async () => {
      const response = await addEdgeDevice(newEdgeDevice);
      await timeout(userFriendlyLoadingTime);
      return response.data;
    }, rejectWithValue);
  },
);

export const clearAddDeviceStatus = createAction(
  'edgeDevices/clearAddDeviceStatus',
);

const { entityReducers, extraEntityReducers } = entitySliceBuilder<
  NewEdgeDevice,
  EdgeDeviceViewModel
>(addDeviceAsync, fetchDevicesAsync, clearAddDeviceStatus.type);

export const edgeDevicesSlice = createSlice({
  name: 'edgeDevices',
  initialState,
  reducers: {
    ...entityReducers,
  },
  extraReducers: (builder) => {
    extraEntityReducers(builder);
  },
});

export const getAddDeviceState = (state: RootState): RequestState =>
  state.edgeDevices.addEntityState;

export const getEdgeDevices = (
  state: RootState,
): EdgeDeviceViewModel[] | null =>
  Object.values(state.edgeDevices.entities);

export const getEdgeDeviceByGenUnit = (
  state: RootState,
  generationUnitId: string,
) => {
  const devices = Object.values<EdgeDeviceViewModel>(
    state.edgeDevices.entities,
  );

  return devices.find(
    (device) => device.generationUnitId === generationUnitId,
  );
};

export const getEdgeDeviceById = (
  state: RootState,
  edgeDeviceId: string,
): EdgeDeviceViewModel | null =>
  R.path([edgeDeviceId], state.edgeDevices.entities) || null;

export default edgeDevicesSlice.reducer;
