import { MouseEventHandler } from 'react';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

interface Props {
  show: boolean;
  onClick: MouseEventHandler;
}

const UpdateEventBtn = ({ show, onClick }: Props) => {
  if (!show) return <></>;

  return (
    <IconButton onClick={onClick} aria-label="edit event">
      <EditIcon />
    </IconButton>
  );
};

export default UpdateEventBtn;
