import { Grid, Chip, Typography } from '@mui/material';
import EventAccordion from '../EventAccordion';
import {
  EventStatus,
  DowntimeEventViewModel,
  downtimeEventTypes,
} from '../../../state/events/eventTypes';
import { MouseEventHandler } from 'react';
import EventRowActionBtn from '../EventRowActionBtn';
import {
  isDeleteable,
  isDiscarded,
  isEditable,
} from '../helpers/statusHelpers';
import { useAppSelector } from '../../../hooks/common';
import { getElectrolyzerDisplayNameById } from '../../../state/electrolyzers/electrolyzerSlice';

interface Props {
  event: DowntimeEventViewModel;
  handleDelete: MouseEventHandler;
  onClickStart: MouseEventHandler;
  onClickEdit: MouseEventHandler;
  handleEnd: MouseEventHandler;
  isPendingEnd: boolean;
  isPendingDeletion: boolean;
}

const DowntimeEventRow = ({
  event,
  event: { type, status },
  isPendingDeletion,
  isPendingEnd,
  handleDelete,
  onClickStart,
  onClickEdit,
  handleEnd,
}: Props) => {
  const electrolyzerDisplayName = useAppSelector((state) =>
    getElectrolyzerDisplayNameById(state, event.electrolyzerId),
  );

  const eventIsDiscarded = isDiscarded(status);
  const eventIsDeleteable = isDeleteable(status);
  const eventIsEditable = isEditable(status);

  const canStart = [
    EventStatus.CONFIRMED,
    EventStatus.NEVER_STARTED,
  ].includes(status);
  const hasStarted = status === EventStatus.STARTED;

  const typeLabel = downtimeEventTypes[type]
    ? downtimeEventTypes[type].label
    : 'unknown';

  return (
    <EventAccordion
      event={event}
      isDiscarded={eventIsDiscarded}
      isDeleteable={eventIsDeleteable}
      isEditable={eventIsEditable}
      isPendingDeletion={isPendingDeletion}
      handleDelete={handleDelete}
      onClickEdit={onClickEdit}
      eventTypeChip={
        <Chip color="error" label="Downtime" size="small" />
      }
      eventTypeSpecificInfo={typeLabel}
      eventTypeSpecificActionBtn={
        <EventRowActionBtn
          showButton={canStart || hasStarted}
          showEnd={hasStarted}
          onClickStart={onClickStart}
          onClickEnd={handleEnd}
          isPendingEnd={isPendingEnd}
        />
      }
    >
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            fontWeight="bold"
            component="span"
          >
            Electrolyzer:&nbsp;
          </Typography>
          <Typography variant="body1" component="span">
            {electrolyzerDisplayName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            fontWeight="bold"
            component="span"
          >
            Type:&nbsp;
          </Typography>
          <Typography variant="body1" component="span">
            {typeLabel}
          </Typography>
        </Grid>
      </Grid>
    </EventAccordion>
  );
};

export default DowntimeEventRow;
