import React from 'react';
import Grid from '@mui/material/Grid';

import CurrentActivityCard from './CurrentActivityCard';
import EdgeDeviceActivityCard from '../edgeDevice/EdgeDeviceActivityCard';
import ElectrolyzerActivityCard from '../electrolyzer/ElectrolyzerActivityCard';
import StorageUnitsActivityCard from '../storage/StorageUnitsActivityCard';

interface CurrentActivityProps {
  generationUnitId: string;
}

const CurrentActivity = ({
  generationUnitId,
}: CurrentActivityProps) => (
  <Grid container spacing={2}>
    <Grid item xs={12} md={3}>
      <CurrentActivityCard>
        <EdgeDeviceActivityCard generationUnitId={generationUnitId} />
      </CurrentActivityCard>
    </Grid>
    <Grid item xs={12} md={6}>
      <CurrentActivityCard>
        <ElectrolyzerActivityCard
          generationUnitId={generationUnitId}
        />
      </CurrentActivityCard>
    </Grid>
    <Grid item xs={12} md={3}>
      <CurrentActivityCard>
        <StorageUnitsActivityCard
          generationUnitId={generationUnitId}
        />
      </CurrentActivityCard>
    </Grid>
  </Grid>
);

export default CurrentActivity;
