import React from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import {
  RequestState,
  RequestStatuses,
} from '../../state/requestTypes';
import { ModalContents, ModalForm } from './modalStyles';
import Stack from '@mui/material/Stack';
import ModalTitle from './ModalTitle';
import Errors from './Errors';

export const enum Operation {
  add = 'add',
  update = 'update',
}

const btnTextMap = {
  [Operation.add]: {
    [RequestStatuses.idle]: 'Add',
    [RequestStatuses.rejected]: 'Add',
    [RequestStatuses.pending]: 'Adding...',
    [RequestStatuses.fulfilled]: 'Added',
  },
  [Operation.update]: {
    [RequestStatuses.idle]: 'Update',
    [RequestStatuses.rejected]: 'Update',
    [RequestStatuses.pending]: 'Updating...',
    [RequestStatuses.fulfilled]: 'Updated',
  },
};

interface Props {
  btnConfig: {
    label: string;
    variant?: 'text' | 'outlined' | 'contained';
    color?: 'inherit' | 'primary' | 'secondary';
    size?: 'small' | 'medium' | 'large';
    iconOnly?: boolean;
  };
  disabled?: boolean;
  titleId: string;
  title: string;
  requestState: RequestState;
  clearStatusAction: () => void;
  clearFormValues: () => void;
  handleSubmit: () => void;
  children: JSX.Element;
  error?: string;
  operation?: Operation;
}

export default function EntityModal({
  btnConfig,
  titleId,
  title,
  requestState,
  clearStatusAction,
  clearFormValues,
  handleSubmit,
  error,
  children,
  operation = Operation.add,
  disabled = false,
}: Props) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    clearFormValues();
    clearStatusAction();
  };

  const getOperationIcon = () => {
    if (operation) {
      if (operation === Operation.add) {
        return <AddIcon />;
      } else if (operation === Operation.update) {
        return <EditIcon />;
      }
    }
    return <></>;
  };

  const formDisabled =
    requestState.status === RequestStatuses.fulfilled ||
    requestState.status === RequestStatuses.pending;

  return (
    <div>
      {btnConfig.iconOnly ? (
        <Tooltip title={btnConfig.label}>
          <span>
            <IconButton
              size="small"
              onClick={() => setOpen(true)}
              disabled={disabled}
            >
              {getOperationIcon()}
            </IconButton>
          </span>
        </Tooltip>
      ) : (
        <Button
          variant={btnConfig.variant || 'text'}
          color={btnConfig.color || 'inherit'}
          onClick={() => setOpen(true)}
          size={btnConfig.size || 'medium'}
          startIcon={getOperationIcon()}
          disabled={disabled}
        >
          {btnConfig.label}
        </Button>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby={titleId}
      >
        <ModalContents
          style={{
            width: '40%',
            maxWidth: '600px',
            minWidth: '400px',
          }}
        >
          <ModalTitle text={title} id={titleId} />
          <ModalForm
            onSubmit={(e) => {
              // Prevent the modal from closing automatically on submission
              e.preventDefault();
              return handleSubmit();
            }}
            autoComplete="off"
          >
            {React.cloneElement(children, { disabled: formDisabled })}
            {requestState.status === RequestStatuses.rejected && (
              <Errors errors={requestState.errors} />
            )}
            {requestState.status === RequestStatuses.fulfilled && (
              <Alert severity="success">
                Successfully{' '}
                {btnTextMap[operation][requestState.status]}
              </Alert>
            )}
            {error && <Alert severity="error">{error}</Alert>}
            <Stack direction="row" spacing={2}>
              <Button variant="outlined" onClick={handleClose}>
                {'Close'}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={formDisabled}
                sx={{ ml: 0.5 }}
              >
                {btnTextMap[operation][requestState.status]}
              </Button>
            </Stack>
          </ModalForm>
        </ModalContents>
      </Modal>
    </div>
  );
}
