import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import StatusChip, { ChipStatus } from '../common/StatusChip';
import TelemetryRequestStatus from '../common/TelemetryRequestStatus';
import { useGetElectrolyzerTelemetryQuery } from '../../app/services/telemetry';
import ProductionRate from './ProductionRate';
import { utcToLocalDate, getTimezone } from '../common/dateConverter';
import isUnauthorizedError from '../../state/common/unauthorizedError';
import { ElectrolyzerViewModel } from '../../state/electrolyzers/electrolyzerTypes';

interface ElectrolyzerTelemetryData {
  state: string;
  controllable: boolean;
  time: string;
  demandValue: number;
  maximumAvailableLoad: number;
  productionRate: number;
}

interface Props {
  electrolyzer: ElectrolyzerViewModel;
}

const ElectrolyzerTelemetry = ({ electrolyzer }: Props) => {
  const [pollingInterval, setPollingInterval] = useState(6000);
  const telemetryRequest = useGetElectrolyzerTelemetryQuery(
    electrolyzer.id,
    {
      pollingInterval,
    },
  );

  const telemetry = telemetryRequest.data?.electrolyzer
    ?.telemetry as ElectrolyzerTelemetryData | null;

  const displayFetchError = Boolean(
    telemetryRequest.isError ||
      (telemetryRequest.isFetching && telemetryRequest.error),
  );

  const isUnauthorized =
    displayFetchError && isUnauthorizedError(telemetryRequest.error);

  useEffect(() => {
    if (isUnauthorized) {
      setPollingInterval(0);
    } else {
      setPollingInterval(6000);
    }
  }, [setPollingInterval, isUnauthorized]);

  if (telemetryRequest.isLoading && !displayFetchError) {
    return <TelemetryRequestStatus loading={true} />;
  }

  if (telemetry && !displayFetchError) {
    const lastUpdated = utcToLocalDate(telemetry.time).format(
      'h:mm:ss A',
    );

    const capacity = Math.round(
      (telemetry.maximumAvailableLoad /
        electrolyzer.maximumLoad.value) *
        100,
    );

    return (
      <>
        <Grid container direction="row">
          <Grid item xs={9}>
            <Grid container direction="row" spacing={0}>
              <Grid item xs={4}>
                {telemetry.controllable ? (
                  <StatusChip
                    label="controllable"
                    status={ChipStatus.GOOD}
                  />
                ) : (
                  <StatusChip
                    label="not controllable"
                    status={ChipStatus.BAD}
                  />
                )}
              </Grid>
              <Grid item xs={4}>
                <StatusChip
                  label={`status: ${telemetry.state || 'off'}`}
                />
              </Grid>
              <Grid item xs={4}>
                <StatusChip
                  label={`capacity: ${capacity}%`}
                  status={
                    capacity === 100
                      ? ChipStatus.GOOD
                      : ChipStatus.BAD
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Typography variant="subtitle1" gutterBottom={false}>
          Production rate
        </Typography>
        <ProductionRate percentage={telemetry.productionRate} />
        <TelemetryRequestStatus
          lastUpdated={lastUpdated}
          timezone={getTimezone()}
        />
      </>
    );
  }

  return <TelemetryRequestStatus displayFetchError={true} />;
};

export default ElectrolyzerTelemetry;
