import dayjs from 'dayjs';
import * as R from 'ramda';
import { ChartXAxisValues } from '../charts/chartXAxisValuesCreator';
import createElectrolyzerDisplayName from '../electrolyzers/displayNameCreator';
import { ElectrolyzerViewModel } from '../electrolyzers/electrolyzerTypes';
import { OfftakerViewModel } from '../offtakers/offtakerTypes';
import {
  DowntimeEventViewModel,
  EventType,
  EventStatus,
  TransformedOfftakeEventViewModel,
  TransformedEventViewModel,
  RollingScheduleChartEventData,
} from './eventTypes';

const getOfftakeProps = (
  offtakeEvent: TransformedOfftakeEventViewModel,
  offtakers: { [id: string]: OfftakerViewModel },
  [earliestDisplayableTime, latestDisplayableTime]: number[],
) => {
  const { id, offtakerId, h2Quantity, start, end, eventType } =
    offtakeEvent;
  const offtakeStart = dayjs(start).valueOf();
  const offtakeEnd = dayjs(end).valueOf();
  const offtaker = offtakers[offtakerId];

  return {
    id,
    name: offtaker ? offtaker.name : 'Not found',
    abbreviation: offtaker ? offtaker.abbreviation : 'Not found',
    h2Quantity,
    start:
      offtakeStart < earliestDisplayableTime
        ? earliestDisplayableTime
        : offtakeStart,
    end:
      offtakeEnd > latestDisplayableTime
        ? latestDisplayableTime
        : offtakeEnd,
    eventType,
  };
};

const getDowntimeProps = (
  downtimeEvent: DowntimeEventViewModel,
  electrolyzers: { [id: string]: ElectrolyzerViewModel },
  [earliestDisplayableTime, latestDisplayableTime]: number[],
) => {
  const { id, electrolyzerId, type, eventType } = downtimeEvent;
  const start = dayjs(downtimeEvent.start).valueOf();
  const end = dayjs(downtimeEvent.end).valueOf();
  const electrolyzer = electrolyzers[electrolyzerId];

  return {
    id,
    electrolyzerDisplayName:
      createElectrolyzerDisplayName(electrolyzer),
    type,
    start:
      start < earliestDisplayableTime
        ? earliestDisplayableTime
        : start,
    end: end > latestDisplayableTime ? latestDisplayableTime : end,
    eventType,
  };
};

const eventStatusesToDisplayOnChart = [
  EventStatus.CONFIRMED,
  EventStatus.STARTED,
];

const collateEventChartData = (
  offtakers: { [id: string]: OfftakerViewModel },
  electrolyzers: { [id: string]: ElectrolyzerViewModel },
  eventsForGenerationUnit: TransformedEventViewModel[],
  xAxisValues: ChartXAxisValues,
): RollingScheduleChartEventData => {
  const xAxisBounds = [xAxisValues[0], R.last(xAxisValues) as number];
  return eventsForGenerationUnit
    .filter((event) => {
      if (!eventStatusesToDisplayOnChart.includes(event.status)) {
        return false;
      }

      return true;
    })
    .reduce(
      (acc, event) => {
        if (event.eventType === EventType.OFFTAKE) {
          acc.offtake.push(
            getOfftakeProps(event, offtakers, xAxisBounds),
          );
        }
        if (event.eventType === EventType.DOWNTIME) {
          acc.downtime.push(
            getDowntimeProps(event, electrolyzers, xAxisBounds),
          );
        }
        return acc;
      },
      { offtake: [], downtime: [] } as RollingScheduleChartEventData,
    );
};

export default collateEventChartData;
