import React from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { useAppSelector } from '../../hooks/common';
import { getEdgeDeviceById } from '../../state/edgeDevices/edgeDeviceSlice';
import { ModalContents } from '../common/modalStyles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

interface Props {
  edgeDeviceId: string;
  open: boolean;
  handleClose: () => void;
}

const EdgeDeviceSpecsModal = ({
  open,
  handleClose,
  edgeDeviceId,
}: Props) => {
  const edgeDevice = useAppSelector((state) =>
    getEdgeDeviceById(state, edgeDeviceId),
  );

  const modalBody = (
    <ModalContents>
      {edgeDevice ? (
        <Stack spacing={1}>
          <Typography variant="body1">
            <strong>Manufacturer: </strong>
            {edgeDevice.manufacturer}
          </Typography>
          <Typography variant="body1">
            <strong>Serial number: </strong>
            {edgeDevice.serialNumber}
          </Typography>
          <Typography variant="body1">
            <strong>IIoT thing name: </strong>
            {edgeDevice.iiotThingName}
          </Typography>
          <Stack direction="row" justifyContent="end">
            <Button variant="outlined" onClick={handleClose}>
              {'Close'}
            </Button>
          </Stack>
        </Stack>
      ) : (
        <Typography variant="body1">Edge device not found</Typography>
      )}
    </ModalContents>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="edge-device-technical-specifications"
      aria-describedby="technical-data-about-an-edge-device"
    >
      {modalBody}
    </Modal>
  );
};

export default EdgeDeviceSpecsModal;
