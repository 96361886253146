import {
  createAction,
  createAsyncThunk,
  createSlice,
} from '@reduxjs/toolkit';

import * as R from 'ramda';

import { RootState } from '../../app/store';
import userFriendlyLoadingTime from '../userFriendlyLoadingTime';
import { RequestState, RequestStatuses } from '../requestTypes';
import entitySliceBuilder from '../common/entitySliceCreator';

import {
  OfftakerViewModel,
  OfftakersViewModel,
  NewOfftaker,
  OfftakersSelectOptsViewModel,
} from './offtakerTypes';

import { addOfftaker, fetchOfftakers } from './offtakerAPI';
import { RejectValue } from '../common/errorTypes';
import { executeWithRejectValue } from '../common/executeWithRejectValue';

export interface OfftakerState {
  addEntityState: RequestState;
  entities: { [key: string]: OfftakerViewModel };
}

const initialState = {
  entities: {},
  addEntityState: {
    status: RequestStatuses.idle,
    errors: [],
  },
};

const timeout = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const addOfftakerAsync = createAsyncThunk<
  OfftakerViewModel,
  NewOfftaker,
  RejectValue
>(
  'offtakers/addOfftaker',
  async (
    { name, abbreviation }: NewOfftaker,
    { rejectWithValue },
  ) => {
    return await executeWithRejectValue(async () => {
      const response = await addOfftaker(name, abbreviation);
      await timeout(userFriendlyLoadingTime);
      return response.data;
    }, rejectWithValue);
  },
);

export const fetchOfftakersAsync = createAsyncThunk<
  OfftakersViewModel,
  void,
  RejectValue
>('offtakers/fetchOfftakers', async (_0, { rejectWithValue }) => {
  return await executeWithRejectValue(async () => {
    const response = await fetchOfftakers();
    return response.data;
  }, rejectWithValue);
});

export const clearAddOfftakerStatus = createAction(
  'offtakers/clearAddOfftakerStatus',
);

export const getAddOfftakerState = (state: RootState): RequestState =>
  state.offtakers.addEntityState;

export const getOfftakersSelectOpts = (
  state: RootState,
): OfftakersSelectOptsViewModel[] => {
  const offtakers: OfftakerViewModel[] = Object.values(
    state.offtakers.entities,
  );
  return offtakers.map((o) => ({ value: o.id, label: o.name }));
};

export const getOfftakerEntities = (
  state: RootState,
): { [key: string]: OfftakerViewModel } => state.offtakers.entities;

export const getSortedOfftakers = (
  state: RootState,
): OfftakerViewModel[] => {
  const values = R.values(
    state.offtakers.entities,
  ) as OfftakerViewModel[];
  return R.sortWith([R.ascend(R.prop('name'))], values);
};

export const getOfftakerById = (
  state: RootState,
  id: string,
): OfftakerViewModel | null => {
  return R.path([id], state.offtakers.entities) || null;
};

const { entityReducers, extraEntityReducers } = entitySliceBuilder<
  NewOfftaker,
  OfftakerViewModel
>(addOfftakerAsync, fetchOfftakersAsync, clearAddOfftakerStatus.type);

const offtakerSlice = createSlice({
  name: 'offtakers',
  initialState,
  reducers: {
    ...entityReducers,
  },
  extraReducers: (builder) => {
    extraEntityReducers(builder);
  },
});

export default offtakerSlice.reducer;
