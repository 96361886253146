import React from 'react';

import {
  useAppDispatch,
  useAppSelector,
} from '../../../hooks/common';
import EntityModal from '../../common/EntityModal';
import {
  addForecastSourceAsync,
  clearAddForecastSourceStatus,
  getAddForecastSourceState,
} from '../../../state/forecastSources/forecastSourcesSlice';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import { ForecastSourceType } from '../../../state/forecastSources/forecastSourceTypes';

const initialFormValues = {
  platformId: '',
  name: '',
  type: '',
};

const types: { [key: string]: string } = {
  'Grid Price': ForecastSourceType.GRID_PRICE,
  Solar: ForecastSourceType.SOLAR,
  'Landfill Gas Power': ForecastSourceType.LANDFILL_GAS_POWER,
};

export default function AddForecastSource() {
  const dispatch = useAppDispatch();
  const requestState = useAppSelector(getAddForecastSourceState);
  const [formValues, setFormValues] =
    React.useState(initialFormValues);

  return (
    <div>
      <EntityModal
        btnConfig={{ label: 'Add forecast source' }}
        title="Add a new forecast source"
        titleId="add-forecast-source-title"
        requestState={requestState}
        clearStatusAction={() =>
          dispatch(clearAddForecastSourceStatus())
        }
        clearFormValues={() => setFormValues(initialFormValues)}
        handleSubmit={() => {
          const { platformId, name, type } = formValues;

          dispatch(
            addForecastSourceAsync({
              platformId,
              name,
              type,
            }),
          );
        }}
      >
        <Stack spacing={3}>
          <TextField
            fullWidth
            id="forecast-source-platformId"
            required
            onInput={(e) =>
              setFormValues({
                ...formValues,
                platformId: (e.target as HTMLTextAreaElement).value,
              })
            }
            label="Platform Id"
            variant="filled"
            value={formValues.platformId}
          />
          <TextField
            fullWidth
            id="forecast-source-name"
            required
            onInput={(e) =>
              setFormValues({
                ...formValues,
                name: (e.target as HTMLTextAreaElement).value,
              })
            }
            label="Forecast Name"
            variant="filled"
            value={formValues.name}
          />
          <Autocomplete
            id="forecast-source-type"
            options={Object.keys(types)}
            onChange={(e, value: string | null) =>
              value &&
              setFormValues({
                ...formValues,
                type: types[value],
              })
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Type"
                value={formValues.type}
                required
                variant="filled"
                inputProps={{
                  ...params.inputProps,
                  required:
                    formValues.type.length < 1 ||
                    formValues.type[0] === '',
                }}
              />
            )}
          />
        </Stack>
      </EntityModal>
    </div>
  );
}
