import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';
import dayjs from 'dayjs';
import * as R from 'ramda';
import { RequestState, RequestStatuses } from '../requestTypes';
import { fetchRollingScheduleByGenerationUnitId } from './rollingSchedulesAPI';
import {
  RollingScheduleChartProductionRateDataRow,
  RollingScheduleExportData,
  RollingScheduleViewModel,
} from './rollingScheduleTypes';
import { RootState } from '../../app/store';
import { RejectValue } from '../common/errorTypes';
import { executeWithRejectValue } from '../common/executeWithRejectValue';
import transformScheduleProductionForChart, {
  transformToProductionPlanChartData,
} from './scheduleProductionTransformer';
import { H2MeasurementUnit } from '../../common/types';
import roundToTwoDp, { roundToOneDp } from '../common/rounder';
import { convertToNm3 } from '../common/valueUnitConvertor';

interface RollingSchedulesState {
  fetchRollingScheduleState: RequestState;
  entities: { [key: string]: RollingScheduleViewModel };
}

const initialState: RollingSchedulesState = {
  entities: {},
  fetchRollingScheduleState: {
    status: RequestStatuses.idle,
    errors: [],
  },
};

export const fetchRollingScheduleByGenerationUnitIdAsync =
  createAsyncThunk<RollingScheduleViewModel, string, RejectValue>(
    'rollingSchedules/fetchRollingScheduleByGenerationUnitId',
    async (generationUnitId: string, { rejectWithValue }) => {
      return await executeWithRejectValue(async () => {
        const response = await fetchRollingScheduleByGenerationUnitId(
          generationUnitId,
        );
        return response.data;
      }, rejectWithValue);
    },
  );

export const rollingSchedulesSlice = createSlice({
  name: 'rollingSchedules',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchRollingScheduleByGenerationUnitIdAsync.fulfilled,
        (
          state: RollingSchedulesState,
          action: PayloadAction<RollingScheduleViewModel>,
        ) => {
          if (action.payload) {
            state.entities = {
              ...state.entities,
              [action.payload.id]: action.payload,
            };
          }

          state.fetchRollingScheduleState = {
            status: RequestStatuses.fulfilled,
            errors: [],
          };
        },
      )
      .addCase(
        fetchRollingScheduleByGenerationUnitIdAsync.pending,
        (state: RollingSchedulesState) => {
          state.fetchRollingScheduleState = {
            status: RequestStatuses.pending,
            errors: [],
          };
        },
      )
      .addCase(
        fetchRollingScheduleByGenerationUnitIdAsync.rejected,
        (state: RollingSchedulesState, action) => {
          state.fetchRollingScheduleState = {
            status: RequestStatuses.rejected,
            errors: action.payload?.errors,
          };
        },
      );
  },
});

const createdAtDescComparator = function (
  a: RollingScheduleViewModel,
  b: RollingScheduleViewModel,
) {
  return dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix();
};

const getMostRecentRollingScheduleForGenerationUnit = (
  state: RootState,
  generationUnitId: string,
  valuesUnit?: string,
): RollingScheduleViewModel | null => {
  const { entities } = state.rollingSchedules;

  if (!Object.keys(entities).length) return null;

  return R.pipe(
    R.values<{ [key: string]: RollingScheduleViewModel }, string>,
    R.filter<RollingScheduleViewModel>(
      R.propEq('generationUnitId', generationUnitId),
    ),
    R.sort(createdAtDescComparator),
    (sortedSchedules) => {
      const [mostRecentRollingSchedule] = sortedSchedules;
      if (!mostRecentRollingSchedule) {
        return null;
      }

      return valuesUnit === H2MeasurementUnit.NM3
        ? convertRollingScheduleUnitsToNm3(mostRecentRollingSchedule)
        : mostRecentRollingSchedule;
    },
  )(entities);
};

export const convertRollingScheduleUnitsToNm3 = (
  rollingSchedule: RollingScheduleViewModel,
): RollingScheduleViewModel => ({
  ...rollingSchedule,
  production: rollingSchedule.production.map((planItem) => ({
    ...planItem,
    cumulativeH2: {
      periodStart: convertToNm3(planItem.cumulativeH2.periodStart),
      periodEnd: convertToNm3(planItem.cumulativeH2.periodEnd),
    },
    totalH2Production: convertToNm3(planItem.totalH2Production),
    actions: planItem.actions.map((action) => ({
      ...action,
      productionRate: convertToNm3(action.productionRate),
    })),
  })),
});

export const getScheduleProductionRateDataByGenerationUnitId =
  createCachedSelector(
    getMostRecentRollingScheduleForGenerationUnit,
    (
      _state_: RootState,
      _generationUnitId: string,
      _valuesUnit: H2MeasurementUnit,
      staticStorageUnitTotalCapacity: number,
    ) => staticStorageUnitTotalCapacity,
    (
      rollingSchedule: RollingScheduleViewModel | null,
      staticStorageUnitTotalCapacity: number,
    ): RollingScheduleChartProductionRateDataRow[] | null => {
      if (!rollingSchedule) return null;

      return transformScheduleProductionForChart(
        rollingSchedule.production,
        staticStorageUnitTotalCapacity,
      );
    },
  )((_state_, _generationUnitId, _valuesUnit) => _generationUnitId);

export const getRollingScheduleCSVExportData = (
  state: RootState,
  generationUnitId: string,
): RollingScheduleExportData | null => {
  const latestSchedule =
    getMostRecentRollingScheduleForGenerationUnit(
      state,
      generationUnitId,
    );

  if (!latestSchedule) return null;

  return R.map(
    ({
      periodStart,
      periodEnd,
      totalH2Production,
      totalCost,
      subsidyDiscount,
      actions,
    }) => ({
      periodStart,
      periodEnd,
      totalH2Production,
      productionRate: roundToOneDp(actions[0].productionRate),
      totalCostInPounds: roundToTwoDp(totalCost / 100),
      subsidyDiscountInPounds: roundToTwoDp(subsidyDiscount / 100),
    }),
    latestSchedule.production,
  );
};

export const getProductionPlanChartDataByGenUnitId =
  createCachedSelector(
    getScheduleProductionRateDataByGenerationUnitId,
    (
      rollingScheduleChartProductionRateData:
        | RollingScheduleChartProductionRateDataRow[]
        | null,
    ) =>
      rollingScheduleChartProductionRateData &&
      rollingScheduleChartProductionRateData.length > 0
        ? transformToProductionPlanChartData(
            rollingScheduleChartProductionRateData,
          )
        : null,
  )((_state_, generationUnitId, _valuesUnit) => generationUnitId);

export default rollingSchedulesSlice.reducer;
