import dayjs from 'dayjs';
import { RollingScheduleChartProductionRateDataRow } from './rollingScheduleTypes';
import {
  ProductionInterval,
  ProductionPlanChartData,
} from '../common/types';
import { roundToOneDp } from '../common/rounder';

const scheduleProductionTransformer = (
  production: ProductionInterval[],
  staticStorageUnitTotalCapacity: number | null,
): RollingScheduleChartProductionRateDataRow[] =>
  production.map(({ actions, periodEnd, storageLevels }) => {
    const combinedStorageLevel = storageLevels['static'].reduce(
      (combinedLevel: number, { level }) => combinedLevel + level,
      0,
    );
    return {
      productionRate: roundToOneDp(actions[0]?.productionRate ?? 0),
      periodStart: dayjs(periodEnd).valueOf(),
      storageLevel: combinedStorageLevel,
      staticStorageLevelPercentage: staticStorageUnitTotalCapacity
        ? Math.round(
            (combinedStorageLevel / staticStorageUnitTotalCapacity) *
              100,
          )
        : null,
    };
  });

export const transformToProductionPlanChartData = (
  rollingScheduleChartProductionRateData: RollingScheduleChartProductionRateDataRow[],
): ProductionPlanChartData => ({
  productionRateData: rollingScheduleChartProductionRateData.map(
    ({ periodStart, productionRate }) => ({
      periodStart,
      productionRate: roundToOneDp(productionRate),
    }),
  ),
});

export default scheduleProductionTransformer;
