import { DetailedErrorMessage } from './errorTypes';

export class RequestFailedError extends Error {
  message: string;
  errors?: DetailedErrorMessage[];

  constructor(message: string, errors: DetailedErrorMessage[]) {
    super(message);
    this.message = message;
    this.errors = errors;
  }
}
