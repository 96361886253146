import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import paths from './paths';
import Dashboard from './pages/Dashboard';
import PowerCostEstimator from './pages/PowerCostEstimator';
import Events from './pages/Events';
import Offtakers from './pages/Offtakers';

import { useAppDispatch } from './hooks/common';

import TopNav from './components/nav/TopNav';
import DrawerNav from './components/nav/DrawerNav';

import { fetchUnitsAsync } from './state/generationUnits/generationUnitsSlice';
import { fetchDevicesAsync } from './state/edgeDevices/edgeDeviceSlice';
import { fetchElectrolyzersAsync } from './state/electrolyzers/electrolyzerSlice';
import { fetchForecastSourcesAsync } from './state/forecastSources/forecastSourcesSlice';
import { userActions } from './state/user/userSlice';
import { fetchStorageUnitsAsync } from './state/storageUnits/storageUnitSlice';
import { fetchOfftakersAsync } from './state/offtakers/offtakerSlice';

const RootDiv = styled('div')(() => ({
  display: 'flex',
}));

const Content = styled('main')(() => ({
  flexGrow: 1,
  height: '100vh',
  overflow: 'auto',
}));

const AppBarSpacer = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

const configureAxios = (
  bearerToken: string,
  csrfToken: string,
  logout: Function,
) => {
  axios.defaults.headers.common[
    'Authorization'
  ] = `Bearer ${bearerToken}`;
  axios.defaults.headers.common['x-csrf-token'] = csrfToken;
  axios.interceptors.response.use(
    (res) => res,
    (err) => {
      if (err.response?.status === 401) {
        logout({ returnTo: window.location.origin });
      }
      throw err;
    },
  );
};

const configureAxiosCsrfDefaults = () => {
  axios.defaults.withCredentials = true;
};

const getCsrfToken = async () => {
  return await axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/csrf`)
    .then(({ data }: any) => data.csrfToken);
};

export default function LoggedInView() {
  const { isAuthenticated, logout, getAccessTokenSilently } =
    useAuth0();

  const dispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    (async () => {
      try {
        configureAxiosCsrfDefaults();
        const csrfToken = await getCsrfToken();
        const bearerToken = await getAccessTokenSilently();
        configureAxios(bearerToken, csrfToken, logout);

        dispatch(fetchUnitsAsync());
        dispatch(fetchDevicesAsync());
        dispatch(fetchElectrolyzersAsync());
        dispatch(fetchForecastSourcesAsync());
        dispatch(fetchStorageUnitsAsync());
        dispatch(fetchOfftakersAsync());
        dispatch(userActions.getUserPermissions(bearerToken));
      } catch (e) {
        console.error(e);
      }
    })();
  }, [getAccessTokenSilently, isAuthenticated, logout, dispatch]);

  return (
    <Router>
      <RootDiv>
        <TopNav
          open={open}
          handleDrawerOpen={() => setOpen(true)}
          handleLogout={() =>
            logout({ returnTo: window.location.origin })
          }
        />
        <DrawerNav
          open={open}
          handleDrawerClose={() => setOpen(false)}
        />
        <Content>
          <AppBarSpacer />
          <Container
            maxWidth="lg"
            sx={{
              paddingTop: 3,
              paddingBottom: 4,
            }}
          >
            <Switch>
              <Route path={paths.dashboard}>
                <Stack spacing={1}>
                  <Dashboard />
                </Stack>
              </Route>
              <Route path={paths.powerCostEstimator}>
                <PowerCostEstimator />
              </Route>
              <Route path={paths.events}>
                <Events />
              </Route>
              <Route path={paths.offtakers}>
                <Offtakers />
              </Route>
              <Redirect from="/" to={paths.dashboard} />
            </Switch>
          </Container>
        </Content>
      </RootDiv>
    </Router>
  );
}
