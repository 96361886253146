import { handleApiError } from '../common/apiErrorHandler';

import {
  ElectrolyzersViewModel,
  ElectrolyzerViewModel,
  NewElectrolyzer,
  UpdatedElectrolyzer,
} from './electrolyzerTypes';

const agent = require('axios');

interface FetchElectrolyzersResponse {
  data: { electrolyzers: ElectrolyzersViewModel };
}

export async function fetchElectrolyzers() {
  return await handleApiError<{ data: ElectrolyzersViewModel }>(
    () =>
      agent
        .get(`${process.env.REACT_APP_BACKEND_URL}/electrolyzers`)
        .then(({ data }: FetchElectrolyzersResponse) => ({
          data: data.electrolyzers,
        })),
    'Failed to fetch electrolyzer',
  );
}

interface AddElectrolyzerResponse {
  data: { electrolyzer: ElectrolyzerViewModel };
}

interface UpdateElectrolyzerResponse {
  data: { electrolyzer: UpdatedElectrolyzer };
}

export async function addElectrolyzer(
  newElectrolyzer: NewElectrolyzer,
) {
  return await handleApiError<{ data: ElectrolyzerViewModel }>(
    () =>
      agent
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/electrolyzers`,
          newElectrolyzer,
        )
        .then(({ data }: AddElectrolyzerResponse) => ({
          data: data.electrolyzer,
        })),
    'Failed to add electrolyzer',
  );
}

export async function updateElectrolyzer(
  updatedElectrolyzer: UpdatedElectrolyzer,
) {
  const { id, ...electrolyzerData } = updatedElectrolyzer;
  return await handleApiError<{ data: ElectrolyzerViewModel }>(
    () =>
      agent
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/electrolyzers/${id}`,
          electrolyzerData,
        )
        .then(({ data }: UpdateElectrolyzerResponse) => ({
          data: data.electrolyzer,
        })),
    'Failed to update electrolyzer',
  );
}
