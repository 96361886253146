import React from 'react';
import { styled } from '@mui/material/styles';

const Blob = styled('div')<{
  color?: LiveDataIconStatus;
}>(({ theme, color }) => ({
  borderRadius: '50%',
  height: '10px',
  width: '10px',
  boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
  transform: 'scale(1)',
  background:
    color === 'success'
      ? theme.palette.success.light
      : theme.palette.error.light,
  '@keyframes pulsate': {
    from: {
      opacity: 1,
      transform: 'scale(0.85)',
    },
    to: {
      opacity: 0.5,
      transform: 'scale(1)',
    },
  },
  animation: 'pulsate 2s infinite ease',
}));

export type LiveDataIconStatus = 'success' | 'error';

interface LiveDataIconProps {
  status?: LiveDataIconStatus;
}

const LiveDataIcon = ({ status = 'success' }: LiveDataIconProps) => {
  return <Blob color={status}></Blob>;
};

export default LiveDataIcon;
