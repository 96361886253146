import React, { useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import ProductionPlanChart from './ProductionPlanChart';
import { useAppDispatch, useAppSelector } from '../../hooks/common';
import {
  fetchRollingScheduleByGenerationUnitIdAsync,
  getProductionPlanChartDataByGenUnitId,
} from '../../state/rollingSchedules/rollingSchedulesSlice';
import { getUserValuesUnit } from '../../state/user/userSlice';
import Grid from '@mui/material/Grid';
import RollingScheduleStatus from '../events/RollingScheduleStatus';

interface Props {
  generationUnitId: string;
}

const ChartContainer = styled('div')(({ theme }) => ({
  height: '250px',
  background: theme.palette.background.default,
}));

const ProductionPlan = ({ generationUnitId }: Props) => {
  const userValuesUnit = useAppSelector(getUserValuesUnit);

  const productionPlan = useAppSelector((state) =>
    getProductionPlanChartDataByGenUnitId(
      state,
      generationUnitId,
      userValuesUnit,
    ),
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      try {
        dispatch(
          fetchRollingScheduleByGenerationUnitIdAsync(
            generationUnitId,
          ),
        );
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, generationUnitId]);

  return (
    <Card variant="outlined" sx={{ backgroundColor: 'divider' }}>
      <CardContent>
        <Typography component="h3" variant="subtitle1" gutterBottom>
          Planned production
        </Typography>
        {productionPlan && (
          <>
            <ChartContainer data-testid="production-plan-chart">
              <ProductionPlanChart data={productionPlan} />
            </ChartContainer>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <RollingScheduleStatus
                  generationUnitId={generationUnitId}
                />
              </Grid>
            </Grid>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default ProductionPlan;
