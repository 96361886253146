import React from 'react';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { useAppSelector } from '../../hooks/common';
import { getElectrolyzerById } from '../../state/electrolyzers/electrolyzerSlice';
import { ModalContents } from '../common/modalStyles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import UpdateElectrolyzer from './UpdateElectrolyzer';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';

interface Props {
  electrolyzerId: string;
  open: boolean;
  handleClose: () => void;
}

const ElectrolyzerSpecsModal = ({
  open,
  handleClose,
  electrolyzerId,
}: Props) => {
  const electrolyzer = useAppSelector((state) =>
    getElectrolyzerById(state, electrolyzerId),
  );

  const modalBody = (
    <ModalContents
      style={{ width: '30%', maxWidth: '600px', minWidth: '500px' }}
    >
      {electrolyzer && (
        <Stack spacing={1}>
          <Grid container justifyContent="flex-end">
            <UpdateElectrolyzer electrolyzer={electrolyzer} />
          </Grid>
          <Typography variant="body1">
            <strong>Manufacturer: </strong>
            {electrolyzer.manufacturer}
          </Typography>
          <Typography variant="body1">
            <strong>Serial number: </strong>
            {electrolyzer.serialNumber}
          </Typography>
          <Typography variant="body1">
            <Tooltip
              title="Unit of the value used for controlling the electrolyzer"
              aria-label="control value unit explanation"
            >
              <strong>Control value unit: </strong>
            </Tooltip>
            {electrolyzer.controlValue.unit}
          </Typography>
          <Typography variant="body1">
            <Tooltip
              title="The number of decimal places the electrolyzer permits for control values"
              aria-label="control value precision explanation"
            >
              <strong>Control value decimal precision: </strong>
            </Tooltip>
            {electrolyzer.controlValue.decimalPrecision}
          </Typography>
          <Typography variant="body1">
            <Tooltip
              title="The kg/hr output you can expect from control values"
              aria-label="control value to kg/hr conversion explanation"
            >
              <strong>Control value to kg/hr conversion: </strong>
            </Tooltip>
          </Typography>
          <ul>
            {electrolyzer.controlValue.kgPerHrRelationship.map(
              ({ controlValue, kgPerHr }) => (
                <li key={controlValue}>
                  <Typography variant="body1">
                    {controlValue} {electrolyzer.controlValue.unit}{' '}
                    produces {kgPerHr} kg/hr
                  </Typography>
                </li>
              ),
            )}
          </ul>
          <Typography variant="body1">
            <Tooltip
              title="The expected power required to produce an amount of hydrogen kg/hr"
              aria-label="electrolyzer efficiency explanation"
            >
              <strong>Efficiency: </strong>
            </Tooltip>
          </Typography>
          <ul>
            {electrolyzer.efficiency.map(
              ({ kgPerHr, minKW, maxKW }) => (
                <li key={kgPerHr}>
                  <Typography variant="body1">
                    {kgPerHr} kg/hr requires {minKW} - {maxKW} kW
                  </Typography>
                </li>
              ),
            )}
          </ul>
          {electrolyzer.maximumLoad && (
            <Typography variant="body1">
              <strong>Maximum load:</strong>&nbsp;
              {electrolyzer.maximumLoad.value}{' '}
              {electrolyzer.maximumLoad.unit}
            </Typography>
          )}
          <Tooltip
            title="The number of seconds that must elapse after one production rate change before another can occur"
            aria-label="Minimum interval between production rate changes explanation"
          >
            <Typography variant="body1">
              <strong>
                Minimum interval between production rate changes:{' '}
              </strong>
              {electrolyzer.minRateChangeIntervalSeconds}
              {' seconds'}
            </Typography>
          </Tooltip>
          <Typography variant="body1">
            <strong>
              Min. continuous time (seconds) electrolyzer must:{' '}
            </strong>
            <ul>
              <li>
                produce H₂ once production has started:{' '}
                {electrolyzer.minZeroProductionIntervalSeconds}
              </li>
              <li>
                not produce H₂ once production has stopped:{' '}
                {electrolyzer.minNonZeroProductionIntervalSeconds}
              </li>
            </ul>
          </Typography>
          <Stack direction="row" justifyContent="end">
            <Button variant="outlined" onClick={handleClose}>
              {'Close'}
            </Button>
          </Stack>
        </Stack>
      )}
    </ModalContents>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="electrolyzer-technical-specifications"
      aria-describedby="technical-data-about-an-electrolyzer"
    >
      {modalBody}
    </Modal>
  );
};

export default ElectrolyzerSpecsModal;
