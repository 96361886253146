const styleProps = (options = { hasRightAxis: false }) => ({
  width: 500,
  height: 300,
  margin: {
    top: 30,
    right: options.hasRightAxis ? -15 : 40,
    left: -15,
    bottom: 20,
  },
});

export default styleProps;
