import React from 'react';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { OfftakersSelectOptsViewModel } from '../../../state/offtakers/offtakerTypes';
import DatePicker from '../../common/DatePicker';
import calculateExpectedEnd from '../helpers/calculateExpectedEnd';

export interface OfftakeEventFormValues {
  expectedStart: null | Date;
  expectedEnd: null | Date;
  offtakerId: string;
  h2QuantityKg: string;
  renewableIncentiveEligibleMassKg?: string;
  h2IsRequiredUpFront: boolean;
}

interface Props {
  disabled: boolean;
  hasRenewableIncentive: boolean;
  hasStaticStorage: boolean;
  setFormValues: (formValues: OfftakeEventFormValues) => void;
  formValues: OfftakeEventFormValues;
  offtakersSelectOpts: OfftakersSelectOptsViewModel[];
}

const StyledFormControl = styled(FormControl)(() => ({
  minWidth: 220,
}));

const OfftakeEventFields = ({
  disabled,
  setFormValues,
  formValues,
  offtakersSelectOpts,
  hasRenewableIncentive,
  hasStaticStorage,
}: Props) => {
  return (
    <Grid container spacing={1}>
      <Grid item>
        <DatePicker
          disabled={disabled}
          label="Start"
          timeIntervals={15}
          value={formValues.expectedStart}
          handleChange={(value) =>
            setFormValues({
              ...formValues,
              expectedStart: value,
              expectedEnd: calculateExpectedEnd(
                value,
                formValues.expectedEnd,
              ),
            })
          }
        />
      </Grid>
      <Grid item>
        <DatePicker
          disabled={disabled}
          label="End"
          timeIntervals={15}
          value={formValues.expectedEnd}
          handleChange={(value) =>
            setFormValues({
              ...formValues,
              expectedEnd: value,
            })
          }
        />
      </Grid>
      <Grid item>
        <StyledFormControl>
          <Autocomplete
            id="offtakers"
            options={offtakersSelectOpts}
            getOptionLabel={(offtaker) => offtaker.label || ''}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            onChange={(e, offtaker) =>
              offtaker &&
              setFormValues({
                ...formValues,
                offtakerId: offtaker.value,
              })
            }
            value={
              offtakersSelectOpts.find(
                ({ value }) => formValues.offtakerId === value,
              ) || null
            }
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Offtakers"
                value={
                  offtakersSelectOpts.find(
                    (o) => o.value === formValues.offtakerId,
                  ) || null
                }
                required
                variant="filled"
                inputProps={{
                  ...params.inputProps,
                  required: formValues.offtakerId === '',
                }}
              />
            )}
          />
        </StyledFormControl>
      </Grid>
      <Grid item>
        <Stack spacing={1} direction="row">
          <TextField
            fullWidth
            id="h2CapacityKg"
            required
            type="number"
            onInput={(e) =>
              setFormValues({
                ...formValues,
                h2QuantityKg: (e.target as HTMLTextAreaElement).value,
              })
            }
            label="H₂ Qty (kg)"
            variant="filled"
            value={formValues.h2QuantityKg}
            disabled={disabled}
          />
          {hasRenewableIncentive && (
            <TextField
              fullWidth
              id="renewableIncentiveEligibleMassKg"
              type="number"
              onInput={(e) =>
                setFormValues({
                  ...formValues,
                  renewableIncentiveEligibleMassKg: (
                    e.target as HTMLTextAreaElement
                  ).value,
                })
              }
              label="Incentivised Qty (kg)"
              variant="filled"
              value={formValues.renewableIncentiveEligibleMassKg}
              disabled={disabled}
            />
          )}
        </Stack>
      </Grid>
      {hasStaticStorage && (
        <Grid item display="flex" alignItems="center">
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formValues.h2IsRequiredUpFront}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      h2IsRequiredUpFront: e.target.checked,
                    })
                  }
                />
              }
              label="Ensure H₂ produced before start"
            />
          </FormGroup>
        </Grid>
      )}
    </Grid>
  );
};

export default OfftakeEventFields;
