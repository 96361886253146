import dayjs from 'dayjs';
import { MouseEventHandler } from 'react';
import { Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordion, {
  AccordionProps,
} from '@mui/material/Accordion';
import DeleteEventBtn from './DeleteEventBtn';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import EventDeferralWarning from './EventDeferralWarning';
import {
  DowntimeEventViewModel,
  EventStatus,
  TransformedOfftakeEventViewModel,
} from '../../state/events/eventTypes';
import UpdateEventBtn from './UpdateEventBtn';
import EventStatusChip, {
  fromEventStatus,
} from '../common/EventStatusChip';

const advancedFormat = require('dayjs/plugin/advancedFormat');
dayjs.extend(advancedFormat);

interface Props {
  children: React.ReactNode;
  eventTypeChip: React.ReactNode;
  isDiscarded: boolean;
  isDeleteable: boolean;
  isEditable: boolean;
  isPendingDeletion: boolean;
  eventTypeSpecificInfo?: string;
  eventTypeSpecificActionBtn?: React.ReactNode;
  onClickEdit: MouseEventHandler;
  handleDelete: MouseEventHandler;
  event: TransformedOfftakeEventViewModel | DowntimeEventViewModel;
}

const Accordion = styled(
  (props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ),
  {
    shouldForwardProp: (prop) =>
      prop !== 'isDiscarded' && prop !== 'isDraft',
  },
)<{ isDiscarded: boolean }>(({ theme, isDiscarded }) => ({
  border: isDiscarded ? 'none' : `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '& .MuiButtonBase-root.MuiAccordionSummary-root': {
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowRightIcon sx={{ fontSize: '1.8rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
}));

const AccordionContainer = styled(Accordion)<{
  isDiscarded: boolean;
  isDraft: boolean;
}>(({ theme, isDiscarded, isDraft }) => ({
  backgroundColor:
    isDiscarded || isDraft
      ? 'rgba(255, 255, 255, 0.12)'
      : theme.palette.background.default,
  opacity: isDiscarded || isDraft ? 0.7 : 1,
  borderRadius: theme.spacing(0.5),
  marginBottom: theme.spacing(1.5),
  ':last-child': { marginBottom: '0px' },
}));

const formatDate = (date: string, format: string) =>
  dayjs(date).format(format);

const EventAccordion = ({
  event,
  event: { start, end, deferralIsOverdue, status },
  eventTypeChip,
  children,
  isDiscarded,
  isDeleteable,
  isEditable,
  isPendingDeletion,
  eventTypeSpecificInfo,
  onClickEdit,
  handleDelete,
  eventTypeSpecificActionBtn,
}: Props) => {
  const theme = useTheme();
  const mediumScreen = useMediaQuery(theme.breakpoints.down('md'));
  const largeScreen = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <AccordionContainer
      isDiscarded={isDiscarded}
      isDraft={status === EventStatus.DRAFT}
    >
      <AccordionSummary>
        <Grid container alignItems="center">
          <Grid item xs={2}>
            <EventStatusChip
              label={status}
              status={fromEventStatus(status)}
            />
          </Grid>
          <Grid item xs={2}>
            {mediumScreen && formatDate(start, 'D MMM, h:mma')}
            {largeScreen && formatDate(start, 'ddd Do MMM, h:mma')}
          </Grid>
          <Grid item xs={2}>
            {mediumScreen && formatDate(end, 'D MMM, h:mma')}
            {largeScreen && formatDate(end, 'ddd Do MMM, h:mma')}
          </Grid>
          <Grid item xs={2}>
            {eventTypeChip}
          </Grid>
          <Grid item xs={1}>
            {eventTypeSpecificInfo}
          </Grid>
          <Grid item xs={2}>
            <Grid container item justifyContent="center">
              {eventTypeSpecificActionBtn}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={2}
            lg={1}
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Grid item xs={4}>
              <UpdateEventBtn
                show={isEditable}
                onClick={onClickEdit}
              />
            </Grid>
            <Grid item xs={4}>
              <DeleteEventBtn
                show={!isDiscarded && isDeleteable}
                isPending={isPendingDeletion}
                handleDelete={handleDelete}
              />
            </Grid>
            {deferralIsOverdue && (
              <Grid item xs={4}>
                <EventDeferralWarning event={event} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </AccordionContainer>
  );
};

export default EventAccordion;
