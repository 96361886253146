import { handleApiError } from '../common/apiErrorHandler';
import {
  OfftakerViewModel,
  OfftakersViewModel,
} from './offtakerTypes';

const agent = require('axios');

interface AddOfftakerResponse {
  data: { offtaker: OfftakerViewModel };
}

interface FetchOfftakersResponse {
  data: { offtakers: OfftakersViewModel };
}

export async function fetchOfftakers() {
  return await handleApiError<{ data: OfftakersViewModel }>(
    () =>
      agent
        .get(`${process.env.REACT_APP_BACKEND_URL}/offtakers`)
        .then(({ data }: FetchOfftakersResponse) => ({
          data: data.offtakers,
        })),
    'Failed to fetch offtakers',
  );
}

export async function addOfftaker(
  name: string,
  abbreviation: string,
) {
  return await handleApiError<{ data: OfftakerViewModel }>(
    () =>
      agent
        .post(`${process.env.REACT_APP_BACKEND_URL}/offtakers`, {
          name,
          abbreviation,
        })
        .then(({ data }: AddOfftakerResponse) => ({
          data: data.offtaker,
        })),
    'Failed to add offtaker',
  );
}
