import { createSlice, createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { RootState } from '../../app/store';
import createXAxisValues, {
  ChartXAxisValues,
  FORECAST_INTERVAL_MINUTES,
  ROLLING_SCHEDULE_INTERVAL_MINUTES,
} from './chartXAxisValuesCreator';

export interface ChartState {
  viewWindow: {
    start: string;
    end: string;
  };
}

const initialState = {
  forecast: {
    viewWindow: {
      start: dayjs().toISOString(),
      end: dayjs().add(3, 'days').toISOString(),
    },
  },
  rollingSchedule: {
    viewWindow: {
      start: dayjs().toISOString(),
      end: dayjs().add(3, 'days').toISOString(),
    },
  },
};

export const chartSlice = createSlice({
  name: 'charts',
  initialState,
  reducers: {},
  extraReducers: () => {},
});

export const getXAxisValuesForForecastViewWindow = createSelector(
  (state: RootState) => state.charts.forecast.viewWindow,
  ({ start, end }: ChartState['viewWindow']) =>
    createXAxisValues(start, end, FORECAST_INTERVAL_MINUTES),
);

export const getXAxisValuesForRollingScheduleViewWindow =
  createSelector(
    (state: RootState) => state.charts.rollingSchedule.viewWindow,
    ({ start, end }: ChartState['viewWindow']): ChartXAxisValues =>
      createXAxisValues(
        start,
        end,
        ROLLING_SCHEDULE_INTERVAL_MINUTES,
      ),
  );

export default chartSlice.reducer;
