import { RequestFailedError } from './requestFailedError';

export async function handleApiError<ResponseType>(
  apiRequest: () => Promise<ResponseType>,
  defaultErrMessage: string,
): Promise<ResponseType> {
  try {
    return await apiRequest();
  } catch (error: any) {
    const message =
      error.response?.data?.message || defaultErrMessage;
    const errors = error.response?.data?.errors || [
      { message: message },
    ];
    throw new RequestFailedError(message, errors);
  }
}
