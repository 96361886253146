import {
  configureStore,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { getPersistConfig } from 'redux-deep-persist';
import edgeDeviceReducer from '../state/edgeDevices/edgeDeviceSlice';
import generationUnitsReducer from '../state/generationUnits/generationUnitsSlice';
import electrolyzerReducer from '../state/electrolyzers/electrolyzerSlice';
import forecastSourceReducer from '../state/forecastSources/forecastSourcesSlice';
import forecastsReducer from '../state/forecasts/forecastsSlice';
import chartsReducer from '../state/charts/chartsSlice';
import userReducer from '../state/user/userSlice';
import powerCostEstimateReducer from '../state/powerCostEstimate/powerCostEstimateSlice';
import storageUnitsReducer from '../state/storageUnits/storageUnitSlice';
import offtakerReducer from '../state/offtakers/offtakerSlice';
import eventReducer from '../state/events/eventSlice';
import planEventReducer from '../state/planEvent/planEventSlice';
import rollingSchedulesReducer from '../state/rollingSchedules/rollingSchedulesSlice';

import { baseApi } from './services/baseApi';

const rootReducer = combineReducers({
  generationUnits: generationUnitsReducer,
  edgeDevices: edgeDeviceReducer,
  electrolyzers: electrolyzerReducer,
  [baseApi.reducerPath]: baseApi.reducer,
  forecastSources: forecastSourceReducer,
  forecasts: forecastsReducer,
  charts: chartsReducer,
  user: userReducer,
  powerCostEstimate: powerCostEstimateReducer,
  storageUnits: storageUnitsReducer,
  offtakers: offtakerReducer,
  events: eventReducer,
  rollingSchedules: rollingSchedulesReducer,
  planEvent: planEventReducer,
});

const rootPersistConfig = getPersistConfig({
  key: 'storedState',
  storage: storage,
  whitelist: ['user.preferences', 'generationUnits.selectedEntity'],
  rootReducer,
});

const persistedReducer = persistReducer(
  rootPersistConfig,
  rootReducer,
);

export const initStore = () =>
  configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['persist/PERSIST'],
        },
      }).concat(baseApi.middleware),
  });

export const store = initStore();
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
