import React from 'react';
import MuiCard from '@mui/material/Card';
import MuiCardContent from '@mui/material/CardContent';
import { styled } from '@mui/material/styles';

interface CurrentActivityCardProps {
  children: React.ReactNode;
}

const Card = styled(MuiCard)(({ theme }) => ({
  height: '100%',
  backgroundColor: theme.palette.divider,
}));

const CardContent = styled(MuiCardContent)(() => ({
  height: '100%',
  '&:last-child': {
    paddingBottom: '16px',
  },
}));

const CurrentActivityCard = ({
  children,
}: CurrentActivityCardProps) => (
  <Card variant="outlined">
    <CardContent>{children}</CardContent>
  </Card>
);

export default CurrentActivityCard;
