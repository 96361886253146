import React from 'react';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';

interface FormValues {
  name: string;
  lat: string;
  long: string;
  forecastSources: string[];
  maxBalanceOfPlantKw: string;
  productionIssueEmailList: string;
  maxGridImportKw: string;
  renewableIncentivePencePerKg: string;
}

interface Props {
  disabled?: boolean;
  setFormValues: (formValues: FormValues) => void;
  formValues: FormValues;
  forecastSourceOptions: { [key: string]: string };
}

const productionIssueEmailListDescription = `Email address list for production issue notifications. Multiple email addresses
                                             can be entered by separating them with a comma (mail1@mail.com,mail2@mail.co.uk)`;

const getCurrentForecastSources = (
  forecastSourceOptions: { [key: string]: string },
  forecastSources: string[],
): string[] =>
  Object.entries(forecastSourceOptions)
    .filter(([_, forecastSourceId]) =>
      forecastSources.includes(forecastSourceId),
    )
    .map(([forecastSourceName, _]) => forecastSourceName);

const GenerationUnitFields = ({
  disabled,
  setFormValues,
  formValues,
  forecastSourceOptions,
}: Props) => {
  return (
    <Stack spacing={3}>
      <TextField
        fullWidth
        id="name"
        required
        onInput={(e) =>
          setFormValues({
            ...formValues,
            name: (e.target as HTMLTextAreaElement).value,
          })
        }
        label="Unit name"
        variant="filled"
        value={formValues.name}
        disabled={disabled}
      />
      <TextField
        fullWidth
        id="lat"
        required
        type="number"
        onInput={(e) =>
          setFormValues({
            ...formValues,
            lat: (e.target as HTMLTextAreaElement).value,
          })
        }
        label="Lat"
        variant="filled"
        value={formValues.lat}
        disabled={disabled}
      />
      <TextField
        fullWidth
        id="long"
        required
        type="number"
        onInput={(e) =>
          setFormValues({
            ...formValues,
            long: (e.target as HTMLTextAreaElement).value,
          })
        }
        label="Long"
        variant="filled"
        value={formValues.long}
        disabled={disabled}
      />
      <TextField
        fullWidth
        id="renewableIncentivePencePerKg"
        type="number"
        onInput={(e) =>
          setFormValues({
            ...formValues,
            renewableIncentivePencePerKg: (
              e.target as HTMLTextAreaElement
            ).value,
          })
        }
        label="Renewable Incentive (pence per kg)"
        variant="filled"
        value={formValues.renewableIncentivePencePerKg}
        disabled={disabled}
      />
      <TextField
        fullWidth
        id="maxBalanceOfPlantKw"
        required
        type="number"
        onInput={(e) =>
          setFormValues({
            ...formValues,
            maxBalanceOfPlantKw: (e.target as HTMLTextAreaElement)
              .value,
          })
        }
        label="Max power required for balance of plant (kW)"
        variant="filled"
        value={formValues.maxBalanceOfPlantKw}
        disabled={disabled}
      />
      <TextField
        fullWidth
        id="maxGridImportKw"
        type="number"
        onInput={(e) =>
          setFormValues({
            ...formValues,
            maxGridImportKw: (e.target as HTMLTextAreaElement).value,
          })
        }
        label="Max grid import power (kW)"
        variant="filled"
        value={formValues.maxGridImportKw}
        disabled={disabled}
      />
      <Tooltip title={productionIssueEmailListDescription}>
        <TextField
          fullWidth
          id="productionIssueEmailList"
          required
          onInput={(e) =>
            setFormValues({
              ...formValues,
              productionIssueEmailList: (
                e.target as HTMLTextAreaElement
              ).value,
            })
          }
          label="Email list for production issue notifications"
          variant="filled"
          value={formValues.productionIssueEmailList}
          disabled={disabled}
        />
      </Tooltip>
      <Autocomplete
        multiple
        id="forecast-sources"
        options={Object.keys(forecastSourceOptions)}
        onChange={(e, values: string[] | null) =>
          values &&
          setFormValues({
            ...formValues,
            forecastSources: values.map(
              (value) => forecastSourceOptions[value],
            ),
          })
        }
        value={getCurrentForecastSources(
          forecastSourceOptions,
          formValues.forecastSources,
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Forecast sources"
            value={formValues.forecastSources}
            required
            variant="filled"
            inputProps={{
              ...params.inputProps,
              required:
                formValues.forecastSources.length < 1 ||
                formValues.forecastSources[0] === '',
            }}
          />
        )}
      />
    </Stack>
  );
};

export default GenerationUnitFields;
