import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from './axiosBaseQuery';

// initialize an empty api service that we'll inject endpoints into later as needed
export const baseApi = createApi({
  reducerPath: 'services',
  refetchOnMountOrArgChange: true,
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL as string,
  }),
  endpoints: () => ({}),
});
