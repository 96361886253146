import React from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/common';
import EntityModal from '../common/EntityModal';
import {
  addElectrolyzerAsync,
  clearAddElectrolyzerStatus,
  getAddElectrolyzerState,
} from '../../state/electrolyzers/electrolyzerSlice';
import ElectrolyzerFields from './ElectrolyzerFields';

const initialFormValues = {
  manufacturer: '',
  serialNumber: '',
  efficiency: '',
  controlValue: '',
  maximumLoad: '',
  minRateChangeIntervalSeconds: 0,
  minZeroProductionIntervalSeconds: 0,
  minNonZeroProductionIntervalSeconds: 0,
};

interface Props {
  generationUnitId: string;
}

export default function AddElectrolyzer({ generationUnitId }: Props) {
  const dispatch = useAppDispatch();
  const requestStatus = useAppSelector(getAddElectrolyzerState);
  const [formValues, setFormValues] =
    React.useState(initialFormValues);

  const [error, setError] = React.useState('');

  return (
    <div>
      <EntityModal
        btnConfig={{
          label: 'Add electrolyzer',
          iconOnly: true,
        }}
        title="Add electrolyzer"
        titleId="connect-electrolyzer-title"
        requestState={requestStatus}
        error={error}
        clearStatusAction={() =>
          dispatch(clearAddElectrolyzerStatus())
        }
        clearFormValues={() => setFormValues(initialFormValues)}
        handleSubmit={() => {
          try {
            setError('');

            const formattedData = {
              ...formValues,
              generationUnitId,
              controlValue: JSON.parse(formValues.controlValue),
              efficiency: JSON.parse(formValues.efficiency),
              maximumLoad: JSON.parse(formValues.maximumLoad),
            };

            dispatch(addElectrolyzerAsync(formattedData));
          } catch (error) {
            setError(
              'Failed to transform electrolyzer form data for submission.',
            );
          }
        }}
      >
        <ElectrolyzerFields
          setFormValues={setFormValues}
          formValues={formValues}
        />
      </EntityModal>
    </div>
  );
}
