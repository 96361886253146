import React from 'react';
import { styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { useAppDispatch, useAppSelector } from '../../hooks/common';
import {
  getGenerationUnitsSortedByName,
  getSelectedGenerationUnitId,
  selectGenerationUnitById,
} from '../../state/generationUnits/generationUnitsSlice';

const Container = styled('div')(({ theme }) => ({
  flexGrow: 1,
  [theme.breakpoints.up('xs')]: {
    maxWidth: '250px',
    minWidth: '250px',
  },
  [theme.breakpoints.up('xl')]: {
    maxWidth: '320px',
    minWidth: '320px',
  },
  paddingRight: theme.spacing(2),
}));

const GenerationUnitSelectForm = () => {
  const generationUnitsAlphabetized = useAppSelector(
    getGenerationUnitsSortedByName,
  );
  const selectedGenerationUnitId = useAppSelector(
    getSelectedGenerationUnitId,
  );

  const dispatch = useAppDispatch();

  if (!generationUnitsAlphabetized.length) return <></>;

  return (
    <Container>
      <FormControl fullWidth size="small">
        <InputLabel id="generation-unit-select-label">
          Generation Unit
        </InputLabel>
        <Select
          labelId="generation-unit-select-label"
          label="Generation Unit"
          value={selectedGenerationUnitId}
          onChange={(e) =>
            dispatch(
              selectGenerationUnitById(
                (e.target as HTMLSelectElement).value,
              ),
            )
          }
        >
          {generationUnitsAlphabetized.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Container>
  );
};

export default GenerationUnitSelectForm;
