import React from 'react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import LiveDataIcon from '../common/LiveDataIcon';
import { FetchError, FetchSuccess } from '../common/StatusMessaging';
import { utcToLocalDate } from '../common/dateConverter';

interface Props {
  lastUpdated?: string;
  timezone?: string;
  errorMessage?: string;
  loading?: boolean;
  displayFetchError?: boolean;
}

const RollingScheduleLatestStatus = ({
  lastUpdated,
  timezone,
  errorMessage,
  loading = false,
  displayFetchError = false,
}: Props) => {
  if (loading) {
    return (
      <Typography
        sx={{
          fontSize: '11px',
          fontStyle: 'italic',
        }}
      >
        Loading...
      </Typography>
    );
  }

  if (displayFetchError || !lastUpdated) {
    return (
      <FetchError>Unable to get rolling schedule status</FetchError>
    );
  }

  const lastUpdatedLocalDate = utcToLocalDate(lastUpdated).format(
    'D MMM, h:mm:ss A',
  );
  if (errorMessage) {
    return (
      <Tooltip
        title={`${errorMessage} (${
          timezone
            ? `${lastUpdatedLocalDate} ${timezone}`
            : lastUpdatedLocalDate
        })`}
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={1}
          mt={1}
        >
          <Grid item>
            <LiveDataIcon status="error" />
          </Grid>
          <Grid item>
            <FetchError>
              Schedule updates failing since: {lastUpdatedLocalDate}
            </FetchError>
          </Grid>
        </Grid>
      </Tooltip>
    );
  }

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      spacing={1}
      mt={1}
    >
      <Grid item>
        <LiveDataIcon status="success" />
      </Grid>
      <Grid item>
        <FetchSuccess>
          Schedule last updated:{' '}
          {timezone ? (
            <Tooltip title={`${lastUpdatedLocalDate} ${timezone}`}>
              <span>{lastUpdatedLocalDate}</span>
            </Tooltip>
          ) : (
            <span>{lastUpdatedLocalDate}</span>
          )}
        </FetchSuccess>
      </Grid>
    </Grid>
  );
};

export default RollingScheduleLatestStatus;
