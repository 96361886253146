import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { fetchBestEventTimes } from './planEventAPI';
import { RequestStatuses, RequestState } from '../requestTypes';
import {
  EventPlanningInputs,
  BestEventTimeOptions,
} from './planEventTypes';
import { RootState } from '../../app/store';
import { selectGenerationUnitById } from '../generationUnits/generationUnitsSlice';
import { RejectValue } from '../common/errorTypes';
import { executeWithRejectValue } from '../common/executeWithRejectValue';

interface PlanEventState {
  activeModalStep: number;
  bestEventTimeOptions: BestEventTimeOptions | null;
  fetchBestEventTimesState: RequestState;
}

const initialState = {
  activeModalStep: 0,
  bestEventTimeOptions: null,
  fetchBestEventTimesState: {
    status: RequestStatuses.idle,
    errors: [],
  },
};

export const fetchBestEventTimesAsync = createAsyncThunk<
  BestEventTimeOptions,
  EventPlanningInputs,
  RejectValue
>(
  'planEvent/fetchBestEventTimesState',
  async (eventPlanningInputs, { rejectWithValue }) => {
    return await executeWithRejectValue(async () => {
      const response = await fetchBestEventTimes(eventPlanningInputs);
      return response.data;
    }, rejectWithValue);
  },
);

export const resetPlanEventState = createAction(
  'planEvent/resetPlanEventState',
);

export const planEventSlice = createSlice({
  name: 'planEvent',
  initialState,
  reducers: {
    resetPlanEventState: (state: PlanEventState) => {
      state.activeModalStep = 0;
      state.bestEventTimeOptions = null;
      state.fetchBestEventTimesState = {
        status: RequestStatuses.idle,
        errors: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchBestEventTimesAsync.pending,
        (state: PlanEventState) => {
          state.fetchBestEventTimesState = {
            status: RequestStatuses.pending,
            errors: [],
          };
        },
      )
      .addCase(
        fetchBestEventTimesAsync.fulfilled,
        (
          state: PlanEventState,
          action: PayloadAction<BestEventTimeOptions>,
        ) => {
          state.bestEventTimeOptions = action.payload;

          state.fetchBestEventTimesState = {
            status: RequestStatuses.fulfilled,
            errors: [],
          };

          state.activeModalStep = 1;
        },
      )
      .addCase(
        fetchBestEventTimesAsync.rejected,
        (state: PlanEventState, action) => {
          state.bestEventTimeOptions = null;

          state.fetchBestEventTimesState = {
            status: RequestStatuses.rejected,
            errors: action.payload?.errors,
          };
        },
      )
      .addCase(selectGenerationUnitById, (state: PlanEventState) => {
        state.bestEventTimeOptions = null;

        state.fetchBestEventTimesState = {
          status: RequestStatuses.idle,
          errors: [],
        };
      });
  },
});

export const getActiveModalStep = (state: RootState): number =>
  state.planEvent.activeModalStep;

export const getFetchBestEventTimesState = (
  state: RootState,
): RequestState => state.planEvent.fetchBestEventTimesState;

export const getBestEventTimeOptions = (
  state: RootState,
): BestEventTimeOptions | null =>
  state.planEvent.bestEventTimeOptions;

export default planEventSlice.reducer;
