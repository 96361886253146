import React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/material/styles';

import GenerationUnitSelectForm from './GenerationUnitSelectForm';
import Logo from '../../images/white_logo.png';
import DisplayValuesSelectForm from './DisplayValuesSelectForm';

const drawerWidth = 240;

interface Props {
  open: boolean;
  handleDrawerOpen: () => void;
  handleLogout: () => void;
}

const LogoImgContainer = styled('div')(() => ({
  flexGrow: 6,
}));

const LogoImg = styled('img')(() => ({
  height: '24px',
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean }>(({ theme, open }) => ({
  backgroundColor: theme.palette.background.default,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const MenuButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'open',
})<{ open: boolean }>(({ open }) => ({
  marginRight: 5,
  ...(open && { display: 'none' }),
}));

export default function TopNav({
  open,
  handleDrawerOpen,
  handleLogout,
}: Props) {
  return (
    <AppBar open={open} position="absolute">
      <Toolbar sx={{ paddingRight: 24 }}>
        <MenuButton
          edge="start"
          color="default"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          open={open}
        >
          <MenuIcon />
        </MenuButton>
        <LogoImgContainer>
          <LogoImg alt="Octopus Hydrogen Logo" src={Logo} />
        </LogoImgContainer>
        <DisplayValuesSelectForm />
        <GenerationUnitSelectForm />
        <Button color="inherit" onClick={handleLogout}>
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
}
