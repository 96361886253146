import * as React from 'react';
import TextField from '@mui/material/TextField';
import {
  DateTimePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Stack from '@mui/material/Stack';
import dateFormats from '../common/dateFormats';
import dayjs from 'dayjs';

interface Props {
  startValue: string;
  setStartValue: (value: string) => void;
  deadlineValue: string;
  setDeadlineValue: (value: string) => void;
}

const PowerCostFromDatePicker = ({
  startValue,
  setStartValue,
  deadlineValue,
  setDeadlineValue,
}: Props) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      localeText={{ start: 'Start', end: 'Deadline' }}
    >
      <Stack spacing={2}>
        <DateTimePicker
          renderInput={(props) => <TextField {...props} />}
          label="Start"
          value={startValue}
          onChange={(newValue) =>
            newValue &&
            dayjs(newValue).isValid() &&
            setStartValue(newValue)
          }
          inputFormat={dateFormats.DATE_TIME_INPUT_FORMAT}
        />
        <DateTimePicker
          renderInput={(props) => <TextField {...props} />}
          label="Deadline"
          value={deadlineValue}
          onChange={(newValue) =>
            newValue &&
            dayjs(newValue).isValid() &&
            setDeadlineValue(newValue)
          }
          inputFormat={dateFormats.DATE_TIME_INPUT_FORMAT}
        />
      </Stack>
    </LocalizationProvider>
  );
};

export default PowerCostFromDatePicker;
