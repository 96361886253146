import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export function utcToLocalDate(
  utcDateString: string | undefined = undefined,
): dayjs.Dayjs {
  const utcDate = utcDateString
    ? dayjs.utc(utcDateString)
    : dayjs.utc();
  return dayjs(utcDate).tz(getTimezone());
}

export function localDateToUtc(localDateString: string): dayjs.Dayjs {
  const localDate = dayjs.tz(localDateString, getTimezone());
  return localDate.utc();
}

export function getTimezone(): string {
  return dayjs.tz.guess();
}

export function isInTheFuture(utcDateString: string): boolean {
  return dayjs.utc().isBefore(dayjs.utc(utcDateString));
}

export function isInThePast(utcDateString: string): boolean {
  return dayjs.utc().isAfter(dayjs.utc(utcDateString));
}

export function utcToUk(date: any) {
  return new Date(date).toLocaleString('en-GB', {
    timeZone: 'Europe/London',
  });
}
