import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';

export const ModalContents = styled('div')(({ theme, style }) => ({
  position: 'absolute',
  width: (style && style.width) || 400,
  backgroundColor: theme.palette.background.paper,
  border: '2px solid #000',
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2, 4, 3),
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '90vh',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

export const ModalForm = styled('form')(({ theme }) => ({
  '& > *': {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  width: '100%',
  maxWidth: '800px',
}));

export const PromptForm = styled(Grid)(() => ({
  '& > *': {
    margin: 1,
  },
}));
