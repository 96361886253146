import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import LoggedInView from './LoggedInView';
import LoggedOutView from './LoggedOutView';

declare module '@mui/material/styles/createTypography' {
  interface TypographyOptions {
    modalTitle: TypographyStyleOptions;
  }
}

declare module '@mui/material/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    modalTitle: true;
  }
}

const darkTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1em',
          backgroundColor: 'rgba(97, 97, 97, 0.97)',
        },
      },
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#60F8AB',
    },
    secondary: {
      main: '#E850F6',
    },
    background: {
      default: '#303030',
    },
    info: {
      main: '#E850F6',
    },
  },
  typography: {
    fontSize: 12,
    h1: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '1.75rem',
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '0.95rem',
    },
    caption: {
      fontSize: '0.69rem',
      fontStyle: 'italic',
    },
    modalTitle: {
      fontSize: '1.2rem',
    },
  },
});

export default function App() {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      {isAuthenticated ? (
        <LoggedInView />
      ) : (
        <LoggedOutView handleLogin={loginWithRedirect} />
      )}
    </ThemeProvider>
  );
}
