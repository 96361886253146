import React from 'react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import { FetchError, FetchSuccess } from './StatusMessaging';
import LiveDataIcon from './LiveDataIcon';

interface Props {
  lastUpdated?: string;
  timezone?: string;
  displayFetchError?: boolean;
  loading?: boolean;
}

const TelemetryRequestStatus = ({
  lastUpdated,
  timezone,
  displayFetchError = false,
  loading = false,
}: Props) => {
  if (loading) {
    return (
      <Typography
        sx={{
          fontSize: '11px',
          fontStyle: 'italic',
        }}
      >
        Loading...
      </Typography>
    );
  }

  if (displayFetchError && lastUpdated) {
    return (
      <FetchError>
        Unable to get data. Last updated{' '}
        {timezone ? (
          <Tooltip title={`${lastUpdated} ${timezone}`}>
            <span>{lastUpdated}</span>
          </Tooltip>
        ) : (
          <span>{lastUpdated}</span>
        )}
      </FetchError>
    );
  }

  if (displayFetchError && !lastUpdated) {
    return <FetchError>Unable to get telemetry data</FetchError>;
  }

  return (
    <Grid container direction="row" alignItems="center" spacing={1}>
      <Grid item>
        <LiveDataIcon />
      </Grid>
      <Grid item>
        <FetchSuccess>
          Last updated:{' '}
          {timezone ? (
            <Tooltip title={`${lastUpdated} ${timezone}`}>
              <span>{lastUpdated}</span>
            </Tooltip>
          ) : (
            <span>{lastUpdated}</span>
          )}
        </FetchSuccess>
      </Grid>
    </Grid>
  );
};

export default TelemetryRequestStatus;
