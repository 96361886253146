import { baseApi } from './baseApi';

export const rollingScheduleApi = baseApi.injectEndpoints({
  endpoints(build) {
    return {
      getRollingScheduleStatus: build.query({
        query: (id: string) => ({
          url: `/generation-units/${id}/rolling-schedule/status`,
          method: 'get',
        }),
      }),
    };
  },
  overrideExisting: false,
});

export const { useGetRollingScheduleStatusQuery } =
  rollingScheduleApi;
