import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/common';
import EntityModal, { Operation } from '../common/EntityModal';
import {
  clearUpdateElectrolyzerStatus,
  getUpdateElectrolyzerState,
  updateElectrolyzerAsync,
} from '../../state/electrolyzers/electrolyzerSlice';
import ElectrolyzerFields from './ElectrolyzerFields';
import { ElectrolyzerViewModel } from '../../state/electrolyzers/electrolyzerTypes';
import { userHasEditPermission } from '../../state/user/userSlice';

const initialFormValues = {
  manufacturer: '',
  serialNumber: '',
  efficiency: '',
  controlValue: '',
  maximumLoad: '',
  minRateChangeIntervalSeconds: 0,
  minZeroProductionIntervalSeconds: 0,
  minNonZeroProductionIntervalSeconds: 0,
};

const getFormValues = (
  electrolyzer: ElectrolyzerViewModel | null,
) => {
  if (electrolyzer) {
    return {
      manufacturer: electrolyzer.manufacturer,
      serialNumber: electrolyzer.serialNumber,
      controlValue: JSON.stringify(
        electrolyzer.controlValue,
        null,
        4,
      ),
      efficiency: JSON.stringify(electrolyzer.efficiency, null, 4),
      maximumLoad: JSON.stringify(electrolyzer.maximumLoad, null, 4),
      minRateChangeIntervalSeconds:
        electrolyzer.minRateChangeIntervalSeconds,
      minZeroProductionIntervalSeconds:
        electrolyzer.minZeroProductionIntervalSeconds,
      minNonZeroProductionIntervalSeconds:
        electrolyzer.minNonZeroProductionIntervalSeconds,
    };
  }
  return initialFormValues;
};
interface Props {
  electrolyzer: ElectrolyzerViewModel;
}

export default function UpdateElectrolyzer({ electrolyzer }: Props) {
  const dispatch = useAppDispatch();
  const requestStatus = useAppSelector(getUpdateElectrolyzerState);

  const [formValues, setFormValues] =
    React.useState(initialFormValues);

  useEffect(() => {
    setFormValues(getFormValues(electrolyzer));
  }, [electrolyzer]);

  const canEdit = useAppSelector(userHasEditPermission);

  const editBtnLabel = () => {
    if (!canEdit) return 'You do not have permission to edit';
    return 'Edit technical specs';
  };

  const [error, setError] = React.useState('');
  return (
    <div>
      <EntityModal
        btnConfig={{
          label: editBtnLabel(),
          iconOnly: true,
        }}
        title="Edit Technical Specs"
        titleId="update-electrolyzer-title"
        requestState={requestStatus}
        error={error}
        clearStatusAction={() =>
          dispatch(clearUpdateElectrolyzerStatus())
        }
        clearFormValues={() => {}}
        disabled={!canEdit}
        handleSubmit={() => {
          try {
            setError('');

            const formattedData = {
              ...formValues,
              controlValue: JSON.parse(formValues.controlValue),
              efficiency: JSON.parse(formValues.efficiency),
              maximumLoad: JSON.parse(formValues.maximumLoad),
            };

            dispatch(
              updateElectrolyzerAsync({
                ...formattedData,
                id: electrolyzer.id,
              }),
            );
          } catch (error) {
            setError(
              'Failed to transform electrolyzer form data for submission.',
            );
          }
        }}
        operation={Operation.update}
      >
        <>
          {electrolyzer && (
            <ElectrolyzerFields
              setFormValues={setFormValues}
              formValues={formValues}
            />
          )}
        </>
      </EntityModal>
    </div>
  );
}
