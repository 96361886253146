import { baseApi } from './baseApi';

export const telemetryApi = baseApi.injectEndpoints({
  endpoints(build) {
    return {
      getEdgeDeviceTelemetry: build.query({
        query: (id: string) => ({
          url: `/edge-devices/${id}/telemetry`,
          method: 'get',
        }),
      }),
      getElectrolyzerTelemetry: build.query({
        query: (id: string) => ({
          url: `/electrolyzers/${id}/telemetry`,
          method: 'get',
        }),
      }),
      getStorageUnitTelemetry: build.query({
        query: (id: string) => ({
          url: `/storage-units/${id}/telemetry`,
          method: 'get',
        }),
      }),
    };
  },
  overrideExisting: false,
});

export const {
  useGetEdgeDeviceTelemetryQuery,
  useGetElectrolyzerTelemetryQuery,
  useGetStorageUnitTelemetryQuery,
} = telemetryApi;
