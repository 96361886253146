import React from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/common';
import EntityModal from '../common/EntityModal';
import StorageUnitFields from './StorageUnitFields';
import {
  addStorageUnitAsync,
  clearAddStorageUnitStatus,
  getAddStorageUnitsState,
} from '../../state/storageUnits/storageUnitSlice';
import { getElectrolyzerSelectOptsByGenerationUnitId } from '../../state/electrolyzers/electrolyzerSlice';
import { userHasEditPermission } from '../../state/user/userSlice';

const initialFormValues = {
  name: '',
  type: '',
  serialNumber: '',
  manufacturer: '',
  minCapacityKg: 0,
  totalCapacityKg: 0,
  electrolyzerIds: [''],
};

interface Props {
  generationUnitId: string;
}

export default function AddStorageUnit({ generationUnitId }: Props) {
  const dispatch = useAppDispatch();
  const requestStatus = useAppSelector(getAddStorageUnitsState);
  const electrolyzerSelectOpts = useAppSelector((state) =>
    getElectrolyzerSelectOptsByGenerationUnitId(
      state,
      generationUnitId,
    ),
  );
  const [formValues, setFormValues] =
    React.useState(initialFormValues);

  const canAdd = useAppSelector(userHasEditPermission);

  const addBtnLabel = () => {
    if (!canAdd)
      return 'You do not have permission to add a new storage unit';
    return 'Add storage unit';
  };

  const [error, setError] = React.useState('');
  return (
    <div>
      <EntityModal
        btnConfig={{
          label: addBtnLabel(),
          iconOnly: true,
        }}
        title="Add storage unit"
        titleId="connect-storage-unit-title"
        requestState={requestStatus}
        error={error}
        clearStatusAction={() =>
          dispatch(clearAddStorageUnitStatus())
        }
        clearFormValues={() => setFormValues(initialFormValues)}
        disabled={!canAdd}
        handleSubmit={() => {
          try {
            setError('');

            const formattedData = {
              ...formValues,
              generationUnitId,
            };

            dispatch(addStorageUnitAsync(formattedData));
          } catch (error) {
            setError(
              'Failed to transform storage unit form data for submission.',
            );
          }
        }}
      >
        <StorageUnitFields
          setFormValues={setFormValues}
          formValues={formValues}
          electrolyzerSelectOpts={electrolyzerSelectOpts}
        />
      </EntityModal>
    </div>
  );
}
