import React, { useState, useRef, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import Info from '@mui/icons-material/Info';
import { styled } from '@mui/material/styles';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../hooks/common';
import { GenerationUnitViewModel } from '../../../state/generationUnits/generationUnitTypes';
import {
  fetchLatestForecastsByGenUnitIdAsync,
  getForecastCreationTime,
} from '../../../state/forecasts/forecastsSlice';
import SolarForecastChart from './SolarForecastChart';
import GridPriceForecastChart from './GridPriceForecastChart';
import { ForecastSourceType } from '../../../state/forecastSources/forecastSourceTypes';
import LandfillGasPowerForecastChart from './LandfillGasPowerForecastChart';
import ForecastTimestamp from './ForecastTimestamp';

interface Props {
  forecastSources: GenerationUnitViewModel['forecastSources'];
  generationUnitId: string;
}

const InfoPopperContent = styled('div')(({ theme }) => ({
  background: theme.palette.background.paper,
  padding: '8px 16px',
  maxWidth: '500px',
  borderWidth: '1px',
  borderColor: theme.palette.text.primary,
  borderStyle: 'solid',
}));

const ChartContainer = styled('div')(({ theme }) => ({
  height: '250px',
  background: theme.palette.background.default,
}));

const renderForecastTimestamp = (
  forecastSourceTimestamp: string | null,
) => {
  if (!forecastSourceTimestamp) return <></>;
  return <ForecastTimestamp time={forecastSourceTimestamp} />;
};

const getSources = (forecastSources: Props['forecastSources']) => ({
  solarSource: forecastSources.find(
    ({ type }) => type === ForecastSourceType.SOLAR,
  ),
  gridSource: forecastSources.find(
    ({ type }) => type === ForecastSourceType.GRID_PRICE,
  ),
  landfillGasPowerSource: forecastSources.find(
    ({ type }) => type === ForecastSourceType.LANDFILL_GAS_POWER,
  ),
});

const ForecastCharts = ({
  forecastSources,
  generationUnitId,
}: Props) => {
  const { solarSource, gridSource, landfillGasPowerSource } =
    getSources(forecastSources);

  const sourceTimestamps = useAppSelector((state) => ({
    [ForecastSourceType.SOLAR]: solarSource
      ? getForecastCreationTime(
          state,
          generationUnitId,
          solarSource.platformId,
          ForecastSourceType.SOLAR,
        )
      : null,
    [ForecastSourceType.GRID_PRICE]: gridSource
      ? getForecastCreationTime(
          state,
          generationUnitId,
          gridSource.platformId,
          ForecastSourceType.GRID_PRICE,
        )
      : null,
    [ForecastSourceType.LANDFILL_GAS_POWER]: landfillGasPowerSource
      ? getForecastCreationTime(
          state,
          generationUnitId,
          landfillGasPowerSource.platformId,
          ForecastSourceType.LANDFILL_GAS_POWER,
        )
      : null,
  }));

  const [solarInfoOpen, setSolarInfoOpen] = useState(false);
  const solarInfoAnchorRef = useRef(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      try {
        dispatch(
          fetchLatestForecastsByGenUnitIdAsync(generationUnitId),
        );
      } catch (e) {
        console.error(e);
      }
    })();
  }, [dispatch, generationUnitId]);

  return (
    <Card variant="outlined" sx={{ backgroundColor: 'divider' }}>
      <CardContent>
        <Typography component="h3" variant="subtitle1" gutterBottom>
          Forecasts
        </Typography>
        <Grid container spacing={2}>
          {solarSource && (
            <Grid item xs={12}>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Typography component="h4" variant="subtitle2">
                    Solar
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={() => setSolarInfoOpen(!solarInfoOpen)}
                    ref={solarInfoAnchorRef}
                    size="small"
                  >
                    <Info />
                  </IconButton>
                  <Popper
                    open={solarInfoOpen}
                    anchorEl={solarInfoAnchorRef.current}
                    placement="right-start"
                  >
                    <InfoPopperContent>
                      <Typography component="p" variant="body1">
                        The solar forecast graph displays
                        probabilistic forecast data. The shaded area
                        represents the upper (90%) and lower (10%)
                        probability bounds of the forecast. There is a
                        10% chance that the actual output will fall
                        above the upper value, and a 10% chance that
                        it will fall below the lower value. So,
                        overall, there is an 80% chance that the
                        output will fall between these values. The
                        solid line represents the median forecast,
                        which is the most likely scenario. The wider
                        the shaded area is, the less certainty there
                        is in the forecast, and the less likely the
                        median forecast is to be accurate.
                      </Typography>
                    </InfoPopperContent>
                  </Popper>
                </Grid>
              </Grid>
              <ChartContainer>
                <SolarForecastChart
                  generationUnitId={generationUnitId}
                  forecastSourcePlatformId={solarSource.platformId}
                />
                {renderForecastTimestamp(
                  sourceTimestamps[ForecastSourceType.SOLAR],
                )}
              </ChartContainer>
            </Grid>
          )}
          {gridSource && (
            <Grid item xs={12}>
              <Typography
                component="h4"
                variant="subtitle2"
                gutterBottom
              >
                Grid prices
              </Typography>
              <ChartContainer>
                <GridPriceForecastChart
                  generationUnitId={generationUnitId}
                  forecastSourcePlatformId={gridSource.platformId}
                />
                {renderForecastTimestamp(
                  sourceTimestamps[ForecastSourceType.GRID_PRICE],
                )}
              </ChartContainer>
            </Grid>
          )}
          {landfillGasPowerSource && (
            <Grid item xs={12}>
              <Typography
                component="h4"
                variant="subtitle2"
                gutterBottom
              >
                Landfill gas power
              </Typography>
              <ChartContainer>
                <LandfillGasPowerForecastChart
                  generationUnitId={generationUnitId}
                  forecastSourcePlatformId={
                    landfillGasPowerSource.platformId
                  }
                />
                {renderForecastTimestamp(
                  sourceTimestamps[
                    ForecastSourceType.LANDFILL_GAS_POWER
                  ],
                )}
              </ChartContainer>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ForecastCharts;
