import { styled } from '@mui/material/styles';
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  ListItemText,
} from '@mui/material';
import { eventStatuses } from '../../state/events/eventTypes';

interface Props {
  updateFilter: (event: string[]) => void;
  filterState: { id: string; value: string[] };
}

const toTitleCase = (word: string) => {
  const [first, ...rest] = word.replace('_', ' ').split('');
  return `${first.toUpperCase()}${rest.join('').toLowerCase()}`;
};

const StatusFilterFormControl = styled(FormControl)(({ theme }) => ({
  margin: theme.spacing(1),
  width: '240px',
  legend: { width: '68px' },
}));

const SELECT_ALL = 'select_all';
const SelectAllMenuItem = styled(MenuItem)(() => ({
  '& .MuiTypography-root': { fontWeight: 'bold' },
}));

const StatusFilter = ({ updateFilter, filterState }: Props) => {
  const numberSelected = filterState.value.length;
  const allSelected = numberSelected === eventStatuses.length;

  const handleChange = (event: SelectChangeEvent<string[]>) => {
    const { value } = event.target;
    if (!Array.isArray(value)) return; // Type guard. Value will always be an array but Mui asks that we use SelectChangeEvent which thinks that it could be a string.

    if (value[value.length - 1] === SELECT_ALL) {
      updateFilter(allSelected ? [] : eventStatuses);
      return;
    }
    updateFilter(value);
  };

  return (
    <StatusFilterFormControl>
      <InputLabel id="filter-status-select-label">
        Event status
      </InputLabel>
      <Select
        labelId="filter-status-select-label"
        id="filter-status-select"
        multiple
        displayEmpty
        value={filterState.value}
        onChange={handleChange}
        input={<OutlinedInput label="Statuses" />}
        renderValue={() => {
          if (!numberSelected) return '';
          return `Status filter: showing ${
            allSelected
              ? 'all'
              : `${numberSelected}/${eventStatuses.length}`
          } types`;
        }}
      >
        <SelectAllMenuItem
          value={SELECT_ALL}
          className={`${allSelected ? 'Mui-selected' : ''}`}
        >
          <Checkbox
            checked={allSelected}
            indeterminate={Boolean(numberSelected) && !allSelected}
          />
          <ListItemText primary="Select all" />
        </SelectAllMenuItem>
        {eventStatuses.map((status) => (
          <MenuItem key={status} value={status}>
            <Checkbox checked={filterState.value.includes(status)} />
            <ListItemText primary={toTitleCase(status)} />
          </MenuItem>
        ))}
      </Select>
    </StatusFilterFormControl>
  );
};

export default StatusFilter;
