import React from 'react';

import {
  addDeviceAsync,
  getAddDeviceState,
  clearAddDeviceStatus,
  getEdgeDeviceByGenUnit,
} from '../../state/edgeDevices/edgeDeviceSlice';

import { useAppDispatch, useAppSelector } from '../../hooks/common';
import EntityModal from '../common/EntityModal';
import EdgeDeviceFields from './EdgeDeviceFields';

const initialFormValues = {
  manufacturer: '',
  serialNumber: '',
  iiotThingName: '',
};

interface Props {
  generationUnitId: string;
}

export default function AddEdgeDevice({ generationUnitId }: Props) {
  const dispatch = useAppDispatch();
  const requestStatus = useAppSelector(getAddDeviceState);
  const [formValues, setFormValues] =
    React.useState(initialFormValues);

  const edgeDevice = useAppSelector((state) =>
    getEdgeDeviceByGenUnit(state, generationUnitId),
  );

  if (!edgeDevice) {
    return (
      <div>
        <EntityModal
          btnConfig={{ size: 'small', label: 'Add edge device' }}
          title="Add a new edge device"
          titleId="add-edge-device-title"
          requestState={requestStatus}
          clearStatusAction={() => dispatch(clearAddDeviceStatus())}
          clearFormValues={() => setFormValues(initialFormValues)}
          handleSubmit={() =>
            dispatch(
              addDeviceAsync({ ...formValues, generationUnitId }),
            )
          }
        >
          <EdgeDeviceFields
            setFormValues={setFormValues}
            formValues={formValues}
          />
        </EntityModal>
      </div>
    );
  }

  return null;
}
