import agent from 'axios';
import { handleApiError } from '../common/apiErrorHandler';
import {
  DowntimeEventViewModel,
  EventStatus,
  EventViewModel,
  NewEvents,
  OfftakeEventViewModel,
  UpdatedDowntimeEvent,
  UpdatedOfftakeEvent,
} from './eventTypes';

interface AddEventsResponse {
  data: {
    events: {
      [id: string]: EventViewModel;
    };
  };
}

interface FetchEventsResponse {
  data: {
    events: {
      [id: string]: EventViewModel;
    };
  };
}

interface DeleteEventResponse {
  data: {
    event: EventViewModel;
  };
}

interface UpdateEventResponse {
  data: {
    event: OfftakeEventViewModel | DowntimeEventViewModel;
  };
}

interface AddEventsActionResponse extends AddEventsResponse {}

export async function fetchEventsByGenerationUnitId(
  generationUnitId: string,
) {
  return handleApiError<{
    data: FetchEventsResponse['data']['events'];
  }>(
    async () =>
      agent
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/generation-units/${generationUnitId}/events`,
        )
        .then(({ data }: FetchEventsResponse) => ({
          data: data && data.events,
        })),
    'Failed to fetch offtake events',
  );
}

export async function startEvent(
  eventId: string,
  expectedEnd: string,
  h2QuantityKg?: number,
  renewableIncentiveEligibleMassKg?: number,
) {
  return handleApiError<{ data: EventViewModel }>(
    async () =>
      agent
        .patch(
          `${process.env.REACT_APP_BACKEND_URL}/events/${eventId}`,
          {
            expectedEnd,
            h2QuantityKg,
            renewableIncentiveEligibleMassKg,
            status: EventStatus.STARTED,
          },
        )
        .then(({ data }: UpdateEventResponse) => ({
          data: data.event,
        })),
    'Failed to start event',
  );
}

export async function endEvent(eventId: string) {
  return handleApiError<{ data: EventViewModel }>(
    async () =>
      agent
        .patch(
          `${process.env.REACT_APP_BACKEND_URL}/events/${eventId}`,
          { status: EventStatus.ENDED },
        )
        .then(({ data }: UpdateEventResponse) => ({
          data: data.event,
        })),
    'Failed to end event',
  );
}

export async function updateEvent(
  updatedEvent: UpdatedOfftakeEvent | UpdatedDowntimeEvent,
) {
  const { id, ...payload } = updatedEvent;
  return handleApiError<{ data: EventViewModel }>(
    async () =>
      agent
        .patch(
          `${process.env.REACT_APP_BACKEND_URL}/events/${id}`,
          payload,
        )
        .then(({ data }: UpdateEventResponse) => ({
          data: data.event,
        })),
    'Failed to update event',
  );
}

export async function deleteEvent(eventId: string) {
  return handleApiError<{
    data: EventViewModel;
  }>(
    async () =>
      agent
        .delete(
          `${process.env.REACT_APP_BACKEND_URL}/events/${eventId}`,
        )
        .then(({ data }: DeleteEventResponse) => ({
          data: data.event,
        })),
    'Failed to delete event',
  );
}

export async function addEvents(
  generationUnitId: string,
  newEvents: NewEvents,
) {
  return handleApiError<{
    data: {
      [id: string]: EventViewModel;
    };
  }>(
    () =>
      agent
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/generation-units/${generationUnitId}/events`,
          newEvents,
        )
        .then(({ data }: AddEventsResponse) => ({
          data: data.events,
        })),
    'Failed to add events',
  );
}

export async function addEventsAction(
  generationUnitId: string,
  action: string,
) {
  return handleApiError<{
    data: {
      [id: string]: EventViewModel;
    };
  }>(
    () =>
      agent
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/generation-units/${generationUnitId}/events/action`,
          {
            action,
          },
        )
        .then(({ data }: AddEventsActionResponse) => ({
          data: data.events,
        })),
    'Failed to add events action',
  );
}
