import * as R from 'ramda';
import { EventStatus } from '../../../state/events/eventTypes';

export const isDiscarded = (status: EventStatus): boolean =>
  R.includes(status, [
    EventStatus.DELETED,
    EventStatus.NEVER_STARTED,
    EventStatus.SUSPENDED,
  ]);

export const isDeleteable = (status: EventStatus): boolean =>
  R.includes(status, [EventStatus.CONFIRMED, EventStatus.DRAFT]);

export const isEditable = (status: EventStatus): boolean =>
  R.includes(status, [EventStatus.CONFIRMED, EventStatus.DRAFT]);
