import Modal from '@mui/material/Modal';
import { ModalContents } from '../../common/modalStyles';
import ModalTitle from '../../common/ModalTitle';
import PlanEventTimesModalContent from './PlanEventTimesModalContent';

interface Props {
  open: boolean;
  handleClose: () => void;
  generationUnitId: string;
}

const PlanEventTimesModal = ({
  open,
  handleClose,
  generationUnitId,
}: Props) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="plan-event-times-modal-title"
    >
      <ModalContents style={{ width: '50%', height: '90%' }}>
        <ModalTitle
          text="Plan Event"
          id="plan-event-times-modal-title"
        />
        <PlanEventTimesModalContent
          generationUnitId={generationUnitId}
          handleClose={handleClose}
        />
      </ModalContents>
    </Modal>
  );
};

export default PlanEventTimesModal;
