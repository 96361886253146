import React, { useEffect, useState } from 'react';
import RollingScheduleLatestStatus from './RollingScheduleLatestStatus';
import { getTimezone } from '../common/dateConverter';
import isUnauthorizedError from '../../state/common/unauthorizedError';
import { RollingScheduleStatus as RollingScheduleStatusData } from '../../state/rollingSchedules/rollingScheduleTypes';
import { useGetRollingScheduleStatusQuery } from '../../app/services/rollingSchedule';

interface Props {
  generationUnitId: string;
}
const RollingScheduleStatus = ({ generationUnitId }: Props) => {
  const [pollingInterval, setPollingInterval] = useState(6000);
  const rollingScheduleStatusRequest =
    useGetRollingScheduleStatusQuery(generationUnitId, {
      pollingInterval,
    });

  const rollingScheduleStatus: RollingScheduleStatusData =
    rollingScheduleStatusRequest.data?.rollingScheduleStatus;

  const displayFetchError = Boolean(
    rollingScheduleStatusRequest.isError ||
      (rollingScheduleStatusRequest.isFetching &&
        rollingScheduleStatusRequest.error),
  );

  const isUnauthorized =
    displayFetchError &&
    isUnauthorizedError(rollingScheduleStatusRequest.error);

  useEffect(() => {
    if (isUnauthorized) {
      setPollingInterval(0);
    } else {
      setPollingInterval(60000);
    }
  }, [setPollingInterval, isUnauthorized]);

  return (
    <RollingScheduleLatestStatus
      lastUpdated={rollingScheduleStatus?.time}
      errorMessage={rollingScheduleStatus?.errorMessage}
      timezone={getTimezone()}
      loading={rollingScheduleStatusRequest.isLoading}
      displayFetchError={displayFetchError}
    />
  );
};

export default RollingScheduleStatus;
