import {
  createAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  addStorageUnit,
  fetchStorageUnits,
  updateStorageUnit,
} from './storageUnitsAPI';
import userFriendlyLoadingTime from '../userFriendlyLoadingTime';
import { RequestStatuses, RequestState } from '../requestTypes';
import {
  NewStorageUnit,
  StorageUnitsViewModel,
  StorageUnitViewModel,
  UpdatedStorageUnit,
} from './storageUnitTypes';
import entitySliceBuilder from '../common/entitySliceCreator';
import { getElectrolyzersByGenerationUnitId } from '../electrolyzers/electrolyzerSlice';
import { EntityState } from '../common/entitySliceCreator';
import * as R from 'ramda';
import { RejectValue } from '../common/errorTypes';
import { executeWithRejectValue } from '../common/executeWithRejectValue';
import { H2MeasurementUnit } from '../../common/types';
import { convertToNm3 } from '../common/valueUnitConvertor';

export interface StorageUnitState extends EntityState {
  addEntityState: RequestState;
  updateEntityState: RequestState;
  entities: { [key: string]: StorageUnitViewModel } | {};
}

const initialState: StorageUnitState = {
  entities: {},
  addEntityState: {
    status: RequestStatuses.idle,
    errors: [],
  },
  updateEntityState: {
    status: RequestStatuses.idle,
    errors: [],
  },
};

const timeout = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const fetchStorageUnitsAsync = createAsyncThunk<
  StorageUnitsViewModel,
  void,
  RejectValue
>(
  'storageUnits/fetchStorageUnits',
  async (_0, { rejectWithValue }) => {
    return await executeWithRejectValue(async () => {
      const response = await fetchStorageUnits();
      return response.data;
    }, rejectWithValue);
  },
);

export const addStorageUnitAsync = createAsyncThunk<
  StorageUnitViewModel,
  NewStorageUnit,
  RejectValue
>(
  'storageUnits/addStorageUnit',
  async (newStorageUnit: NewStorageUnit, { rejectWithValue }) => {
    return await executeWithRejectValue(async () => {
      const response = await addStorageUnit(newStorageUnit);
      await timeout(userFriendlyLoadingTime);
      return response.data;
    }, rejectWithValue);
  },
);

export const clearAddStorageUnitStatus = createAction(
  'storageUnits/clearAddStorageUnitStatus',
);

export const updateStorageUnitAsync = createAsyncThunk<
  StorageUnitViewModel,
  UpdatedStorageUnit,
  RejectValue
>(
  'storageUnits/updateStorageUnit',
  async (
    updatedStorageUnit: UpdatedStorageUnit,
    { rejectWithValue },
  ) => {
    return await executeWithRejectValue(async () => {
      const response = await updateStorageUnit(updatedStorageUnit);
      await timeout(userFriendlyLoadingTime);
      return response.data;
    }, rejectWithValue);
  },
);

export const clearUpdateStorageUnitStatus = createAction(
  'storageUnits/clearUpdateStorageUnitStatus',
);

const { entityReducers, extraEntityReducers } = entitySliceBuilder<
  NewStorageUnit,
  StorageUnitViewModel
>(
  addStorageUnitAsync,
  fetchStorageUnitsAsync,
  clearAddStorageUnitStatus.type,
);

export const storageUnitsSlice = createSlice({
  name: 'storageUnits',
  initialState,
  reducers: {
    ...entityReducers,
    clearUpdateStorageUnitStatus: (state: EntityState) => {
      state.updateEntityState = {
        status: RequestStatuses.idle,
        errors: [],
      };
    },
  },
  extraReducers: (builder) => {
    extraEntityReducers(
      builder
        .addCase(
          updateStorageUnitAsync.pending,
          (state: StorageUnitState) => {
            state.updateEntityState = {
              status: RequestStatuses.pending,
              errors: [],
            };
          },
        )
        .addCase(
          updateStorageUnitAsync.rejected,
          (state: StorageUnitState, action) => {
            state.updateEntityState = {
              status: RequestStatuses.rejected,
              errors: action.payload?.errors,
            };
          },
        )
        .addCase(
          updateStorageUnitAsync.fulfilled,
          (state, action: PayloadAction<StorageUnitViewModel>) => {
            if (action.payload) {
              state.entities = {
                ...state.entities,
                [action.payload.id]: action.payload,
              };
            }

            state.updateEntityState = {
              status: RequestStatuses.fulfilled,
              errors: [],
            };
          },
        ),
    );
  },
});

export const getAddStorageUnitsState = (
  state: RootState,
): RequestState => state.storageUnits.addEntityState;

export const getUpdateStorageUnitState = (
  state: RootState,
): RequestState => state.storageUnits.updateEntityState;

export const getStorageUnitsByGenerationUnitId = (
  state: RootState,
  generationUnitId: string,
  valuesUnit: string,
): StorageUnitViewModel[] | null => {
  const allStorageUnits = Object.values(
    state.storageUnits.entities,
  ) as StorageUnitViewModel[] | [];

  const electrolyzers = getElectrolyzersByGenerationUnitId(
    state,
    generationUnitId,
  );
  if (!electrolyzers) {
    return null;
  }

  const electrolyzerIds = electrolyzers.map((e) => e.id);
  const storageUnits = allStorageUnits.filter((storageUnit) =>
    storageUnit.electrolyzerIds.some((e) =>
      electrolyzerIds.includes(e),
    ),
  );
  return valuesUnit === H2MeasurementUnit.NM3
    ? convertStorageUnitsToNm3(storageUnits)
    : storageUnits;
};

export const convertStorageUnitsToNm3 = (
  storageUnits: StorageUnitViewModel[],
): StorageUnitViewModel[] =>
  storageUnits.map((storageUnit) => ({
    ...storageUnit,
    totalCapacityKg: convertToNm3(storageUnit.totalCapacityKg),
  }));

export const getStorageUnitById = (
  state: RootState,
  id: string,
): StorageUnitViewModel | null =>
  R.path([id], state.storageUnits.entities) || null;

export const getStorageUnitTotalStaticCapacity = (
  state: RootState,
  generationUnitId: string,
): number | null => {
  const storageUnits = Object.values<StorageUnitViewModel>(
    state.storageUnits.entities,
  ).filter(
    (storage) =>
      storage.generationUnitId === generationUnitId &&
      storage.type === 'static',
  );

  return storageUnits.length
    ? R.sum(
        storageUnits.map(({ totalCapacityKg }) => totalCapacityKg),
      )
    : null;
};

export const hasStaticStorageUnit = (
  state: RootState,
  generationUnitId: string,
) => {
  const allStorageUnits = Object.values<StorageUnitViewModel>(
    state.storageUnits.entities,
  );

  if (allStorageUnits.length) {
    return allStorageUnits.some(
      (storageUnit: StorageUnitViewModel) =>
        storageUnit.generationUnitId === generationUnitId &&
        storageUnit.type === 'static',
    );
  }

  return false;
};

export default storageUnitsSlice.reducer;
