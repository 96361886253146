import { ElectrolyzerViewModel } from './electrolyzerTypes';

const createElectrolyzerDisplayName = (
  electrolyzer?: ElectrolyzerViewModel | null,
): string => {
  return electrolyzer
    ? `${electrolyzer.manufacturer} ${electrolyzer.serialNumber}`
    : 'Not found';
};

export default createElectrolyzerDisplayName;
