import Typography from '@mui/material/Typography';
import { capitalize } from '@mui/material';

interface Props {
  text: string;
  id: string;
}

const ModalTitle = ({ text, id }: Props) => (
  <Typography component="h2" variant="modalTitle" id={id}>
    {capitalize(text)}
  </Typography>
);

export default ModalTitle;
