import dayjs from 'dayjs';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import { BestEventTimeOptions } from '../../../../state/planEvent/planEventTypes';

const StyleListItemBtn = styled(ListItemButton)(() => ({
  border: '1px solid white',
  borderRadius: '4px',
}));

interface Props {
  eventOptions: BestEventTimeOptions;
  checkedOption: number;
  handleCheck: (value: number) => () => void;
}

const SelectEventList = ({
  eventOptions,
  handleCheck,
  checkedOption,
}: Props) => {
  return (
    <List>
      {eventOptions.map((eventOption, idx) => {
        const [event] = eventOption.eventsToCreate;
        const formattedTimes = `${dayjs(event.expectedStart).format(
          'h:mm a on ddd D MMMM',
        )} - ${dayjs(event.expectedEnd).format(
          'h:mm a on ddd D MMMM',
        )}`;

        return (
          <ListItem key={idx}>
            <StyleListItemBtn onClick={handleCheck(idx)}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checkedOption === idx}
                />
              </ListItemIcon>
              <ListItemText primary={formattedTimes} />
            </StyleListItemBtn>
          </ListItem>
        );
      })}
    </List>
  );
};

export default SelectEventList;
