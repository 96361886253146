import { handleApiError } from '../common/apiErrorHandler';

import {
  NewStorageUnit,
  StorageUnitViewModel,
  StorageUnitsViewModel,
  UpdatedStorageUnit,
} from './storageUnitTypes';

const agent = require('axios');

interface FetchStorageUnitsResponse {
  data: { storageUnits: StorageUnitsViewModel };
}

export async function fetchStorageUnits() {
  return await handleApiError<{ data: StorageUnitsViewModel }>(
    () =>
      agent
        .get(`${process.env.REACT_APP_BACKEND_URL}/storage-units`)
        .then(({ data }: FetchStorageUnitsResponse) => ({
          data: data.storageUnits,
        })),
    'Failed to fetch storage units',
  );
}

interface AddStorageUnitResponse {
  data: { storageUnit: StorageUnitViewModel };
}

export async function addStorageUnit(newStorageUnit: NewStorageUnit) {
  return await handleApiError<{ data: StorageUnitViewModel }>(
    () =>
      agent
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/storage-units`,
          newStorageUnit,
        )
        .then(({ data }: AddStorageUnitResponse) => ({
          data: data.storageUnit,
        })),
    'Failed to add storage unit',
  );
}

interface UpdateStorageUnitResponse {
  data: { storageUnit: UpdatedStorageUnit };
}

export async function updateStorageUnit(
  updatedStorageUnit: UpdatedStorageUnit,
) {
  const { id, ...storageUnitData } = updatedStorageUnit;
  return await handleApiError<{ data: StorageUnitViewModel }>(
    () =>
      agent
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/storage-units/${id}`,
          storageUnitData,
        )
        .then(({ data }: UpdateStorageUnitResponse) => ({
          data: data.storageUnit,
        })),
    'Failed to update storage unit',
  );
}
