import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { DowntimeEventViewModel } from '../../../state/events/eventTypes';
import { RequestState } from '../../../state/requestTypes';
import DatePicker from '../../common/DatePicker';
import { ModalForm } from '../../common/modalStyles';
import getExpectedEndFormValue from '../expectedEndCalculator';
import UpdateEventFormActions from '../UpdateEventFormActions';

interface Props {
  event: DowntimeEventViewModel;
  handleSubmit: (formValues: {
    eventId: string;
    expectedEnd: string;
  }) => Promise<void>;
  handleClose: () => void;
  onOpenDatePicker: () => void;
  onCloseDatePicker: () => void;
  disabled: boolean;
  requestState: RequestState;
}

const StartDowntimeEventForm = ({
  event,
  handleSubmit,
  handleClose,
  onOpenDatePicker,
  onCloseDatePicker,
  requestState,
  disabled,
}: Props) => {
  const [expectedEndFormValue, setExpectedEnd] =
    useState<Date | null>(
      getExpectedEndFormValue(event.start, event.end),
    );

  return (
    <ModalForm
      onSubmit={async (e) => {
        e.preventDefault();

        await handleSubmit({
          eventId: event.id,
          expectedEnd: dayjs(expectedEndFormValue)
            .second(0)
            .toISOString(),
        });
      }}
    >
      <Stack spacing={3}>
        <DatePicker
          disabled={disabled}
          label="Expected end"
          timeIntervals={15}
          value={expectedEndFormValue}
          handleChange={setExpectedEnd}
          onOpen={onOpenDatePicker}
          onClose={onCloseDatePicker}
        />
      </Stack>
      <UpdateEventFormActions
        requestState={requestState}
        handleClose={handleClose}
        successMessage="Started successfully"
      />
    </ModalForm>
  );
};

export default StartDowntimeEventForm;
