import {
  CartesianGrid,
  LineChart,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  ReferenceArea,
  Tooltip,
  TooltipProps,
} from 'recharts';
import { useTheme } from '@mui/material/styles';
import * as R from 'ramda';
import dayjs from 'dayjs';
import ChartTooltip from '../common/ChartTooltip';
import { ChartXAxisValues } from '../../state/charts/chartXAxisValuesCreator';
import { RollingScheduleChartProductionRateDataRow } from '../../state/rollingSchedules/rollingScheduleTypes';
import {
  EventType,
  RollingScheduleChartEventData,
} from '../../state/events/eventTypes';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import { H2MeasurementUnit } from '../../common/types';

const hoverFilter =
  (currentTime: number) =>
  ({ start, end }: { start: number; end: number }) =>
    currentTime >= start && currentTime <= end;

const renderTooltip =
  ({
    eventData,
    productionRateDataKey,
    storageLevelDataKey,
    valuesUnit,
  }: {
    eventData: RollingScheduleChartEventData;
    productionRateDataKey?: string;
    storageLevelDataKey?: string;
    valuesUnit: H2MeasurementUnit;
  }) =>
  ({ active, payload, label }: TooltipProps<number, string>) => {
    if (active && payload) {
      const productionRateData = payload.find(
        ({ dataKey }) => dataKey === productionRateDataKey,
      );

      const storageLevelData = payload.find(
        ({ dataKey }) => dataKey === storageLevelDataKey,
      );

      const getEventsBeingHoveredOver = hoverFilter(label);
      // Tooltip doesn't know when <ReferenceArea /> is hovered over, so we need to calculate these ourselves.
      const offtakeEvents = eventData.offtake.filter(
        getEventsBeingHoveredOver,
      );
      const downtimeEvents = eventData.downtime.filter(
        getEventsBeingHoveredOver,
      );

      return (
        <ChartTooltip>
          <span className="label">
            Date: {dayjs(label).format('ddd DD MMM')}
            <br />
            Time: {dayjs(label).format('HH:mm')}
            <br />
            {productionRateData && (
              <>
                Production rate: {productionRateData.value}{' '}
                {valuesUnit}/hr
                <br />
              </>
            )}
            {storageLevelData && (
              <>
                Static storage Level: {storageLevelData.value} %
                <br />
              </>
            )}
            {Boolean(offtakeEvents.length) && (
              <>
                <br />
                <strong>Offtake:</strong>
                <br />
                {offtakeEvents.map((event, idx) => (
                  <div key={event.id}>
                    {event.name} ({event.h2Quantity} {valuesUnit}){' '}
                    {idx !== offtakeEvents.length - 1 && <br />}
                  </div>
                ))}
              </>
            )}
            {Boolean(downtimeEvents.length) && (
              <>
                <br />
                <strong>Downtime:</strong>
                <br />
                {downtimeEvents.map((event, idx) => (
                  <div key={event.id}>
                    <div>{event.electrolyzerDisplayName}</div>(
                    {event.type})
                    {idx !== offtakeEvents.length - 1 && <br />}
                  </div>
                ))}
              </>
            )}
          </span>
        </ChartTooltip>
      );
    }
    return <></>;
  };

const renderEvents = (
  eventData: RollingScheduleChartEventData,
  offtakeColour: string,
  downtimeColour: string,
) =>
  [...eventData.offtake, ...eventData.downtime].map((event) => {
    const { id, start, end, eventType } = event;
    const eventTypeColour =
      eventType === EventType.OFFTAKE
        ? offtakeColour
        : downtimeColour;
    return (
      <ReferenceArea
        x1={start}
        x2={end}
        y1={eventType === EventType.OFFTAKE ? '94' : '86'}
        y2={eventType === EventType.OFFTAKE ? '100' : '92'}
        fill={eventTypeColour}
        stroke={eventTypeColour}
        opacity={1}
        fillOpacity={1}
        yAxisId="arbitraryPositionAxis"
        xAxisId="timeAxis"
        key={id}
        type="monotone"
      />
    );
  });

interface Props {
  xAxisValues: ChartXAxisValues;
  scheduleProductionRateData: RollingScheduleChartProductionRateDataRow[];
  eventData: RollingScheduleChartEventData;
  valuesUnit: H2MeasurementUnit;
}

const RollingScheduleChart = ({
  xAxisValues,
  scheduleProductionRateData,
  eventData,
  valuesUnit,
}: Props) => {
  const theme = useTheme();

  const showStorageLevel =
    scheduleProductionRateData &&
    scheduleProductionRateData[0].staticStorageLevelPercentage !==
      null;

  const dataToPlot = {
    storageLevel: {
      color: theme.palette.secondary.light,
      label: 'Static storage level (%)',
      key: 'staticStorageLevelPercentage',
    },
    productionRate: {
      color: theme.palette.primary.light,
      label: `Production rate (${valuesUnit}/hr)`,
      key: 'productionRate',
    },
    offtakePeriod: {
      color: theme.palette.common.white,
      label: 'Offtake period',
    },
    downtimePeriod: {
      color: theme.palette.error.light,
      label: 'Downtime period',
    },
  };

  const legendPayload = [
    {
      value: dataToPlot.offtakePeriod.label,
      type: 'rect',
      color: dataToPlot.offtakePeriod.color,
    },
    {
      value: dataToPlot.downtimePeriod.label,
      type: 'rect',
      color: dataToPlot.downtimePeriod.color,
    },
    {
      value: dataToPlot.productionRate.label,
      type: 'line',
      color: dataToPlot.productionRate.color,
    },
    ...(showStorageLevel
      ? [
          {
            value: dataToPlot.storageLevel.label,
            type: 'line',
            color: dataToPlot.storageLevel.color,
          },
        ]
      : []),
  ] as Payload[];

  const chartStyleProps = {
    margin: {
      top: 30,
      bottom: 20,
      right: 40,
    },
  };

  const lightGrey = theme.palette.grey[300];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart {...chartStyleProps}>
        <CartesianGrid
          strokeWidth={0.5}
          strokeDasharray="2 2"
          stroke={theme.palette.grey[500]}
        />
        <XAxis
          type="number"
          ticks={xAxisValues}
          minTickGap={20}
          domain={[xAxisValues[0], R.last(xAxisValues) as number]}
          dataKey="periodStart"
          tickFormatter={(date) => dayjs(date).format('HH:mm ddd')}
          xAxisId="timeAxis"
          allowDataOverflow={true}
          stroke={lightGrey}
        />
        <YAxis
          yAxisId="productionRateAxis"
          stroke={lightGrey}
          label={{
            value: dataToPlot.productionRate.label,
            position: 'middle',
            angle: 270,
            dx: -12,
            fill: lightGrey,
          }}
          padding={{ top: 50 }}
        ></YAxis>
        {renderEvents(
          eventData,
          dataToPlot.offtakePeriod.color,
          dataToPlot.downtimePeriod.color,
        )}
        <Line
          xAxisId="timeAxis"
          yAxisId="productionRateAxis"
          name={dataToPlot.productionRate.label}
          type="stepBefore"
          dataKey={dataToPlot.productionRate.key}
          stroke={dataToPlot.productionRate.color}
          strokeWidth={2}
          dot={false}
          activeDot={{ fill: dataToPlot.productionRate.color }}
          data={scheduleProductionRateData}
        />
        {/* This is a hidden YAxis which is used to arbitrarily position the events bars at the top of the graph */}
        <YAxis
          yAxisId="arbitraryPositionAxis"
          hide={true}
          domain={[0, 100]}
        />
        {showStorageLevel && (
          <>
            <YAxis
              yAxisId="storageLevelAxis"
              stroke={lightGrey}
              orientation="right"
              domain={[0, 100]}
              padding={{ top: 50 }}
              label={{
                value: dataToPlot.storageLevel.label,
                position: 'middle',
                angle: 90,
                dx: 20,
                fill: lightGrey,
              }}
            />
            <Line
              xAxisId="timeAxis"
              yAxisId="storageLevelAxis"
              name={dataToPlot.storageLevel.label}
              type="linear"
              dataKey={dataToPlot.storageLevel.key}
              stroke={dataToPlot.storageLevel.color}
              strokeWidth={2}
              dot={false}
              activeDot={{ fill: dataToPlot.productionRate.color }}
              data={scheduleProductionRateData}
            />
          </>
        )}
        <Legend payload={legendPayload} />
        <Tooltip
          content={renderTooltip({
            eventData,
            productionRateDataKey: dataToPlot.productionRate.key,
            storageLevelDataKey: dataToPlot.storageLevel.key,
            valuesUnit,
          })}
          wrapperStyle={{ outline: 'none' }}
        />
        ;
      </LineChart>
    </ResponsiveContainer>
  );
};

export default RollingScheduleChart;
