import { styled } from '@mui/material/styles';

interface Props {
  children: React.ReactNode;
}

const Container = styled('div')(({ theme }) => ({
  padding: '8px',
  backgroundColor: theme.palette.background.default,
  borderRadius: '4px',
  minHeight: '100px',
  display: 'flex',
}));

const DataCard = ({ children }: Props) => {
  return <Container>{children}</Container>;
};

export default DataCard;
