import React from 'react';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import CalculatorIcon from '@mui/icons-material/CalculateOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShippingOutlined';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DrawerNavItem from './DrawerNavItem';
import paths from '../../paths';
import { styled, Theme, CSSObject } from '@mui/material/styles';

const drawerWidth = 240;

const ToolbarIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '0 8px',
  ...theme.mixins.toolbar,
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function DrawerNav({ open, handleDrawerClose }: any) {
  const navItems = [
    {
      to: paths.dashboard,
      Icon: DashboardIcon,
      label: 'Dashboard',
    },
    {
      to: paths.powerCostEstimator,
      Icon: CalculatorIcon,
      label: 'Power Cost Calculator',
    },
    {
      to: paths.offtakers,
      Icon: LocalShippingIcon,
      label: 'Offtakers',
    },
    {
      to: paths.events,
      Icon: DateRangeIcon,
      label: 'Events',
    },
  ];

  return (
    <Drawer
      variant="permanent"
      open={open}
      PaperProps={{ sx: { backgroundColor: 'divider' } }}
    >
      <ToolbarIcon>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </ToolbarIcon>
      <Divider />
      <List>
        {navItems.map(
          (item) => item && <DrawerNavItem key={item.to} {...item} />,
        )}
      </List>
    </Drawer>
  );
}
