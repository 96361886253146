import { createSlice } from '@reduxjs/toolkit';
import jwtDecode from 'jwt-decode';
import { RootState } from '../../app/store';
import { UserPreferences } from './userTypes';
import { H2MeasurementUnit } from '../../common/types';

const initialState = {
  permissions: [] as string[],
  preferences: {} as UserPreferences,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserPermissions(state, action) {
      const decodedToken = jwtDecode<any>(action.payload);
      state.permissions = decodedToken.permissions;
    },
    updateUserValuesUnit(state, action) {
      state.preferences = {
        ...state.preferences,
        valuesUnit: action.payload,
      };
    },
  },
});

export const userActions = userSlice.actions;

export const userHasEditPermission = (state: RootState): boolean => {
  return state.user.permissions.includes('update:all');
};

export const getUserValuesUnit = (
  state: RootState,
): H2MeasurementUnit => {
  return state.user?.preferences?.valuesUnit ?? H2MeasurementUnit.KG;
};

export default userSlice.reducer;
