import { Button, CircularProgress } from '@mui/material';
import { MouseEventHandler } from 'react';

interface Props {
  showButton: boolean;
  showEnd: boolean;
  onClickStart: MouseEventHandler;
  onClickEnd: MouseEventHandler;
  isPendingEnd: boolean;
}

const EventRowActionBtn = ({
  showButton,
  showEnd,
  onClickStart,
  onClickEnd,
  isPendingEnd,
}: Props) => {
  if (!showButton) return null;

  return showEnd ? (
    <Button
      onClick={onClickEnd}
      sx={{ minWidth: 72 }}
      variant={isPendingEnd ? 'outlined' : 'contained'}
      color="secondary"
    >
      {isPendingEnd ? (
        <CircularProgress size={20} color="secondary" />
      ) : (
        'End'
      )}
    </Button>
  ) : (
    <Button
      onClick={onClickStart}
      sx={{ minWidth: 72 }}
      variant="contained"
      color="primary"
    >
      Start
    </Button>
  );
};

export default EventRowActionBtn;
