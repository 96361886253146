import dayjs from 'dayjs';
import { useState } from 'react';
import Card from '@mui/material/Card';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {
  EventStatus,
  OfftakeEventViewModel,
  UpdatedOfftakeEvent,
} from '../../../state/events/eventTypes';
import { ModalForm } from '../../common/modalStyles';
import { useAppSelector } from '../../../hooks/common';
import { hasRenewableIncentiveConfigured } from '../../../state/generationUnits/generationUnitsSlice';
import OfftakeEventFields, {
  OfftakeEventFormValues,
} from './OfftakeEventFields';
import { getOfftakersSelectOpts } from '../../../state/offtakers/offtakerSlice';
import { hasStaticStorageUnit } from '../../../state/storageUnits/storageUnitSlice';
import UpdateEventFormActions from '../UpdateEventFormActions';
import { RequestState } from '../../../state/requestTypes';
import { styled } from '@mui/material';

interface Props {
  event: OfftakeEventViewModel;
  handleSubmit: (formValues: UpdatedOfftakeEvent) => Promise<void>;
  disabled: boolean;
  requestState: RequestState;
  handleClose: () => void;
}

const StatusFormControl = styled(FormControl)(() => ({
  minWidth: 130,
}));

const UpdateOfftakeEventForm = ({
  event,
  handleSubmit,
  handleClose,
  requestState,
  disabled,
}: Props) => {
  const hasRenewableIncentive = useAppSelector((state) =>
    hasRenewableIncentiveConfigured(state, event.generationUnitId),
  );

  const hasStaticStorage = useAppSelector((state) =>
    hasStaticStorageUnit(state, event.generationUnitId),
  );

  const offtakersSelectOpts = useAppSelector(getOfftakersSelectOpts);

  const [
    offtakeEventSpecificFormValues,
    setOfftakeEventSpecificFormValues,
  ] = useState<OfftakeEventFormValues>({
    expectedStart: new Date(event.start),
    expectedEnd: new Date(event.end),
    h2QuantityKg: event.h2QuantityKg.toString(),
    offtakerId: event.offtakerId,
    renewableIncentiveEligibleMassKg:
      event.renewableIncentiveEligibleMassKg?.toString() ?? '',
    h2IsRequiredUpFront: event.h2IsRequiredUpFront,
  });

  const [statusFormValue, setStatusFormValue] = useState<EventStatus>(
    event.status,
  );

  return (
    <ModalForm
      style={{ maxWidth: '1100px' }}
      onSubmit={async (e) => {
        e.preventDefault();

        await handleSubmit({
          ...offtakeEventSpecificFormValues,
          id: event.id,
          status: statusFormValue,
          expectedStart: dayjs(
            offtakeEventSpecificFormValues.expectedStart,
          )
            .second(0)
            .toISOString(),
          expectedEnd: dayjs(
            offtakeEventSpecificFormValues.expectedEnd,
          )
            .second(0)
            .toISOString(),
          h2QuantityKg: parseInt(
            offtakeEventSpecificFormValues.h2QuantityKg,
            10,
          ),
          renewableIncentiveEligibleMassKg:
            typeof offtakeEventSpecificFormValues.renewableIncentiveEligibleMassKg ===
            'string'
              ? parseInt(
                  offtakeEventSpecificFormValues.renewableIncentiveEligibleMassKg,
                )
              : undefined,
        });
      }}
    >
      <Card variant="outlined">
        <CardContent>
          <Grid container direction="row">
            <OfftakeEventFields
              setFormValues={(formValues) =>
                setOfftakeEventSpecificFormValues((prevState) => ({
                  ...prevState,
                  ...formValues,
                }))
              }
              formValues={offtakeEventSpecificFormValues}
              offtakersSelectOpts={offtakersSelectOpts}
              disabled={disabled}
              hasRenewableIncentive={hasRenewableIncentive}
              hasStaticStorage={hasStaticStorage}
            />
          </Grid>
        </CardContent>
      </Card>
      <StatusFormControl>
        <InputLabel id="status-select-label">Status</InputLabel>
        <Select
          labelId="status-select-label"
          label="Status"
          value={statusFormValue}
          onChange={(e) =>
            setStatusFormValue(
              (e.target as HTMLSelectElement).value as EventStatus,
            )
          }
        >
          <MenuItem value={EventStatus.DRAFT}>
            {EventStatus.DRAFT}
          </MenuItem>
          <MenuItem value={EventStatus.CONFIRMED}>
            {EventStatus.CONFIRMED}
          </MenuItem>
        </Select>
      </StatusFormControl>

      <UpdateEventFormActions
        handleClose={handleClose}
        successMessage="Updated successfully"
        requestState={requestState}
      />
    </ModalForm>
  );
};

export default UpdateOfftakeEventForm;
