import React from 'react';
import { Link } from 'react-router-dom';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ListItemButton } from '@mui/material';
import { useLocation } from 'react-router-dom';

interface Props {
  to: string;
  Icon: React.ComponentType;
  label: string;
}

const DrawerNavItem = ({ to, Icon, label }: Props) => {
  const location = useLocation();
  return (
    <div>
      <ListItemButton
        selected={location.pathname === to}
        component={Link}
        to={to}
      >
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </div>
  );
};

export default DrawerNavItem;
