import React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

interface FormValues {
  manufacturer: string;
  serialNumber: string;
  iiotThingName: string;
}

interface Props {
  disabled?: boolean;
  setFormValues: (formValues: FormValues) => void;
  formValues: FormValues;
}

const EdgeDeviceFields = ({
  disabled,
  setFormValues,
  formValues,
}: Props) => (
  <Stack spacing={3}>
    <TextField
      fullWidth
      id="name"
      required
      onInput={(e) =>
        setFormValues({
          ...formValues,
          manufacturer: (e.target as HTMLTextAreaElement).value,
        })
      }
      label="Manufacturer"
      variant="filled"
      value={formValues.manufacturer}
      disabled={disabled}
    />
    <TextField
      fullWidth
      id="serialNumber"
      required
      onInput={(e) =>
        setFormValues({
          ...formValues,
          serialNumber: (e.target as HTMLTextAreaElement).value,
        })
      }
      label="Serial No."
      variant="filled"
      value={formValues.serialNumber}
      disabled={disabled}
    />
    <TextField
      fullWidth
      id="iiotThingName"
      required
      onInput={(e) =>
        setFormValues({
          ...formValues,
          iiotThingName: (e.target as HTMLTextAreaElement).value,
        })
      }
      label="IIoT Thing Name"
      variant="filled"
      value={formValues.iiotThingName}
      disabled={disabled}
    />
  </Stack>
);

export default EdgeDeviceFields;
