import agent from 'axios';
import { handleApiError } from '../common/apiErrorHandler';
import { RollingScheduleViewModel } from './rollingScheduleTypes';

interface FetchRollingScheduleResponse {
  data: { rollingSchedule: RollingScheduleViewModel };
}

export async function fetchRollingScheduleByGenerationUnitId(
  generationUnitId: string,
) {
  return await handleApiError<{
    data: RollingScheduleViewModel;
  }>(
    () =>
      agent
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/generation-units/${generationUnitId}/rolling-schedule`,
        )
        .then(({ data }: FetchRollingScheduleResponse) => ({
          data: data && data.rollingSchedule,
        })),
    'Failed to fetch production schedule',
  );
}
