import * as React from 'react';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

interface Props {
  percentage: number;
}

const Container = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const ProgressContainer = styled(Box)(() => ({
  width: '100%',
  marginRight: '4px',
}));

const TextContainer = styled(Box)(() => ({
  minWidth: 35,
}));

const ProductionRate = ({ percentage }: Props) => (
  <Container>
    <ProgressContainer>
      <LinearProgress variant="determinate" value={percentage} />
    </ProgressContainer>
    <TextContainer>
      <Typography variant="body2">{`${percentage}%`}</Typography>
    </TextContainer>
  </Container>
);

export default ProductionRate;
