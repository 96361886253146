import { forwardRef } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  value: Date | null;
  handleChange: (value: Date | null) => void;
  label: string;
  timeIntervals?: number;
  disabled?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  fullWidth?: boolean;
}

const DatePicker = ({
  value,
  handleChange,
  label,
  timeIntervals,
  disabled,
  onOpen,
  onClose,
  fullWidth,
}: Props) => {
  const MuiStyleInput = forwardRef<HTMLInputElement, TextFieldProps>(
    ({ value, onClick, label }, ref) => (
      <TextField
        disabled={disabled}
        label={label}
        value={value}
        onClick={onClick}
        ref={ref}
        variant="filled"
        sx={{ width: fullWidth ? '100%' : '180px' }}
      />
    ),
  );

  return (
    <ReactDatePicker
      selected={value ? new Date(value) : null}
      onChange={handleChange}
      showTimeSelect
      timeFormat="HH:mm"
      timeIntervals={timeIntervals}
      timeCaption="time"
      dateFormat="MMMM d, h:mm aa"
      customInput={<MuiStyleInput label={label} />}
      onCalendarOpen={onOpen}
      onCalendarClose={onClose}
      disabled={disabled}
    />
  );
};

export default DatePicker;
