import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';
import { useMemo, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import type { RootState, AppDispatch } from '../app/store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> =
  useSelector;

export const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const usePolling = (cb: Function, delay: number) => {
  const savedCallback = useRef<Function>();

  useEffect(() => {
    cb();
    savedCallback.current = cb;
  }, [cb]);

  useEffect(() => {
    if (delay !== null) {
      const id = setInterval(() => savedCallback.current?.(), delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
