import React from 'react';
import Alert from '@mui/material/Alert';
import { Typography } from '@mui/material';
import { useAppSelector } from '../../hooks/common';
import { H2MeasurementUnit } from '../../common/types';
import { TransformedPowerCostEstimate } from '../../state/powerCostEstimate/powerCostEstimateTypes';
import { selectedUnitHasIncentiveConfigured } from '../../state/generationUnits/generationUnitsSlice';

interface Props {
  estimate: TransformedPowerCostEstimate;
  valuesUnit: H2MeasurementUnit;
}

const formatPounds = (penceValue: number) => {
  return `£${(penceValue / 100).toFixed(2)}`;
};

const PowerCostResultCard = ({
  estimate: {
    totalCost,
    totalCostBeforeIncentiveDeduction,
    costPerUnit,
    costPerUnitBeforeIncentiveDeduction,
    totalIncentiveValue,
    totalIncentiveKg,
  },
  valuesUnit,
}: Props) => {
  const showIncentive = useAppSelector((state) =>
    selectedUnitHasIncentiveConfigured(state),
  );

  return (
    <Alert severity="success">
      <Typography component="div">
        <Typography variant="h6" component="h2">
          Overall Cost
        </Typography>
        <div>
          Per {valuesUnit}: {formatPounds(costPerUnit)}
          {showIncentive &&
            ` (${formatPounds(
              costPerUnitBeforeIncentiveDeduction,
            )} without renewable incentive)`}
        </div>
        <div>
          Total: {formatPounds(totalCost)}
          {showIncentive &&
            ` (${formatPounds(
              totalCostBeforeIncentiveDeduction,
            )} without renewable incentive)`}
        </div>
        {showIncentive && (
          <div>
            <br />
            <Typography variant="h6" component="h2">
              Renewable Incentive
            </Typography>
            Total value of incentive:{' '}
            {formatPounds(totalIncentiveValue)}
            <br />
            Total H₂ qualifying for incentive: {totalIncentiveKg} kg
          </div>
        )}
      </Typography>
    </Alert>
  );
};

export default PowerCostResultCard;
