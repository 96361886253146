import dayjs from 'dayjs';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

interface Props {
  time: string;
}

const ForecastTimestampTypography = styled(Typography)(
  ({ theme }) => ({
    right: theme.spacing(2),
    bottom: theme.spacing(2),
    color: theme.palette.text.secondary,
  }),
) as typeof Typography;

const ForecastTimestamp = ({ time }: Props) => {
  return (
    <Grid container justifyContent="end">
      <Grid item>
        <ForecastTimestampTypography component="span" variant="body2">
          Forecast last updated at&nbsp;
          {dayjs(time).format('HH:mm DD/MM')}
        </ForecastTimestampTypography>
      </Grid>
    </Grid>
  );
};

export default ForecastTimestamp;
