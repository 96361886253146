import { ChangeEvent } from 'react';
import { Grid, FormControlLabel, Switch } from '@mui/material';
import StatusFilter from './StatusFilter';

interface Props {
  updatePastEventsFilter: (
    event: ChangeEvent<HTMLInputElement>,
  ) => void;
  updateEventStatusFilter: (event: string[]) => void;
  statusFilterState: { id: string; value: string[] };
}

const EventTableFilters = ({
  updatePastEventsFilter,
  updateEventStatusFilter,
  statusFilterState,
}: Props) => (
  <Grid container paddingBottom="14px">
    <Grid item xs={3}>
      <StatusFilter
        updateFilter={updateEventStatusFilter}
        filterState={statusFilterState}
      />
    </Grid>
    <Grid item xs={2} marginTop={2}>
      <FormControlLabel
        label="Include past events"
        labelPlacement="start"
        control={<Switch onChange={updatePastEventsFilter} />}
      />
    </Grid>
  </Grid>
);

export default EventTableFilters;
