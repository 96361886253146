import React from 'react';
import Alert from '@mui/material/Alert';
import { useAppSelector } from '../../hooks/common';
import { getXAxisValuesForForecastViewWindow } from '../../state/charts/chartsSlice';
import ProductionChart from '../common/ProductionChart';
import { ProductionPlanChartData } from '../../state/common/types';

interface Props {
  data: ProductionPlanChartData;
}

const ProductionPlanChart = ({ data }: Props) => {
  const xAxisValues = useAppSelector(
    getXAxisValuesForForecastViewWindow,
  );

  if (!xAxisValues) {
    return (
      <Alert severity="error">Unable to format chart data</Alert>
    );
  }

  return <ProductionChart data={data} xAxisValues={xAxisValues} />;
};

export default ProductionPlanChart;
