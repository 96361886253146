import React, { useCallback, useState } from 'react';
import { Modal } from '@mui/material';
import { ModalContents } from '../../common/modalStyles';
import ModalTitle from '../../common/ModalTitle';
import StartOfftakeEventForm from './StartOfftakeEventForm';
import {
  useAppSelector,
  useAppDispatch,
} from '../../../hooks/common';
import {
  clearStartEventStatus,
  getEventById,
  getStartEventState,
} from '../../../state/events/eventSlice';
import {
  DowntimeEventViewModel,
  EventType,
  OfftakeEventViewModel,
} from '../../../state/events/eventTypes';
import StartDowntimeEventForm from '../downtime/StartDowntimeEventForm';
import parseRequestState from '../../../state/common/requestStateParser';

interface Props {
  open: boolean;
  onCloseModal: () => void;
  eventId: string;
  handleStart: (formValues: {
    eventId: string;
    expectedEnd: string;
    h2QuantityKg?: number;
    renewableIncentiveEligibleMassKg?: number;
  }) => Promise<void>;
}

const StartEventModal = ({
  open,
  eventId,
  onCloseModal,
  handleStart,
}: Props) => {
  // We specify height for when the date picker is open only. Otherwise (e.g. upon error), the modal correctly controls its own height.
  const [modalHeight, setModalHeight] = useState<number | undefined>(
    undefined,
  );

  const event = useAppSelector((state) =>
    getEventById(state, eventId),
  );

  const dispatch = useAppDispatch();
  const requestState = useAppSelector(getStartEventState);
  const { isPending, isFulfilled } = parseRequestState(requestState);

  const disabled = isPending || isFulfilled;

  const handleClose = useCallback(() => {
    if (isPending) return;
    onCloseModal();
    dispatch(clearStartEventStatus());
  }, [isPending, onCloseModal, dispatch]);

  if (!event) return null;

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalContents style={{ width: 440, height: modalHeight }}>
        <ModalTitle
          text={`Start ${event.eventType} event`}
          id="start-event-modal-title"
        />
        {event.eventType === EventType.OFFTAKE && (
          <StartOfftakeEventForm
            event={event as OfftakeEventViewModel}
            onOpenDatePicker={() => setModalHeight(464)}
            onCloseDatePicker={() => setModalHeight(undefined)}
            handleSubmit={handleStart}
            handleClose={handleClose}
            disabled={disabled}
            requestState={requestState}
          />
        )}
        {event.eventType === EventType.DOWNTIME && (
          <StartDowntimeEventForm
            event={event as DowntimeEventViewModel}
            onOpenDatePicker={() => setModalHeight(464)}
            onCloseDatePicker={() => setModalHeight(undefined)}
            handleSubmit={handleStart}
            handleClose={handleClose}
            disabled={disabled}
            requestState={requestState}
          />
        )}
      </ModalContents>
    </Modal>
  );
};

export default StartEventModal;
