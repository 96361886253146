import React from 'react';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import Collapse from '@mui/material/Collapse';
import { styled } from '@mui/material/styles';
import IconButton, {
  IconButtonProps,
} from '@mui/material/IconButton';
import CurrentActivity from './CurrentActivity';
import ProductionPlan from '../productionPlan/ProductionPlan';
import ForecastCharts from '../forecasts/charts/ForecastCharts';
import { GenerationUnitViewModel } from '../../state/generationUnits/generationUnitTypes';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import GenerationUnitSpecsModal from './GenerationUnitSpecsModal';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  unit: GenerationUnitViewModel;
}

const GenerationUnit = ({ unit }: Props) => {
  const [expanded, setExpanded] = React.useState(true);
  const [open, setOpen] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Grid container direction={'row'} spacing={3} sx={{ mb: 1 }}>
        <Grid item xs={9} md={6}>
          <Grid container>
            <Grid item>
              <Typography component="h2" variant="h2" gutterBottom>
                {unit.name}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title="More information">
                <IconButton
                  size="small"
                  onClick={() => setOpen(true)}
                >
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <GenerationUnitSpecsModal
            open={open}
            generationUnitId={unit.id}
            handleClose={() => setOpen(false)}
          />
        </Grid>
        <Grid item xs={3} md={6}>
          <Grid container justifyContent="flex-end">
            <ExpandMore
              expand={expanded}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore>
          </Grid>
        </Grid>
      </Grid>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <Grid
          container
          direction="row"
          spacing={2}
          alignItems={'center'}
        >
          <Grid item xs={12}>
            <CurrentActivity generationUnitId={unit.id} />
          </Grid>
          <Grid item xs={12}>
            <ProductionPlan generationUnitId={unit.id} />
          </Grid>
          <Grid item xs={12}>
            <ForecastCharts
              forecastSources={unit.forecastSources}
              generationUnitId={unit.id}
            />
          </Grid>
        </Grid>
      </Collapse>
    </>
  );
};

export default GenerationUnit;
