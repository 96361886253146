import dayjs from 'dayjs';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {
  TransformedOfftakeEventViewModel,
  DowntimeEventViewModel,
  EventStatus,
} from '../../state/events/eventTypes';

interface Props {
  event: TransformedOfftakeEventViewModel | DowntimeEventViewModel;
}

const EventDeferralWarning = ({
  event: { start, end, status, deferralIsOverdue },
}: Props) => {
  if (!deferralIsOverdue) {
    return <></>;
  }

  const statusChange =
    status === EventStatus.CONFIRMED ? 'start' : 'end';
  const expectedChangeTimestamp = dayjs(
    status === EventStatus.CONFIRMED ? start : end,
  );
  const date = expectedChangeTimestamp.format('ddd MM MMM');
  const time = expectedChangeTimestamp.format('h:mm A');

  const warningText = `An event that was supposed to ${statusChange} at ${time} on ${date} has not been ${statusChange}ed, nor automatically deferred to a later time. This is most likely due to an issue with the platform and the Octopus Hydrogen tech team have been alerted of the issue. If you haven't heard form them yet and need assistance, feel free to email devs@octohydrogen.com`;

  return (
    <Tooltip title={warningText}>
      <IconButton size="small">
        <WarningAmberIcon fontSize="large" color="error" />
      </IconButton>
    </Tooltip>
  );
};

export default EventDeferralWarning;
