import React from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

interface Props {
  children: React.ReactNode;
}

const ErrorContainer = styled('div')(({ theme }) => ({
  color: theme.palette.error.light,
}));

export const FetchError = ({ children }: Props) => {
  return (
    <ErrorContainer>
      <Typography variant="caption" component="div">
        {children}
      </Typography>
    </ErrorContainer>
  );
};

const SuccessContainer = styled('div')(({ theme }) => ({
  color: theme.palette.grey[400],
}));

export const FetchSuccess = ({ children }: Props) => {
  return (
    <SuccessContainer>
      <Typography variant="caption" component="div">
        {children}
      </Typography>
    </SuccessContainer>
  );
};
