import dayjs from 'dayjs';
import { useMemo, ChangeEvent } from 'react';
import {
  useTable,
  useFilters,
  Column,
  FilterTypes,
  Row,
} from 'react-table';
import {
  EventStatus,
  TransformedEventViewModel,
} from '../../state/events/eventTypes';

const useEventsTable = (
  events: TransformedEventViewModel[],
): {
  rows: Row<TransformedEventViewModel>[];
  prepareRow: (row: Row<TransformedEventViewModel>) => void;
  updatePastEventsFilter: (
    event: ChangeEvent<HTMLInputElement>,
  ) => void;
  updateEventStatusFilter: (event: string[]) => void;
  statusFilterState: { id: string; value: string[] };
} => {
  const columns: Array<Column<TransformedEventViewModel>> = useMemo(
    () => [
      {
        accessor: (event) => event.end,
        id: 'end',
        filter: 'onlyInTheFuture',
      },
      {
        accessor: (event) => event.status,
        id: 'status',
        filter: 'checkedStatuses',
      },
    ],
    [],
  );

  const filterTypes: FilterTypes<TransformedEventViewModel> = useMemo(
    () => ({
      onlyInTheFuture: (rows, id, filterApplied) =>
        filterApplied
          ? rows.filter((row) => {
              const now = dayjs();
              const rowValue = row.values[id[0]];
              return dayjs(rowValue).isAfter(now);
            })
          : rows,
      checkedStatuses: (rows, id, statuses) =>
        rows.filter((row) => {
          const rowValue = row.values[id[0]];
          return statuses.includes(rowValue);
        }),
    }),
    [],
  );

  const { state, rows, prepareRow, setFilter } = useTable(
    {
      columns,
      data: events,
      filterTypes,
      autoResetFilters: false,
      initialState: {
        filters: [
          { id: 'end', value: true },
          {
            id: 'status',
            value: [
              EventStatus.DRAFT,
              EventStatus.CONFIRMED,
              EventStatus.STARTED,
              EventStatus.NEVER_STARTED,
              EventStatus.ENDED,
              EventStatus.AUTO_ENDED,
            ],
          },
        ],
      },
    },
    useFilters,
  );

  return {
    rows,
    prepareRow,
    updatePastEventsFilter: (
      event: ChangeEvent<HTMLInputElement>,
    ) => {
      setFilter('end', !event.target.checked);
    },
    updateEventStatusFilter: (statusValue: string[]) => {
      setFilter('status', statusValue);
    },
    statusFilterState: state.filters[1],
  };
};

export default useEventsTable;
