import {
  createAction,
  createSlice,
  PayloadAction,
  createSelector,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import * as R from 'ramda';
import dayjs from 'dayjs';
import { EntityState } from '../common/entitySliceCreator';
import { RequestState, RequestStatuses } from '../requestTypes';
import { RootState } from '../../app/store';
import {
  NewEvents,
  EventViewModel,
  RollingScheduleChartEventData,
  EventType,
  OfftakeEventViewModel,
  DowntimeEventViewModel,
  EventsActions,
  EventStatus,
  UpdatedOfftakeEvent,
  UpdatedDowntimeEvent,
} from './eventTypes';
import {
  addEvents,
  deleteEvent,
  fetchEventsByGenerationUnitId,
  startEvent,
  endEvent,
  addEventsAction,
  updateEvent,
} from './eventApi';
import { getOfftakerEntities } from '../offtakers/offtakerSlice';
import { getElectrolyzerEntities } from '../electrolyzers/electrolyzerSlice';
import { OfftakerViewModel } from '../offtakers/offtakerTypes';
import { getXAxisValuesForRollingScheduleViewWindow } from '../charts/chartsSlice';
import { ChartXAxisValues } from '../charts/chartXAxisValuesCreator';
import collateEventChartData from './eventChartDataCollator';
import { RejectValue } from '../common/errorTypes';
import { executeWithRejectValue } from '../common/executeWithRejectValue';
import { H2MeasurementUnit } from '../../common/types';
import { convertToUserUnit } from '../common/valueUnitConvertor';
import { TransformedEventViewModel } from './eventTypes';
import { ElectrolyzerViewModel } from '../electrolyzers/electrolyzerTypes';

interface DeleteState extends RequestState {
  entityId: string;
}
interface EndOfftakeEventState extends RequestState {
  entityId: string;
}

interface EventState {
  entities: { [key: string]: EventViewModel } | {};
  addEntityState: RequestState;
  fetchState: RequestState;
  deleteState: DeleteState;
  startEventState: RequestState;
  updateEventState: RequestState;
  endOfftakeEventState: EndOfftakeEventState;
  addActionState: RequestState;
}

const initialState: EventState = {
  entities: {},
  addEntityState: {
    status: RequestStatuses.idle,
    errors: [],
  },
  fetchState: {
    status: RequestStatuses.idle,
    errors: [],
  },
  deleteState: {
    status: RequestStatuses.idle,
    errors: [],
    entityId: '',
  },
  startEventState: {
    status: RequestStatuses.idle,
    errors: [],
  },
  updateEventState: {
    status: RequestStatuses.idle,
    errors: [],
  },
  endOfftakeEventState: {
    status: RequestStatuses.idle,
    errors: [],
    entityId: '',
  },
  addActionState: {
    status: RequestStatuses.idle,
    errors: [],
  },
};

export const clearAddEventsStatus = createAction(
  'events/clearAddEventsStatus',
);

export const clearDeleteEventStatus = createAction(
  'events/clearDeleteEventStatus',
);

export const clearStartEventStatus = createAction(
  'events/clearStartEventStatus',
);

export const clearUpdateEventStatus = createAction(
  'events/clearUpdateEventStatus',
);

export const clearEndOfftakeEventStatus = createAction(
  'events/clearEndOfftakeEventStatus',
);

export const clearAddActionStatus = createAction(
  'events/clearAddActionStatus',
);

export const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {
    clearAddEventsStatus: (state: EntityState) => {
      state.addEntityState = {
        status: RequestStatuses.idle,
        errors: [],
      };
    },
    clearDeleteEventStatus: (state: EntityState) => {
      state.deleteState = {
        status: RequestStatuses.idle,
        errors: [],
        entityId: '',
      };
    },
    clearStartEventStatus: (state: EntityState) => {
      state.startEventState = {
        status: RequestStatuses.idle,
        errors: [],
      };
    },
    clearUpdateEventStatus: (state: EntityState) => {
      state.updateEventState = {
        status: RequestStatuses.idle,
        errors: [],
      };
    },
    clearEndOfftakeEventStatus: (state: EntityState) => {
      state.endOfftakeEventState = {
        status: RequestStatuses.idle,
        errors: [],
        entityId: '',
      };
    },
    clearAddActionStatus: (state: EntityState) => {
      state.addActionState = {
        status: RequestStatuses.idle,
        errors: [],
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchEventsByGenerationUnitIdAsync.pending,
        (state) => {
          state.fetchState = {
            status: RequestStatuses.pending,
            errors: [],
          };
        },
      )
      .addCase(
        fetchEventsByGenerationUnitIdAsync.fulfilled,
        (
          state,
          action: PayloadAction<{
            [id: string]: EventViewModel;
          }>,
        ) => {
          if (action.payload) {
            state.entities = {
              ...state.entities,
              ...action.payload,
            };
          }

          state.fetchState = {
            status: RequestStatuses.fulfilled,
            errors: [],
          };
        },
      )
      .addCase(
        fetchEventsByGenerationUnitIdAsync.rejected,
        (state: EntityState, action) => {
          state.fetchState = {
            status: RequestStatuses.rejected,
            errors: action.payload?.errors,
          };
        },
      )
      .addCase(deleteEventAsync.pending, (state, action) => {
        state.deleteState = {
          status: RequestStatuses.pending,
          errors: [],
          entityId: action.meta.arg,
        };
      })
      .addCase(
        deleteEventAsync.fulfilled,
        (state, action: PayloadAction<EventViewModel>) => {
          state.entities = {
            ...state.entities,
            [action.payload.id]: action.payload,
          };

          state.deleteState = {
            status: RequestStatuses.fulfilled,
            errors: [],
            entityId: action.payload.id,
          };
        },
      )
      .addCase(
        deleteEventAsync.rejected,
        (state: EntityState, action) => {
          state.deleteState = {
            status: RequestStatuses.rejected,
            errors: action.payload?.errors,
            entityId: action.meta.arg,
          };
        },
      )
      .addCase(
        addEventsAsync.fulfilled,
        (state: EntityState, action) => {
          state.entities = {
            ...state.entities,
            ...action.payload,
          };

          state.addEntityState = {
            status: RequestStatuses.fulfilled,
            errors: [],
          };
        },
      )
      .addCase(addEventsAsync.pending, (state: EntityState) => {
        state.addEntityState = {
          status: RequestStatuses.pending,
          errors: [],
        };
      })
      .addCase(
        addEventsAsync.rejected,
        (state: EntityState, action) => {
          state.addEntityState = {
            status: RequestStatuses.rejected,
            errors: action.payload?.errors,
          };
        },
      )
      .addCase(
        updateEventAsync.fulfilled,
        (state: EntityState, action) => {
          state.entities = {
            ...state.entities,
            [action.payload.id]: action.payload,
          };

          state.updateEventState = {
            status: RequestStatuses.fulfilled,
            errors: [],
          };
        },
      )
      .addCase(updateEventAsync.pending, (state: EntityState) => {
        state.updateEventState = {
          status: RequestStatuses.pending,
          errors: [],
        };
      })
      .addCase(
        updateEventAsync.rejected,
        (state: EntityState, action) => {
          state.updateEventState = {
            status: RequestStatuses.rejected,
            errors: action.payload?.errors,
          };
        },
      )
      .addCase(
        addEventsActionAsync.fulfilled,
        (state: EntityState, action) => {
          state.entities = {
            ...state.entities,
            ...action.payload,
          };

          state.addActionState = {
            status: RequestStatuses.fulfilled,
            errors: [],
          };
        },
      )
      .addCase(addEventsActionAsync.pending, (state: EntityState) => {
        state.addActionState = {
          status: RequestStatuses.pending,
          errors: [],
        };
      })
      .addCase(
        addEventsActionAsync.rejected,
        (state: EntityState, action) => {
          state.addActionState = {
            status: RequestStatuses.rejected,
            errors: action.payload?.errors,
          };
        },
      )
      .addCase(startEventAsync.pending, (state) => {
        state.startEventState = {
          status: RequestStatuses.pending,
          errors: [],
        };
      })
      .addCase(
        startEventAsync.fulfilled,
        (state, action: PayloadAction<EventViewModel>) => {
          state.entities = {
            ...state.entities,
            [action.payload.id]: action.payload,
          };

          state.startEventState = {
            status: RequestStatuses.fulfilled,
            errors: [],
          };
        },
      )
      .addCase(startEventAsync.rejected, (state, action) => {
        state.startEventState = {
          status: RequestStatuses.rejected,
          errors: action.payload?.errors,
        };
      })
      .addCase(endEventAsync.pending, (state, action) => {
        state.endOfftakeEventState = {
          status: RequestStatuses.pending,
          errors: [],
          entityId: action.meta.arg,
        };
      })
      .addCase(
        endEventAsync.fulfilled,
        (state, action: PayloadAction<EventViewModel>) => {
          state.entities = {
            ...state.entities,
            [action.payload.id]: action.payload,
          };

          state.endOfftakeEventState = {
            status: RequestStatuses.fulfilled,
            errors: [],
            entityId: action.payload.id,
          };
        },
      )
      .addCase(endEventAsync.rejected, (state, action) => {
        state.endOfftakeEventState = {
          status: RequestStatuses.rejected,
          errors: action.payload?.errors,
          entityId: action.meta.arg,
        };
      });
  },
});

export const addEventsAsync = createAsyncThunk<
  { [id: string]: EventViewModel },
  { generationUnitId: string; newEvents: NewEvents },
  RejectValue
>(
  'events/addEvents',
  async ({ generationUnitId, newEvents }, { rejectWithValue }) => {
    return await executeWithRejectValue(async () => {
      const response = await addEvents(generationUnitId, newEvents);
      return response.data;
    }, rejectWithValue);
  },
);

export const addEventsActionAsync = createAsyncThunk<
  { [id: string]: EventViewModel },
  { generationUnitId: string; action: EventsActions },
  RejectValue
>(
  'events/addEventsAction',
  async ({ generationUnitId, action }, { rejectWithValue }) => {
    return await executeWithRejectValue(async () => {
      const response = await addEventsAction(
        generationUnitId,
        action,
      );
      return response.data;
    }, rejectWithValue);
  },
);

export const fetchEventsByGenerationUnitIdAsync = createAsyncThunk<
  { [id: string]: EventViewModel },
  string,
  RejectValue
>(
  'events/fetchEventsByGenerationUnitId',
  async (generationUnitId: string, { rejectWithValue }) => {
    return await executeWithRejectValue(async () => {
      const response = await fetchEventsByGenerationUnitId(
        generationUnitId,
      );
      return response.data;
    }, rejectWithValue);
  },
);

export const deleteEventAsync = createAsyncThunk<
  EventViewModel,
  string,
  RejectValue
>(
  'events/deleteEvent',
  async (eventId: string, { rejectWithValue }) => {
    return await executeWithRejectValue(async () => {
      const response = await deleteEvent(eventId);
      return response.data;
    }, rejectWithValue);
  },
);

export const startEventAsync = createAsyncThunk<
  OfftakeEventViewModel | DowntimeEventViewModel,
  {
    eventId: string;
    expectedEnd: string;
    h2QuantityKg?: number;
    renewableIncentiveEligibleMassKg?: number;
  },
  RejectValue
>(
  '/events/startEvent',
  async (
    {
      eventId,
      expectedEnd,
      h2QuantityKg,
      renewableIncentiveEligibleMassKg,
    },
    { rejectWithValue },
  ) => {
    return await executeWithRejectValue(async () => {
      const response = await startEvent(
        eventId,
        expectedEnd,
        h2QuantityKg,
        renewableIncentiveEligibleMassKg,
      );
      return response.data;
    }, rejectWithValue);
  },
);

export const updateEventAsync = createAsyncThunk<
  OfftakeEventViewModel | DowntimeEventViewModel,
  UpdatedOfftakeEvent | UpdatedDowntimeEvent,
  RejectValue
>(
  '/events/updateEvent',
  async (
    updatedEvent: UpdatedOfftakeEvent | UpdatedDowntimeEvent,
    { rejectWithValue },
  ) => {
    return await executeWithRejectValue(async () => {
      const response = await updateEvent(updatedEvent);
      return response.data;
    }, rejectWithValue);
  },
);

export const endEventAsync = createAsyncThunk<
  OfftakeEventViewModel,
  string,
  RejectValue
>('/events/endEvent', async (eventId, { rejectWithValue }) => {
  return await executeWithRejectValue(async () => {
    const response = await endEvent(eventId);
    return response.data;
  }, rejectWithValue);
});

const getId = (_: RootState, id: string) => id;

const getEntities = (
  state: RootState,
): { [id: string]: EventViewModel } => state.events.entities;

const startDateComparator = (
  a: TransformedEventViewModel,
  b: TransformedEventViewModel,
) => dayjs(a.start).unix() - dayjs(b.start).unix();

export const getEventsByGenerationUnitId = createSelector(
  getEntities,
  getId,
  (
    _state_: RootState,
    _generationUnitId: string,
    valuesUnit: H2MeasurementUnit,
  ) => valuesUnit,
  (
    entities,
    generationUnitId: string,
    valuesUnit: H2MeasurementUnit,
  ): TransformedEventViewModel[] => {
    const allEvents: EventViewModel[] = Object.values(entities);

    const eventsForGenUnit = allEvents.filter(
      (event) => event.generationUnitId === generationUnitId,
    );

    return R.sort(
      startDateComparator,
      convertEventDataToUserUnit(eventsForGenUnit, valuesUnit),
    );
  },
);

export const convertEventDataToUserUnit = (
  events: EventViewModel[],
  valuesUnit: H2MeasurementUnit,
): TransformedEventViewModel[] =>
  events.map((event) => {
    if (event.eventType === EventType.DOWNTIME) {
      return event;
    }

    const convertedEvent = {
      ...event,
      h2Quantity: convertToUserUnit(event.h2QuantityKg, valuesUnit),
      ...(!R.isNil(event.renewableIncentiveEligibleMassKg) && {
        incentivisedQuantity: convertToUserUnit(
          event.renewableIncentiveEligibleMassKg,
          valuesUnit,
        ),
      }),
    };

    const {
      renewableIncentiveEligibleMassKg,
      h2QuantityKg,
      ...formattedEvent
    } = convertedEvent;

    return formattedEvent;
  });

export const getEventDataForScheduleChart = createSelector(
  getOfftakerEntities,
  getElectrolyzerEntities,
  getEventsByGenerationUnitId,
  getXAxisValuesForRollingScheduleViewWindow,
  (
    offtakers: { [id: string]: OfftakerViewModel },
    electrolyzers: {
      [id: string]: ElectrolyzerViewModel;
    },
    eventsForGenerationUnit: TransformedEventViewModel[],
    xAxisValues: ChartXAxisValues,
  ): RollingScheduleChartEventData =>
    collateEventChartData(
      offtakers,
      electrolyzers,
      eventsForGenerationUnit,
      xAxisValues,
    ),
);

export const hasConfirmedOrStartedEvents = createSelector(
  getEventsByGenerationUnitId,
  (eventsForGenerationUnit: TransformedEventViewModel[]): boolean =>
    eventsForGenerationUnit.some(
      (evt) =>
        evt.status === EventStatus.CONFIRMED ||
        evt.status === EventStatus.STARTED,
    ),
);

export const getAddEventsState = (state: RootState): RequestState =>
  state.events.addEntityState;

export const getAddEventsActionState = (
  state: RootState,
): RequestState => state.events.addActionState;

export const getDeleteOfftakeEventState = (
  state: RootState,
): DeleteState => state.events.deleteState;

export const getEndEventState = (
  state: RootState,
): EndOfftakeEventState => state.events.endOfftakeEventState;

export const getStartEventState = (state: RootState): RequestState =>
  state.events.startEventState;

export const getUpdateEventState = (state: RootState): RequestState =>
  state.events.updateEventState;

export const getOfftakeEventById = (
  state: RootState,
  id: string,
): OfftakeEventViewModel | null => {
  const entities = getEntities(state);
  const event = entities[id];
  if (!event || event.eventType !== EventType.OFFTAKE) return null;
  return event;
};

export const getEventById = (
  state: RootState,
  id: string,
): OfftakeEventViewModel | DowntimeEventViewModel | null => {
  const entities = getEntities(state);
  const event = entities[id];
  if (!event) return null;
  return event;
};

export default eventsSlice.reducer;
