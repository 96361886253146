import {
  ActionReducerMapBuilder,
  AsyncThunk,
  PayloadAction,
} from '@reduxjs/toolkit';

import { RequestStatuses, RequestState } from '../requestTypes';
import { RejectValue } from './errorTypes';

export interface EntityState {
  addEntityState: RequestState;
  entities: { [key: string]: unknown };
  [key: string]: unknown;
}

function entitySliceBuilder<
  NewEntity,
  EntityViewModel extends { id: string },
>(
  addEntityAsync: AsyncThunk<EntityViewModel, NewEntity, RejectValue>,
  fetchEntities: AsyncThunk<
    { [key: string]: EntityViewModel },
    void,
    RejectValue
  >,
  clearAddStatusType: string,
) {
  const clearAddStatusKey = clearAddStatusType
    .split('/')
    .pop() as string;

  return {
    entityReducers: {
      [clearAddStatusKey]: (state: EntityState) => {
        state.addEntityState = {
          status: RequestStatuses.idle,
          errors: [],
        };
      },
    },
    extraEntityReducers: (
      builder: ActionReducerMapBuilder<EntityState>,
    ) => {
      builder
        .addCase(
          fetchEntities.fulfilled,
          (
            state: EntityState,
            action: PayloadAction<{ [key: string]: EntityViewModel }>,
          ) => {
            state.entities = action.payload;
          },
        )
        .addCase(
          addEntityAsync.fulfilled,
          (
            state: EntityState,
            action: PayloadAction<EntityViewModel>,
          ) => {
            state.entities = {
              ...state.entities,
              [action.payload.id]: action.payload,
            };

            state.addEntityState = {
              status: RequestStatuses.fulfilled,
              errors: [],
            };
          },
        )
        .addCase(addEntityAsync.pending, (state: EntityState) => {
          state.addEntityState = {
            status: RequestStatuses.pending,
            errors: [],
          };
        })
        .addCase(
          addEntityAsync.rejected,
          (state: EntityState, action) => {
            state.addEntityState = {
              status: RequestStatuses.rejected,
              errors: action.payload?.errors,
            };
          },
        );
    },
  };
}

export default entitySliceBuilder;
