import { handleApiError } from '../common/apiErrorHandler';
import {
  GenerationUnitsViewModel,
  GenerationUnitViewModel,
  NewGenerationUnit,
  UpdatedGenerationUnit,
} from './generationUnitTypes';

const agent = require('axios');

interface FetchGenerationUnitsResponse {
  data: { units: GenerationUnitsViewModel };
}

export async function fetchGenerationUnits() {
  return handleApiError<{ data: GenerationUnitsViewModel }>(
    () =>
      agent
        .get(`${process.env.REACT_APP_BACKEND_URL}/generation-units`)
        .then(({ data }: FetchGenerationUnitsResponse) => ({
          data: data.units,
        })),
    'Failed to fetch generation units',
  );
}

interface AddGenerationUnitResponse {
  data: { unit: GenerationUnitViewModel };
}

export async function addGenerationUnit(newUnit: NewGenerationUnit) {
  return handleApiError<{ data: GenerationUnitViewModel }>(
    () =>
      agent
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/generation-units`,
          newUnit,
        )
        .then(({ data }: AddGenerationUnitResponse) => ({
          data: data.unit,
        })),
    'Failed to add generation unit',
  );
}

interface UpdateGenerationUnitResponse {
  data: { unit: UpdateGenerationUnitResponse };
}

export async function updateGenerationUnit(
  updateGenerationUnit: UpdatedGenerationUnit,
) {
  const { id, ...updatedUnit } = updateGenerationUnit;
  return handleApiError<{ data: GenerationUnitViewModel }>(
    () =>
      agent
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/generation-units/${id}`,
          updatedUnit,
        )
        .then(({ data }: UpdateGenerationUnitResponse) => ({
          data: data.unit,
        })),
    'Failed to update generation unit',
  );
}
