import React from 'react';
import MuiChip from '@mui/material/Chip';
import GoodIcon from '@mui/icons-material/CheckCircleOutline';
import BadIcon from '@mui/icons-material/BlockOutlined';
import { styled } from '@mui/material/styles';

export enum ChipStatus {
  'GOOD',
  'BAD',
}
interface Props {
  label: string;
  status?: ChipStatus;
}

const Chip = styled(MuiChip, {
  shouldForwardProp: (prop) => prop !== 'status',
})<{ status: ChipStatus | undefined }>(({ status }) => {
  if (status === ChipStatus.GOOD) {
    return {
      '& .MuiChip-icon': {
        color: '#5af2a1',
      },
    };
  }
  if (status === ChipStatus.BAD) {
    return {
      '& .MuiChip-icon': {
        color: 'red',
      },
    };
  }
  return {};
});

const StatusChip = ({ label, status }: Props) => {
  return (
    <Chip
      variant="outlined"
      size="small"
      label={label}
      status={status}
      {...(status === ChipStatus.GOOD ? { icon: <GoodIcon /> } : {})}
      {...(status === ChipStatus.BAD ? { icon: <BadIcon /> } : {})}
    />
  );
};

export default StatusChip;
