import { useState } from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import EventDetailsStep from './eventDetailsStep/EventDetailsStep';
import SelectPlannedEvent from './selectEventStep/SelectEventStep';
import {
  DowntimeEventType,
  EventType,
} from '../../../state/events/eventTypes';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../hooks/common';
import {
  getActiveModalStep,
  resetPlanEventState,
} from '../../../state/planEvent/planEventSlice';
import { PlanDowntimeEventFormValues } from './eventDetailsStep/PlanDowntimeEventFormFields';
import { getElectrolyzerSelectOptsByGenerationUnitId } from '../../../state/electrolyzers/electrolyzerSlice';

const steps = [
  { name: 'Add event details', component: EventDetailsStep },
  { name: 'Select event', component: SelectPlannedEvent },
];

interface Props {
  generationUnitId: string;
  handleClose: () => void;
}

const PlanEventTimesModalContent = ({
  generationUnitId,
  handleClose,
}: Props) => {
  const dispatch = useAppDispatch();
  const activeStep = useAppSelector(getActiveModalStep);

  const [selectedEventType, setEventType] = useState<EventType>(
    EventType.DOWNTIME,
  );

  const electrolyzerSelectOpts = useAppSelector((state) =>
    getElectrolyzerSelectOptsByGenerationUnitId(
      state,
      generationUnitId,
    ),
  );

  const initialPlanDowntimeFormValues: PlanDowntimeEventFormValues = {
    earliestStart: dayjs().add(1, 'hour').startOf('hour').toDate(),
    latestEnd: dayjs().add(2, 'hour').startOf('hour').toDate(),
    eventDurationMinutes: '30',
    type: DowntimeEventType.MAINTENANCE,
    electrolyzerId: electrolyzerSelectOpts[0]?.value,
  };

  const [planDowntimeFormValues, setPlanDowntimeFormValues] =
    useState(initialPlanDowntimeFormValues);

  const handleBack = () => dispatch(resetPlanEventState());

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Stepper activeStep={activeStep}>
          {steps.map(({ name }) => (
            <Step key={name}>
              <StepLabel>
                <Typography component="h3" variant="subtitle1">
                  {name}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid item>
        {activeStep === 0 && (
          <EventDetailsStep
            generationUnitId={generationUnitId}
            handleBack={handleBack}
            handleClose={handleClose}
            selectedEventType={selectedEventType}
            setEventType={setEventType}
            formValues={planDowntimeFormValues}
            setFormValues={setPlanDowntimeFormValues}
            electrolyzerSelectOpts={electrolyzerSelectOpts}
          />
        )}
        {activeStep === 1 && (
          <SelectPlannedEvent
            generationUnitId={generationUnitId}
            handleBack={handleBack}
            handleClose={handleClose}
            selectedEventType={selectedEventType}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default PlanEventTimesModalContent;
