import {
  Alert,
  Button,
  CircularProgress,
  Stack,
} from '@mui/material';
import parseRequestState from '../../state/common/requestStateParser';
import { RequestState } from '../../state/requestTypes';
import Errors from '../common/Errors';

interface Props {
  handleClose: () => void;
  successMessage: string;
  requestState: RequestState;
}

const UpdateEventFormActions = ({
  handleClose,
  successMessage,
  requestState,
}: Props) => {
  const { isPending, isFulfilled, isRejected } =
    parseRequestState(requestState);

  return (
    <>
      {isRejected && <Errors errors={requestState.errors} />}
      {isFulfilled && (
        <Alert severity="success">{successMessage}</Alert>
      )}
      <Stack direction="row" spacing={2}>
        <Button
          variant="outlined"
          onClick={handleClose}
          data-testid="close"
        >
          {isFulfilled ? 'Close' : 'Cancel'}
        </Button>
        {!isFulfilled && (
          <Button
            type="submit"
            variant={isPending ? 'outlined' : 'contained'}
            color="primary"
            disabled={isPending || isFulfilled}
            sx={{ ml: 0.5 }}
            data-testid="submit"
          >
            {isPending ? <CircularProgress size={20} /> : 'Confirm'}
          </Button>
        )}
      </Stack>
    </>
  );
};

export default UpdateEventFormActions;
