import { MouseEventHandler } from 'react';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteIcon from '@mui/icons-material/Delete';

interface Props {
  show: boolean;
  isPending: boolean;
  handleDelete: MouseEventHandler;
}

const DeleteEventBtn = ({ show, isPending, handleDelete }: Props) => {
  if (!show) return <></>;

  if (isPending) {
    return <CircularProgress size={30} />;
  }

  return (
    <IconButton onClick={handleDelete} aria-label="delete event">
      <DeleteIcon />
    </IconButton>
  );
};

export default DeleteEventBtn;
