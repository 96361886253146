import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import { FormEvent } from 'react';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../hooks/common';
import { ElectrolyzerSelectOptsViewModel } from '../../../../state/electrolyzers/electrolyzerTypes';
import {
  DowntimeEventType,
  EventType,
} from '../../../../state/events/eventTypes';
import {
  fetchBestEventTimesAsync,
  getFetchBestEventTimesState,
} from '../../../../state/planEvent/planEventSlice';
import { RequestStatuses } from '../../../../state/requestTypes';
import BottomNav from '../BottomNav';
import PlanDowntimeEventForm from './PlanDowntimeEventForm';
import { PlanDowntimeEventFormValues } from './PlanDowntimeEventFormFields';

interface Props {
  generationUnitId: string;
  selectedEventType: EventType;
  setEventType: (eventType: EventType) => void;
  handleBack: () => void;
  handleClose: () => void;
  formValues: PlanDowntimeEventFormValues;
  setFormValues: (formValues: PlanDowntimeEventFormValues) => void;
  handleSubmit?: (e: FormEvent) => Promise<void>;
  electrolyzerSelectOpts: ElectrolyzerSelectOptsViewModel[];
}

const EventDetailsStep = ({
  generationUnitId,
  handleBack,
  handleClose,
  selectedEventType,
  setEventType,
  formValues,
  setFormValues,
  electrolyzerSelectOpts,
}: Props) => {
  const dispatch = useAppDispatch();
  const fetchBestEventTimesState = useAppSelector(
    getFetchBestEventTimesState,
  );
  const isLoading =
    fetchBestEventTimesState.status === RequestStatuses.pending;

  const handlePlanDowntimeSubmit = async (e: FormEvent) => {
    e.preventDefault();

    await dispatch(
      fetchBestEventTimesAsync({
        generationUnitId,
        eventType: EventType.DOWNTIME,
        earliestStart: dayjs(formValues.earliestStart).toISOString(),
        latestEnd: dayjs(formValues.latestEnd).toISOString(),
        eventDurationMinutes: parseInt(
          formValues.eventDurationMinutes as string,
        ),
        type: formValues.type as DowntimeEventType,
        electrolyzerId: formValues.electrolyzerId as string,
      }),
    );
  };

  return (
    <Grid container spacing={2} direction="column">
      <Grid item>
        <FormControl fullWidth>
          <InputLabel id="event-type-select-label">
            Event type
          </InputLabel>
          <Select
            disabled={true}
            labelId="event-type-select-label"
            label="Select the event type"
            value={selectedEventType}
            onChange={(e) =>
              setEventType(e.target.value as EventType)
            }
          >
            {[EventType.DOWNTIME, EventType.OFFTAKE].map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        {selectedEventType === EventType.DOWNTIME ? (
          <PlanDowntimeEventForm
            electrolyzerSelectOpts={electrolyzerSelectOpts}
            formValues={formValues}
            errors={fetchBestEventTimesState.errors}
            setFormValues={setFormValues}
          />
        ) : (
          <div>Placeholder for offtake event form</div>
        )}
      </Grid>
      <Grid item>
        <BottomNav
          isLoading={isLoading}
          submitButtonText="Find best time"
          handleBack={handleBack}
          disableBackButton={true}
          disableSubmitButton={Object.values(formValues).some(
            (value) => !value,
          )}
          handleClose={handleClose}
          handleSubmit={
            selectedEventType === EventType.DOWNTIME
              ? handlePlanDowntimeSubmit
              : undefined
          }
        />
      </Grid>
    </Grid>
  );
};

export default EventDetailsStep;
