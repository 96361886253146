import { Autocomplete, Grid, TextField } from '@mui/material';
import DatePicker from '../../../common/DatePicker';
import { ElectrolyzerSelectOptsViewModel } from '../../../../state/electrolyzers/electrolyzerTypes';
import { downtimeEventTypes } from '../../../../state/events/eventTypes';
import calculateExpectedEnd from '../../helpers/calculateExpectedEnd';

export interface PlanDowntimeEventFormValues {
  earliestStart: null | Date;
  latestEnd: null | Date;
  eventDurationMinutes: null | string;
  type: null | string;
  electrolyzerId: null | string;
}

interface Props {
  disabled?: boolean;
  setFormValues: (formValues: PlanDowntimeEventFormValues) => void;
  formValues: PlanDowntimeEventFormValues;
  electrolyzerSelectOpts: ElectrolyzerSelectOptsViewModel[];
}

const PlanDowntimeEventFormFields = ({
  disabled,
  setFormValues,
  formValues,
  electrolyzerSelectOpts,
}: Props) => {
  const typeSelectOpts = Object.values(downtimeEventTypes);

  const eventDurationMinutesNumber = Number(
    formValues.eventDurationMinutes,
  );
  const eventDurationError =
    eventDurationMinutesNumber === 0 ||
    eventDurationMinutesNumber % 15 !== 0
      ? 'Event duration must be a multiple of 15'
      : undefined;

  return (
    <>
      <Grid item>
        <DatePicker
          fullWidth={true}
          disabled={disabled}
          label="Earliest start"
          timeIntervals={15}
          value={formValues.earliestStart}
          handleChange={(value) =>
            setFormValues({
              ...formValues,
              earliestStart: value,
              latestEnd: calculateExpectedEnd(
                value,
                formValues.latestEnd,
              ),
            })
          }
        />
      </Grid>
      <Grid item>
        <DatePicker
          fullWidth={true}
          disabled={disabled}
          label="Latest end"
          timeIntervals={15}
          value={formValues.latestEnd}
          handleChange={(value) =>
            setFormValues({
              ...formValues,
              latestEnd: value,
            })
          }
        />
      </Grid>
      <Grid item>
        <TextField
          id="event-duration-minutes"
          required
          fullWidth
          type="number"
          onInput={(e) =>
            setFormValues({
              ...formValues,
              eventDurationMinutes: (e.target as HTMLTextAreaElement)
                .value,
            })
          }
          label="Event duration (mins)"
          variant="filled"
          value={formValues.eventDurationMinutes}
          error={Boolean(eventDurationError)}
          helperText={eventDurationError}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          limitTags={1}
          id="electrolyzer"
          disabled={disabled}
          options={electrolyzerSelectOpts}
          getOptionLabel={(electrolyzer) => electrolyzer.label || ''}
          isOptionEqualToValue={(option, value) =>
            option.value === value.value
          }
          value={
            electrolyzerSelectOpts.find(
              ({ value }) => formValues.electrolyzerId === value,
            ) || null
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Electrolyzer"
              variant="filled"
              error={!formValues.electrolyzerId}
              helperText={
                formValues.electrolyzerId
                  ? ''
                  : 'This is a required field'
              }
              value={
                electrolyzerSelectOpts.find(
                  ({ value }) => formValues.electrolyzerId === value,
                ) || null
              }
              required
              inputProps={{
                ...params.inputProps,
                required: formValues.electrolyzerId === null,
              }}
            />
          )}
          ChipProps={{ sx: { height: 25 } }}
          onChange={(e, electrolyzer) => {
            setFormValues({
              ...formValues,
              electrolyzerId: electrolyzer
                ? electrolyzer.value
                : null,
            });
          }}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          id="type"
          options={Object.values(downtimeEventTypes)}
          getOptionLabel={(type) => type.label || ''}
          isOptionEqualToValue={(option, value) =>
            option.value === value.value
          }
          onChange={(e, type) =>
            setFormValues({
              ...formValues,
              type: type ? type.value : null,
            })
          }
          value={
            typeSelectOpts.find(
              ({ value }) => formValues.type === value,
            ) || null
          }
          disabled={disabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Type"
              value={
                typeSelectOpts.find(
                  (o) => o.value === formValues.type,
                ) || null
              }
              required
              variant="filled"
              inputProps={{
                ...params.inputProps,
                required: formValues.type === null,
              }}
              error={!formValues.type}
              helperText={
                formValues.type ? '' : 'This is a required field'
              }
            />
          )}
        />
      </Grid>
    </>
  );
};

export default PlanDowntimeEventFormFields;
