interface ErrorWithStatus {
  data: {
    status: number;
  };
}

function isUnauthorizedError(
  error: ErrorWithStatus | unknown,
): boolean {
  const errorWithStatus = error as ErrorWithStatus;
  return (
    errorWithStatus.data !== undefined &&
    errorWithStatus.data.status === 401
  );
}

export default isUnauthorizedError;
