import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';
import queryString from 'query-string';

import App from './App';
import { store, persistor } from './app/store';

import { PersistGate } from 'redux-persist/integration/react';

const { invitation, organization } = queryString.parse(
  window.location.search,
);

ReactDOM.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN as string}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
    redirectUri={window.location.origin}
    cacheLocation="localstorage"
    audience={process.env.REACT_APP_AUTH0_AUDIENCE as string}
    organization={organization as string}
    invitation={invitation as string}
    scope="read:current_user"
  >
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </Auth0Provider>,
  document.getElementById('root'),
);
