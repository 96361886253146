import { Grid, Typography, List, ListItem } from '@mui/material';

import AddOfftaker from '../components/offtakers/AddOfftaker';

import { RootState } from '../app/store';
import { useAppSelector } from '../hooks/common';
import { getSortedOfftakers } from '../state/offtakers/offtakerSlice';

const Offtakers = () => {
  const offtakers = useAppSelector((state: RootState) =>
    getSortedOfftakers(state),
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          variant="h1"
          component="h1"
          gutterBottom
          align="center"
        >
          Offtakers
        </Typography>

        <AddOfftaker />
        <List>
          {offtakers.map((offtaker) => (
            <ListItem key={offtaker.abbreviation}>
              {offtaker.name}
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default Offtakers;
