import React from 'react';
import dayjs from 'dayjs';
import * as R from 'ramda';
import { useTheme } from '@mui/material/styles';
import {
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  TooltipProps,
  ComposedChart,
} from 'recharts';
import Alert from '@mui/material/Alert';
import { useAppSelector } from '../../../hooks/common';
import {
  getFetchLatestForecastState,
  getLatestLandfillGasPowerChartData,
} from '../../../state/forecasts/forecastsSlice';
import { RequestStatuses } from '../../../state/requestTypes';
import { getXAxisValuesForForecastViewWindow } from '../../../state/charts/chartsSlice';
import fullWidthChartStyles from '../../common/fullWidthChartStyles';
import ChartTooltip from '../../common/ChartTooltip';
import Errors from '../../common/Errors';

const blue = '#42a5f5';

interface Props {
  generationUnitId: string;
  forecastSourcePlatformId: string;
}

const renderTooltip = ({
  active,
  payload,
}: TooltipProps<number, string>) => {
  if (active && payload && payload.length) {
    const {
      lfgExportMw,
      pencePerKwh,
      periodStartLabel,
      periodEndLabel,
    } = payload[0].payload;

    return (
      <ChartTooltip>
        <p className="label">
          {lfgExportMw} MW at {pencePerKwh} pence/kWh
          <br />
          between {periodStartLabel} and {periodEndLabel}
        </p>
      </ChartTooltip>
    );
  }
  return <></>;
};

const LandfillGasPowerForecastChart = ({
  generationUnitId,
  forecastSourcePlatformId,
}: Props) => {
  const theme = useTheme();

  const forecastData = useAppSelector((state) =>
    getLatestLandfillGasPowerChartData(
      state,
      generationUnitId,
      forecastSourcePlatformId,
    ),
  );

  const forecastFetchState = useAppSelector((state) =>
    getFetchLatestForecastState(state),
  );

  const xAxisValues = useAppSelector(
    getXAxisValuesForForecastViewWindow,
  );

  if (!xAxisValues) {
    return (
      <Alert severity="error">Unable to format chart data</Alert>
    );
  }

  if (forecastFetchState.status === RequestStatuses.rejected) {
    return <Errors errors={forecastFetchState.errors} />;
  }

  if (!forecastData) return <></>;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        {...fullWidthChartStyles({ hasRightAxis: true })}
        data={forecastData}
      >
        <CartesianGrid
          strokeWidth={0.5}
          strokeDasharray="2 2"
          stroke={theme.palette.grey[500]}
        />
        <XAxis
          domain={[xAxisValues[0], R.last(xAxisValues) as number]}
          type="number"
          ticks={xAxisValues}
          dataKey="time"
          allowDataOverflow={true}
          stroke={theme.palette.grey[300]}
          tickFormatter={(date) => dayjs(date).format('HH:mm ddd')}
          tickMargin={6}
        />
        <YAxis stroke={theme.palette.grey[300]} />
        <YAxis
          yAxisId="pencePerKwhAxis"
          stroke={theme.palette.grey[300]}
          orientation="right"
        />
        <Legend />
        <Tooltip
          content={renderTooltip}
          wrapperStyle={{ outline: 'none' }}
        />
        <Line
          name="Landfill Gas Power (MW)"
          dataKey="lfgExportMw"
          stroke={blue}
          dot={false}
          activeDot={{ fill: blue }}
          strokeWidth={2}
        />
        <Line
          name="Price (pence/kWh)"
          dataKey="pencePerKwh"
          yAxisId="pencePerKwhAxis"
          stroke={theme.palette.warning.light}
          dot={false}
          activeDot={{ fill: theme.palette.warning.light }}
          strokeWidth={2}
          type="stepAfter"
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default LandfillGasPowerForecastChart;
