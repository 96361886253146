import { useState } from 'react';
import Button from '@mui/material/Button';
import PlanEventTimesModal from './PlanEventTimesModal';
import { useAppDispatch } from '../../../hooks/common';
import { resetPlanEventState } from '../../../state/planEvent/planEventSlice';
import { clearAddEventsStatus } from '../../../state/events/eventSlice';

interface Props {
  generationUnitId: string;
}

const PlanEventTimes = ({ generationUnitId }: Props) => {
  const dispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setModalOpen(false);
    dispatch(resetPlanEventState());
    dispatch(clearAddEventsStatus());
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => setModalOpen(true)}
      >
        Plan Event
      </Button>
      <PlanEventTimesModal
        generationUnitId={generationUnitId}
        open={modalOpen}
        handleClose={handleClose}
      />
    </>
  );
};

export default PlanEventTimes;
