import dayjs from 'dayjs';

export const FORECAST_INTERVAL_MINUTES = 30;
export const ROLLING_SCHEDULE_INTERVAL_MINUTES = 15;

export type ChartXAxisValues = number[];

function getDates(
  startDate: number,
  stopDate: number,
  intervalMinutes: number,
): ChartXAxisValues {
  const dateArray = [];
  let currentDate = dayjs(startDate);

  while (
    currentDate.isBefore(stopDate) ||
    currentDate.isSame(stopDate)
  ) {
    dateArray.push(dayjs(currentDate).valueOf());
    currentDate = currentDate.add(intervalMinutes, 'minutes');
  }

  return dateArray;
}

const startOfInterval = (
  date: string,
  intervalMilliseconds: number,
) =>
  Math.floor(dayjs(date).valueOf() / intervalMilliseconds) *
  intervalMilliseconds;

const endOfInterval = (date: string, intervalMilliseconds: number) =>
  Math.ceil(dayjs(date).valueOf() / intervalMilliseconds) *
  intervalMilliseconds;

const chartXAxisValuesCreator = (
  windowStart: string,
  windowEnd: string,
  intervalMinutes: number,
): ChartXAxisValues => {
  const intervalMilliseconds = intervalMinutes * 60000;
  const roundedDownWindowStart = startOfInterval(
    windowStart,
    intervalMilliseconds,
  );
  const roundedUpWindowEnd = endOfInterval(
    windowEnd,
    intervalMilliseconds,
  );
  return getDates(
    roundedDownWindowStart,
    roundedUpWindowEnd,
    intervalMinutes,
  );
};

export default chartXAxisValuesCreator;
