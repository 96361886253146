import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import { utcToLocalDate } from '../common/dateConverter';
import { useAppSelector } from '../../hooks/common';
import { getSelectedGenerationUnitId } from '../../state/generationUnits/generationUnitsSlice';
import PowerCostFormDatePicker from './PowerCostFormDatePicker';
import { selectedUnitHasIncentiveConfigured } from '../../state/generationUnits/generationUnitsSlice';

export interface FormValues {
  h2QuantityKg: string;
  start: string;
  deadline: string;
  incentiveEligibleMassKg: string;
}

const initialFormValues = (showIncentivisedQuantity: boolean) => ({
  h2QuantityKg: '',
  start: utcToLocalDate().add(1, 'hour').format('YYYY-MM-DDTHH:mm'),
  deadline: utcToLocalDate()
    .add(3, 'days')
    .format('YYYY-MM-DDTHH:mm'),
  incentiveEligibleMassKg: '',
});

interface Props {
  isLoading: boolean;
  handleSubmit: (formValues: FormValues) => void;
}

const PowerCostEstimatorForm = ({
  handleSubmit,
  isLoading,
}: Props) => {
  const selectedGenerationUnitId = useAppSelector(
    getSelectedGenerationUnitId,
  );

  const showIncentivisedQuantity = useAppSelector(
    selectedUnitHasIncentiveConfigured,
  );

  const [formValues, setFormValues] = useState<FormValues>(
    initialFormValues(showIncentivisedQuantity),
  );

  if (!selectedGenerationUnitId) return <></>;

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(formValues);
      }}
    >
      <Stack direction="column" spacing={2}>
        <TextField
          fullWidth
          id="h2-quantity-kg"
          required
          type="number"
          onInput={(e) =>
            setFormValues({
              ...formValues,
              h2QuantityKg: (e.target as HTMLTextAreaElement).value,
            })
          }
          label="Amount of H₂ to be produced (kg)"
          variant="filled"
          value={formValues.h2QuantityKg}
          data-testid="target-h2-mass"
        />
        {showIncentivisedQuantity && (
          <TextField
            fullWidth
            id="incentiveEligibleMassKg"
            type="number"
            required
            onInput={(e) =>
              setFormValues({
                ...formValues,
                incentiveEligibleMassKg: (
                  e.target as HTMLTextAreaElement
                ).value,
              })
            }
            label="Incentive eligible quantity (kg)"
            variant="filled"
            value={formValues.incentiveEligibleMassKg}
          />
        )}
        <PowerCostFormDatePicker
          startValue={formValues.start}
          setStartValue={(value) =>
            setFormValues({
              ...formValues,
              start: value,
            })
          }
          deadlineValue={formValues.deadline}
          setDeadlineValue={(value) =>
            setFormValues({
              ...formValues,
              deadline: value,
            })
          }
        />
        <Button type="submit" variant="outlined" color="primary">
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            'Calculate cost'
          )}
        </Button>
      </Stack>
    </form>
  );
};

export default PowerCostEstimatorForm;
