import { FormEvent } from 'react';
import { Button, CircularProgress, Grid } from '@mui/material';

interface Props {
  isLoading?: boolean;
  disableBackButton: boolean;
  disableSubmitButton: boolean;
  handleBack: () => void;
  handleClose: () => void;
  handleSubmit?: (e: FormEvent) => Promise<void>;
  submitButtonText: string;
}

const BottomNav = ({
  isLoading = false,
  handleBack,
  handleClose,
  submitButtonText,
  disableBackButton,
  disableSubmitButton,
  handleSubmit,
}: Props) => {
  return (
    <Grid container direction="row">
      <Grid item xs={3}>
        <Button
          color="inherit"
          disabled={disableBackButton}
          onClick={handleBack}
        >
          Back
        </Button>
        <Button
          color="inherit"
          onClick={handleClose}
          disabled={isLoading}
        >
          Close
        </Button>
      </Grid>
      <Grid item xs={9}>
        <Grid container justifyContent="flex-end">
          <Button
            disabled={disableSubmitButton}
            onClick={handleSubmit}
          >
            {isLoading ? (
              <CircularProgress size={20} />
            ) : (
              submitButtonText
            )}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BottomNav;
