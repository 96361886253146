import { Chip, Typography, Grid } from '@mui/material';
import { MouseEventHandler } from 'react';
import { useAppSelector } from '../../../hooks/common';
import { hasRenewableIncentiveConfigured } from '../../../state/generationUnits/generationUnitsSlice';
import EventAccordion from '../EventAccordion';
import {
  EventStatus,
  TransformedOfftakeEventViewModel,
} from '../../../state/events/eventTypes';
import EventRowActionBtn from '../EventRowActionBtn';
import { H2MeasurementUnit } from '../../../common/types';
import { hasStaticStorageUnit } from '../../../state/storageUnits/storageUnitSlice';
import {
  isDiscarded,
  isDeleteable,
  isEditable,
} from '../helpers/statusHelpers';
import { getOfftakerById } from '../../../state/offtakers/offtakerSlice';

interface Props {
  event: TransformedOfftakeEventViewModel;
  handleDelete: MouseEventHandler;
  isPendingDeletion: boolean;
  onClickStart: MouseEventHandler;
  onClickEdit: MouseEventHandler;
  handleEnd: MouseEventHandler;
  isPendingEnd: boolean;
  valuesUnit: H2MeasurementUnit;
}

const OfftakeEventRow = ({
  event,
  event: {
    status,
    h2Quantity,
    incentivisedQuantity,
    generationUnitId,
    h2IsRequiredUpFront,
  },
  handleDelete,
  isPendingDeletion,
  onClickStart,
  onClickEdit,
  handleEnd,
  isPendingEnd,
  valuesUnit,
}: Props) => {
  const offtaker = useAppSelector((state) =>
    getOfftakerById(state, event.offtakerId),
  );

  const eventIsDiscarded = isDiscarded(status);
  const eventIsDeleteable = isDeleteable(status);
  const eventIsEditable = isEditable(status);

  const canStart = [
    EventStatus.CONFIRMED,
    EventStatus.NEVER_STARTED,
  ].includes(status);
  const hasStarted = status === EventStatus.STARTED;

  const showIncentivisedQuantity = useAppSelector((state) =>
    hasRenewableIncentiveConfigured(state, generationUnitId),
  );

  const hasStaticStorage = useAppSelector((state) =>
    hasStaticStorageUnit(state, generationUnitId),
  );

  return (
    <EventAccordion
      event={event}
      isDiscarded={eventIsDiscarded}
      isDeleteable={eventIsDeleteable}
      isEditable={eventIsEditable}
      isPendingDeletion={isPendingDeletion}
      handleDelete={handleDelete}
      onClickEdit={onClickEdit}
      eventTypeChip={<Chip label="Offtake" size="small" />}
      eventTypeSpecificInfo={
        offtaker ? offtaker.abbreviation : 'Not found'
      }
      eventTypeSpecificActionBtn={
        <EventRowActionBtn
          showButton={canStart || hasStarted}
          showEnd={hasStarted}
          onClickStart={onClickStart}
          onClickEnd={handleEnd}
          isPendingEnd={isPendingEnd}
        />
      }
    >
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            component="span"
            fontWeight="bold"
          >
            Quantity:&nbsp;
          </Typography>
          <Typography variant="body1" component="span">
            {h2Quantity}
            {valuesUnit}
          </Typography>
        </Grid>
        {showIncentivisedQuantity && (
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              component="span"
              fontWeight="bold"
            >
              Incentivised Quantity:&nbsp;
            </Typography>
            <Typography variant="body1" component="span">
              {incentivisedQuantity}
              {valuesUnit}
            </Typography>
          </Grid>
        )}
        {hasStaticStorage && (
          <Grid item xs={12}>
            <Typography
              variant="subtitle2"
              component="span"
              fontWeight="bold"
            >
              Ensure H₂ produced before start:&nbsp;
            </Typography>
            <Typography variant="body1" component="span">
              {String(h2IsRequiredUpFront)}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography
            variant="subtitle2"
            component="span"
            fontWeight="bold"
          >
            Offtaker:&nbsp;
          </Typography>
          <Typography variant="body1" component="span">
            {offtaker
              ? `${offtaker.name} (${offtaker.abbreviation})`
              : 'Not found'}
          </Typography>
        </Grid>
      </Grid>
    </EventAccordion>
  );
};

export default OfftakeEventRow;
