import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { EventType } from '../../../../state/events/eventTypes';
import { useAppSelector } from '../../../../hooks/common';
import { getElectrolyzerDisplayNameById } from '../../../../state/electrolyzers/electrolyzerSlice';
import { DowntimeEventToCreate } from '../../../../state/planEvent/planEventTypes';

interface Props {
  event: DowntimeEventToCreate;
}

const DowntimeEventDetails = ({ event }: Props) => {
  const electrolyzerName = useAppSelector((state) =>
    getElectrolyzerDisplayNameById(state, event.electrolyzerId),
  );

  return (
    <Grid container direction="row" spacing={0.5}>
      <Grid item xs={12}>
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          component="span"
        >
          Event type:&nbsp;
        </Typography>
        <Typography variant="body1" component="span">
          {EventType.DOWNTIME}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          component="span"
        >
          Start:&nbsp;
        </Typography>
        <Typography variant="body1" component="span">
          {dayjs(event.expectedStart).format('h:mm a on ddd D MMMM')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          component="span"
        >
          End:&nbsp;
        </Typography>
        <Typography variant="body1" component="span">
          {dayjs(event.expectedEnd).format('h:mm a on ddd D MMMM')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          component="span"
        >
          Electrolyzer:&nbsp;
        </Typography>
        <Typography variant="body1" component="span">
          {electrolyzerName}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          component="span"
        >
          Type:&nbsp;
        </Typography>
        <Typography variant="body1" component="span">
          {event.type}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DowntimeEventDetails;
