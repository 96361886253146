export const enum ForecastSourceType {
  SOLAR = 'solar',
  GRID_PRICE = 'grid_price',
  LANDFILL_GAS_POWER = 'landfill_gas_power',
}

export interface ForecastSourceViewModel {
  id: string;
  platformId: string;
  name: string;
  type: string;
  createdAt: string;
}

export interface NewForecastSource {
  platformId: string;
  name: string;
  type: string;
}

export interface ForecastSourcesViewModel {
  [key: string]: ForecastSourceViewModel;
}

export interface ForecastSourceOptionsViewModel {
  [key: string]: string;
}
