import { Alert } from '@mui/material';
import { DetailedErrorMessage } from '../../state/common/errorTypes';

interface Props {
  errors: DetailedErrorMessage[] | undefined;
}

export default function Errors({ errors }: Props) {
  return (
    <>
      {errors &&
        errors.map((error, index) => (
          <Alert
            sx={{ padding: '0.1em' }}
            key={index}
            severity="error"
          >
            {error.message}
          </Alert>
        ))}
    </>
  );
}
