import React from 'react';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';

import ElectrolyzerSpecsModal from './ElectrolyzerSpecsModal';

interface Props {
  electrolyzerId: string;
}

const ElectrolyzerSpecs = ({ electrolyzerId }: Props) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Grid container justifyContent="flex-end">
      <Tooltip title="View technical specs">
        <IconButton size="small" onClick={() => setOpen(true)}>
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <ElectrolyzerSpecsModal
        open={open}
        handleClose={() => setOpen(false)}
        electrolyzerId={electrolyzerId}
      />
    </Grid>
  );
};

export default ElectrolyzerSpecs;
