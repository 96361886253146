import { FormEvent, useState } from 'react';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import SelectedEventDetails from './SelectedEventDetails';
import SelectEventList from './SelectEventList';
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../hooks/common';
import { getBestEventTimeOptions } from '../../../../state/planEvent/planEventSlice';
import {
  addEventsAsync,
  getAddEventsState,
} from '../../../../state/events/eventSlice';
import Errors from '../../../common/Errors';
import { fetchRollingScheduleByGenerationUnitIdAsync } from '../../../../state/rollingSchedules/rollingSchedulesSlice';
import { rollingScheduleApi } from '../../../../app/services/rollingSchedule';
import parseRequestState from '../../../../state/common/requestStateParser';
import BottomNav from '../BottomNav';
import { EventType } from '../../../../state/events/eventTypes';

interface Props {
  generationUnitId: string;
  handleBack: () => void;
  handleClose: () => void;
  selectedEventType: EventType;
}

const SelectEventStep = ({
  handleClose,
  handleBack,
  generationUnitId,
  selectedEventType,
}: Props) => {
  const dispatch = useAppDispatch();
  const addEventsState = useAppSelector(getAddEventsState);
  const { isPending, isFulfilled } =
    parseRequestState(addEventsState);
  const [checkedOption, setChecked] = useState<number>(0);
  const eventOptions = useAppSelector(getBestEventTimeOptions);

  if (!eventOptions || eventOptions.length === 0) {
    return (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Alert severity="info">
            We couldn't find any valid times for this event. Please go
            back and try again.
          </Alert>
        </Grid>
        <Grid item>
          <BottomNav
            handleBack={handleBack}
            handleClose={handleClose}
            submitButtonText="Create event"
            disableBackButton={false}
            disableSubmitButton={true}
          />
        </Grid>
      </Grid>
    );
  }

  const { initiate: fetchRollingScheduleStatus } =
    rollingScheduleApi.endpoints.getRollingScheduleStatus;
  const handleCreateEvent = async (e: FormEvent) => {
    e.preventDefault();
    const { expectedStart, expectedEnd, electrolyzerId, type } =
      eventOptions[checkedOption].eventsToCreate[0];
    await dispatch(
      addEventsAsync({
        generationUnitId,
        newEvents: {
          offtakeEvents: [],
          downtimeEvents: [
            { expectedStart, expectedEnd, electrolyzerId, type },
          ],
        },
      }),
    );

    await dispatch(
      fetchRollingScheduleByGenerationUnitIdAsync(generationUnitId),
    );
    await dispatch(
      fetchRollingScheduleStatus(generationUnitId, {
        forceRefetch: true,
      }),
    );
  };

  const multipleOptionsFound = eventOptions.length > 1;
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <Alert severity="info">
          We've found {eventOptions.length} option
          {multipleOptionsFound ? 's' : ''} for this event.
          {multipleOptionsFound
            ? ' Please select the one that works best for you.'
            : ''}
        </Alert>
      </Grid>
      <Grid item>
        <SelectEventList
          eventOptions={eventOptions}
          handleCheck={(value: number) => () => {
            setChecked(value);
          }}
          checkedOption={checkedOption}
        />
      </Grid>
      <Grid item>
        <SelectedEventDetails
          eventOption={eventOptions[checkedOption]}
        />
      </Grid>
      <Grid item>
        {isFulfilled && (
          <Alert severity="success">
            Successfully created {selectedEventType} event
          </Alert>
        )}
        <Errors errors={addEventsState.errors} />
      </Grid>
      <Grid item>
        <BottomNav
          isLoading={isPending}
          handleBack={handleBack}
          submitButtonText="Create event"
          handleSubmit={handleCreateEvent}
          handleClose={handleClose}
          disableBackButton={isFulfilled}
          disableSubmitButton={isFulfilled}
        />
      </Grid>
    </Grid>
  );
};

export default SelectEventStep;
