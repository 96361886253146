import React from 'react';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';

import EdgeDeviceSpecsModal from './EdgeDeviceSpecsModal';

interface Props {
  edgeDeviceId: string;
}

const EdgeDeviceSpecs = ({ edgeDeviceId }: Props) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Grid container justifyContent="flex-end">
      <Tooltip title="View technical specs">
        <IconButton size="small" onClick={() => setOpen(true)}>
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <EdgeDeviceSpecsModal
        open={open}
        handleClose={() => setOpen(false)}
        edgeDeviceId={edgeDeviceId}
      />
    </Grid>
  );
};

export default EdgeDeviceSpecs;
