import { handleApiError } from '../common/apiErrorHandler';
import { ForecastsViewModel } from './forecastsTypes';

const agent = require('axios');

interface FetchLastestForecastsByGenUnitResponse {
  data: { forecasts: ForecastsViewModel };
}

export async function fetchLatestForecastByGenUnit(
  generationUnitId: string,
) {
  return await handleApiError<{ data: ForecastsViewModel }>(
    () =>
      agent
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/generation-units/${generationUnitId}/forecasts`,
        )
        .then(({ data }: FetchLastestForecastsByGenUnitResponse) => {
          return {
            data,
          };
        }),
    'Failed to fetch latest forecasts',
  );
}
