import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { createCachedSelector } from 're-reselect';

import dayjs from 'dayjs';

import * as R from 'ramda';

import { RootState } from '../../app/store';
import { fetchLatestForecastByGenUnit } from './forecastsAPI';
import { RequestState, RequestStatuses } from '../requestTypes';
import {
  ForecastsViewModel,
  ForecastViewModel,
  GridPriceForecastChartData,
  GridPriceForecastData,
  LandfillGasPowerForecastChartData,
  LandfillGasPowerForecastData,
  SolarForecastChartData,
  SolarForecastData,
} from './forecastsTypes';
import { ForecastSourceType } from '../forecastSources/forecastSourceTypes';
import { RejectValue } from '../common/errorTypes';
import { executeWithRejectValue } from '../common/executeWithRejectValue';
import roundToTwoDp from '../common/rounder';

interface ForecastsState {
  entities: { [key: string]: ForecastsViewModel } | {};
  fetchLatestState: RequestState;
}

const initialState: ForecastsState = {
  entities: {},
  fetchLatestState: {
    status: RequestStatuses.idle,
    errors: [],
  },
};

export const fetchLatestForecastsByGenUnitIdAsync = createAsyncThunk<
  ForecastsViewModel,
  string,
  RejectValue
>(
  'forecasts/fetchLatestForecastsByGenUnitId',
  async (generationUnitId: string, { rejectWithValue }) => {
    return await executeWithRejectValue(async () => {
      const response = await fetchLatestForecastByGenUnit(
        generationUnitId,
      );
      return response.data;
    }, rejectWithValue);
  },
);

export const forecastsSlice = createSlice({
  name: 'forecasts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchLatestForecastsByGenUnitIdAsync.pending,
        (state) => {
          state.fetchLatestState = {
            status: RequestStatuses.pending,
            errors: [],
          };
        },
      )
      .addCase(
        fetchLatestForecastsByGenUnitIdAsync.fulfilled,
        (state, action: PayloadAction<ForecastsViewModel>) => {
          if (action.payload) {
            state.entities = {
              ...state.entities,
              [action.payload.generationUnitId]: action.payload,
            };
          }

          state.fetchLatestState = {
            status: RequestStatuses.fulfilled,
            errors: [],
          };
        },
      )
      .addCase(
        fetchLatestForecastsByGenUnitIdAsync.rejected,
        (state, action) => {
          state.fetchLatestState = {
            status: RequestStatuses.rejected,
            errors: action.payload?.errors,
          };
        },
      );
  },
});

const transformSolarForecastData = (
  forecastData: SolarForecastData,
): SolarForecastChartData =>
  forecastData.map(
    ({
      pvEstimate,
      periodEnd,
      period,
      pvEstimate90,
      pvEstimate10,
      pencePerKwh,
    }) => {
      const periodDuration = dayjs.duration(period);
      const halfPeriodDuration = periodDuration.asMilliseconds() / 2;
      const periodMiddleValue = dayjs(periodEnd)
        .subtract(halfPeriodDuration)
        .valueOf();

      const periodStartLabel = dayjs(periodEnd)
        .subtract(periodDuration)
        .format('HH:mm DD/MM');

      const periodEndLabel = dayjs(periodEnd).format('HH:mm DD/MM');

      return {
        pvEstimate: roundToTwoDp(pvEstimate),
        pvEstimateRange: [pvEstimate10, pvEstimate90],
        periodStartLabel,
        periodEndLabel,
        time: periodMiddleValue,
        pencePerKwh,
      };
    },
  );

const transformGridPriceForecastData = (
  forecastData: GridPriceForecastData,
): GridPriceForecastChartData =>
  forecastData.map(
    ({ consumptionPrice, periodStart, periodEnd }) => ({
      price: roundToTwoDp(consumptionPrice),
      periodStart: dayjs(periodStart).valueOf(),
      periodStartLabel: dayjs(periodStart).format('HH:mm DD/MM'),
      periodEndLabel: dayjs(periodEnd).format('HH:mm DD/MM'),
    }),
  );

const transformLandfillGasPowerForecastData = (
  forecastData: LandfillGasPowerForecastData,
): LandfillGasPowerForecastChartData =>
  forecastData.map(
    ({ periodStart, periodEnd, lfgExportKw, pencePerKwh }) => {
      const halfPeriodDuration =
        dayjs
          .duration(dayjs(periodEnd).diff(dayjs(periodStart)))
          .asMilliseconds() / 2;
      const periodMiddleValue = dayjs(periodEnd)
        .subtract(halfPeriodDuration)
        .valueOf();

      return {
        lfgExportMw: roundToTwoDp(lfgExportKw / 1000),
        pencePerKwh,
        periodStartLabel: dayjs(periodStart).format('HH:mm DD/MM'),
        periodEndLabel: dayjs(periodEnd).format('HH:mm DD/MM'),
        time: periodMiddleValue,
      };
    },
  );

const getForecastByTypeAndPlatformId = (
  forecasts: Array<ForecastViewModel>,
  forecastType: ForecastSourceType,
  forecastSourcePlatformId: string,
) =>
  forecasts.find(
    (f) =>
      f.type === forecastType &&
      f.forecastSourcePlatformId === forecastSourcePlatformId,
  );

const selectLatestForecasts = (
  state: RootState,
  generationUnitId: string,
) =>
  R.find<ForecastsViewModel>(
    R.propEq('generationUnitId', generationUnitId),
  )(Object.values(state.forecasts.entities) as ForecastsViewModel[]);

const getForecastSourcePlatformId = (
  _: RootState,
  _generationUnitId: string,
  forecastSourcePlatformId: string,
) => forecastSourcePlatformId;

const getForecastSourceType = (
  _: RootState,
  _generationUnitId: string,
  _forecastSourcePlatformId: string,
  forecastSourceType: ForecastSourceType,
) => forecastSourceType;

export const getLatestSolarForecastChartData = createCachedSelector(
  selectLatestForecasts,
  getForecastSourcePlatformId,
  getForecastSourceType,
  (
    forecasts: ForecastsViewModel | undefined,
    forecastSourcePlatformId: string,
  ) => {
    if (forecasts) {
      const forecast = getForecastByTypeAndPlatformId(
        forecasts.forecasts,
        ForecastSourceType.SOLAR,
        forecastSourcePlatformId,
      );

      if (!forecast) {
        return null;
      }

      return transformSolarForecastData(
        forecast.forecast as SolarForecastData,
      );
    }
    return null;
  },
)((_state_, genUnitId) => genUnitId);

export const getLatestGridPriceForecastChartData =
  createCachedSelector(
    selectLatestForecasts,
    getForecastSourcePlatformId,
    getForecastSourceType,
    (
      forecasts: ForecastsViewModel | undefined,
      forecastSourcePlatformId: string,
    ) => {
      if (forecasts) {
        const forecast = getForecastByTypeAndPlatformId(
          forecasts.forecasts,
          ForecastSourceType.GRID_PRICE,
          forecastSourcePlatformId,
        );

        if (!forecast) {
          return null;
        }

        return transformGridPriceForecastData(
          forecast.forecast as GridPriceForecastData,
        );
      }
      return null;
    },
  )((_state_, genUnitId) => genUnitId);

export const getLatestLandfillGasPowerChartData =
  createCachedSelector(
    selectLatestForecasts,
    getForecastSourcePlatformId,
    getForecastSourceType,
    (
      forecasts: ForecastsViewModel | undefined,
      forecastSourcePlatformId: string,
    ) => {
      if (forecasts) {
        const forecast = getForecastByTypeAndPlatformId(
          forecasts.forecasts,
          ForecastSourceType.LANDFILL_GAS_POWER,
          forecastSourcePlatformId,
        );

        if (!forecast) {
          return null;
        }

        return transformLandfillGasPowerForecastData(
          forecast.forecast as LandfillGasPowerForecastData,
        );
      }
      return null;
    },
  )((_state_, genUnitId) => genUnitId);

export const getForecastCreationTime = (
  state: RootState,
  generationUnitId: string,
  forecastSourcePlatformId: string,
  forecastSourceType: ForecastSourceType,
): string | null => {
  const forecasts = selectLatestForecasts(state, generationUnitId);

  if (!forecasts) {
    return null;
  }

  const forecast = getForecastByTypeAndPlatformId(
    forecasts.forecasts,
    forecastSourceType,
    forecastSourcePlatformId,
  );
  if (!forecast) {
    return null;
  }

  return forecast.createdAt;
};

export const getFetchLatestForecastState = (
  state: RootState,
): RequestState => state.forecasts.fetchLatestState;

export default forecastsSlice.reducer;
