import React from 'react';
import FormControl from '@mui/material/FormControl';
import { useAppDispatch, useAppSelector } from '../../hooks/common';
import {
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { getUserValuesUnit } from '../../state/user/userSlice';
import { userActions } from '../../state/user/userSlice';

const DisplayValuesSelectForm = () => {
  const userValuesUnit = useAppSelector(getUserValuesUnit);

  const saveValuesUnitPreference = (changeEvent: any) => {
    dispatch(
      userActions.updateUserValuesUnit(changeEvent.target.value),
    );
  };

  const dispatch = useAppDispatch();

  return (
    <>
      <FormLabel>Display values in: &nbsp;</FormLabel>
      <FormControl>
        <RadioGroup
          row
          name="user-display-value-preferences"
          sx={{ display: 'inline' }}
        >
          <FormControlLabel
            value="kg"
            control={
              <Radio
                checked={userValuesUnit === 'kg'}
                onChange={saveValuesUnitPreference}
              />
            }
            label="kg"
          />
          <FormControlLabel
            value="nm3"
            control={
              <Radio
                checked={userValuesUnit === 'nm3'}
                onChange={saveValuesUnitPreference}
              />
            }
            label="nm³"
          />
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default DisplayValuesSelectForm;
