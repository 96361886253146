import React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Logo from './images/white_logo.png';

interface Props {
  handleLogin: () => void;
}

const RootDiv = styled('div')(() => ({
  display: 'flex',
}));

const Content = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  height: '100vh',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Img = styled('img')(() => ({
  height: '60px',
  marginBottom: '20px',
}));

const LoggedOutView = ({ handleLogin }: Props) => (
  <RootDiv>
    <Content>
      <Img alt="Octopus Hydrogen Logo" src={Logo} />
      <Button size="large" onClick={handleLogin}>
        Log in
      </Button>
    </Content>
  </RootDiv>
);

export default LoggedOutView;
