import { handleApiError } from '../common/apiErrorHandler';
import {
  EdgeDeviceViewModel,
  EdgeDevicesViewModel,
  NewEdgeDevice,
} from './edgeDeviceTypes';

const agent = require('axios');

interface FetchEdgeDevicesResponse {
  data: { edgeDevices: EdgeDevicesViewModel };
}

export async function fetchEdgeDevices() {
  return await handleApiError<{ data: EdgeDevicesViewModel }>(
    () =>
      agent
        .get(`${process.env.REACT_APP_BACKEND_URL}/edge-devices`)
        .then(({ data }: FetchEdgeDevicesResponse) => ({
          data: data.edgeDevices,
        })),
    'Failed to fetch edge devices',
  );
}

interface AddEdgeDeviceResponse {
  data: { edgeDevice: EdgeDeviceViewModel };
}

export async function addEdgeDevice(newEdgeDevice: NewEdgeDevice) {
  return await handleApiError<{ data: EdgeDeviceViewModel }>(
    () =>
      agent
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/edge-devices`,
          newEdgeDevice,
        )
        .then(({ data }: AddEdgeDeviceResponse) => ({
          data: data.edgeDevice,
        })),
    'Failed to add edge device',
  );
}
