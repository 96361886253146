import React from 'react';
import { ModalContents, ModalForm } from '../common/modalStyles';
import ModalTitle from '../common/ModalTitle';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import OfftakeEventFields, {
  OfftakeEventFormValues,
} from './offtake/OfftakeEventFields';
import DowntimeEventFields, {
  DowntimeEventFormValues,
} from './downtime/DowntimeEventFields';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from '../../hooks/common';
import {
  addEventsAsync,
  clearAddEventsStatus,
  getAddEventsState,
} from '../../state/events/eventSlice';
import { RequestStatuses } from '../../state/requestTypes';
import Alert from '@mui/material/Alert';
import {
  DowntimeEventType,
  downtimeEventTypes,
} from '../../state/events/eventTypes';
import { getOfftakersSelectOpts } from '../../state/offtakers/offtakerSlice';
import CircularProgress from '@mui/material/CircularProgress';
import { AddOutlined } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { Box, IconButton, Typography } from '@mui/material';
import * as R from 'ramda';
import ClearIcon from '@mui/icons-material/Clear';
import Grid from '@mui/material/Grid';
import { getElectrolyzerSelectOptsByGenerationUnitId } from '../../state/electrolyzers/electrolyzerSlice';
import { fetchRollingScheduleByGenerationUnitIdAsync } from '../../state/rollingSchedules/rollingSchedulesSlice';
import { hasRenewableIncentiveConfigured } from '../../state/generationUnits/generationUnitsSlice';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { hasStaticStorageUnit } from '../../state/storageUnits/storageUnitSlice';
import { rollingScheduleApi } from '../../app/services/rollingSchedule';

interface Props {
  generationUnitId: string;
}

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
}));

const initialOfftake: OfftakeEventFormValues = {
  expectedStart: dayjs().add(1, 'hour').startOf('hour').toDate(),
  expectedEnd: dayjs().add(2, 'hour').startOf('hour').toDate(),
  offtakerId: '',
  h2QuantityKg: '',
  renewableIncentiveEligibleMassKg: '',
  h2IsRequiredUpFront: false,
};

const initialDowntime: DowntimeEventFormValues = {
  expectedStart: dayjs().add(1, 'hour').startOf('hour').toDate(),
  expectedEnd: dayjs().add(2, 'hour').startOf('hour').toDate(),
  type: DowntimeEventType.MAINTENANCE,
  electrolyzerId: null,
};

export default function AddEvents({ generationUnitId }: Props) {
  const initialFormValues: {
    offtakeEvents: OfftakeEventFormValues[];
    downtimeEvents: DowntimeEventFormValues[];
  } = {
    offtakeEvents: [],
    downtimeEvents: [],
  };

  const dispatch = useAppDispatch();
  const requestState = useAppSelector(getAddEventsState);
  const offtakersSelectOpts = useAppSelector(getOfftakersSelectOpts);

  const { initiate: fetchRollingScheduleStatus } =
    rollingScheduleApi.endpoints.getRollingScheduleStatus;

  const typeSelectOpts = Object.values(downtimeEventTypes);

  const electrolyzerSelectOpts = useAppSelector((state) =>
    getElectrolyzerSelectOptsByGenerationUnitId(
      state,
      generationUnitId,
    ),
  );

  const hasRenewableIncentive = useAppSelector((state) =>
    hasRenewableIncentiveConfigured(state, generationUnitId),
  );

  const hasStaticStorage = useAppSelector((state) =>
    hasStaticStorageUnit(state, generationUnitId),
  );

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState('');
  const [eventsFormValues, setEventsFormValues] =
    React.useState(initialFormValues);

  const handleClose = () => {
    setOpen(false);
    dispatch(clearAddEventsStatus());
    setEventsFormValues(initialFormValues);
  };

  const handleSubmit = async () => {
    try {
      setError('');
      await dispatch(
        addEventsAsync({
          generationUnitId,
          newEvents: {
            offtakeEvents: eventsFormValues.offtakeEvents.map(
              (offtakeEventsFormValues) => ({
                ...offtakeEventsFormValues,
                h2QuantityKg: parseInt(
                  offtakeEventsFormValues.h2QuantityKg,
                  10,
                ),
                renewableIncentiveEligibleMassKg:
                  offtakeEventsFormValues.renewableIncentiveEligibleMassKg
                    ? parseInt(
                        offtakeEventsFormValues.renewableIncentiveEligibleMassKg,
                      )
                    : undefined,
                expectedStart: dayjs(
                  offtakeEventsFormValues.expectedStart,
                )
                  .second(0)
                  .toISOString(),
                expectedEnd: dayjs(
                  offtakeEventsFormValues.expectedEnd,
                )
                  .second(0)
                  .toISOString(),
              }),
            ),
            downtimeEvents: eventsFormValues.downtimeEvents.map(
              (downtimeEventFormValues) => ({
                electrolyzerId:
                  downtimeEventFormValues.electrolyzerId as string,
                type: downtimeEventFormValues.type as DowntimeEventType,
                expectedStart: dayjs(
                  downtimeEventFormValues.expectedStart,
                )
                  .second(0)
                  .toISOString(),
                expectedEnd: dayjs(
                  downtimeEventFormValues.expectedEnd,
                )
                  .second(0)
                  .toISOString(),
              }),
            ),
          },
        }),
      );
    } catch (error) {
      setError('Failed to submit events.');
      return;
    }

    await dispatch(
      fetchRollingScheduleByGenerationUnitIdAsync(generationUnitId),
    );

    await dispatch(
      fetchRollingScheduleStatus(generationUnitId, {
        forceRefetch: true,
      }),
    );
  };

  const setOfftakeFormValues =
    (formIndex: number) => (formValues: any) =>
      setEventsFormValues({
        ...eventsFormValues,
        offtakeEvents: R.update(
          formIndex,
          formValues,
          eventsFormValues.offtakeEvents,
        ),
      });

  const addOfftakeEventForm = () =>
    setEventsFormValues({
      ...eventsFormValues,
      offtakeEvents: eventsFormValues.offtakeEvents.concat([
        initialOfftake,
      ]),
    });

  const removeOfftakeEventForm = (formIndex: number) =>
    setEventsFormValues({
      ...eventsFormValues,
      offtakeEvents: R.remove(
        formIndex,
        1,
        eventsFormValues.offtakeEvents,
      ),
    });

  const setDowntimeFormValues =
    (formIndex: number) => (formValues: any) =>
      setEventsFormValues({
        ...eventsFormValues,
        downtimeEvents: R.update(
          formIndex,
          formValues,
          eventsFormValues.downtimeEvents,
        ),
      });

  const addDowntimeEventForm = () =>
    setEventsFormValues({
      ...eventsFormValues,
      downtimeEvents: eventsFormValues.downtimeEvents.concat([
        initialDowntime,
      ]),
    });

  const removeDowntimeEventForm = (formIndex: number) =>
    setEventsFormValues({
      ...eventsFormValues,
      downtimeEvents: R.remove(
        formIndex,
        1,
        eventsFormValues.downtimeEvents,
      ),
    });

  const isLoading = requestState.status === RequestStatuses.pending;
  const isFulfilled =
    requestState.status === RequestStatuses.fulfilled;
  const disabled = isLoading || isFulfilled;

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        Add Events
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby={'add-offtake-and-downtime'}
      >
        <ModalContents style={{ width: '80%', height: '80%' }}>
          <ModalTitle
            text="Add offtake and downtime"
            id="add-offtake-and-downtime-title"
          />
          <ModalForm
            onSubmit={(e) => {
              // Prevent the modal from closing automatically on submission
              e.preventDefault();
              return handleSubmit();
            }}
            autoComplete="off"
            style={{ maxWidth: '1100px' }}
          >
            <Card variant="outlined">
              <CardContent>
                <Typography
                  component="h4"
                  variant="h4"
                  sx={{ mt: 1, mb: 1 }}
                >
                  Offtake
                </Typography>
                <Grid container>
                  {eventsFormValues.offtakeEvents.length === 0 && (
                    <Typography
                      sx={{
                        fontStyle: 'italic',
                      }}
                    >
                      No offtake added
                    </Typography>
                  )}
                  {eventsFormValues.offtakeEvents.map(
                    (offtakeEventFormValues, idx) => (
                      <Grid
                        container
                        direction="row"
                        key={idx}
                        spacing={1}
                        sx={{ mb: 1 }}
                      >
                        <Grid item xs={11}>
                          <OfftakeEventFields
                            setFormValues={setOfftakeFormValues(idx)}
                            formValues={offtakeEventFormValues}
                            offtakersSelectOpts={offtakersSelectOpts}
                            disabled={disabled}
                            hasRenewableIncentive={
                              hasRenewableIncentive
                            }
                            hasStaticStorage={hasStaticStorage}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          {eventsFormValues.offtakeEvents.length >
                            0 && (
                            <IconButton
                              onClick={() =>
                                removeOfftakeEventForm(idx)
                              }
                              aria-label="delete offtake event form"
                              disabled={disabled}
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    ),
                  )}
                </Grid>
                <StyledButton
                  size="small"
                  startIcon={<AddOutlined />}
                  onClick={addOfftakeEventForm}
                  disabled={disabled}
                >
                  Add offtake
                </StyledButton>
              </CardContent>
            </Card>
            <Card variant="outlined">
              <CardContent>
                <Typography
                  component="h4"
                  variant="h4"
                  sx={{ mt: 1, mb: 1 }}
                >
                  Downtime
                </Typography>
                <Grid container>
                  {eventsFormValues.downtimeEvents.length === 0 && (
                    <Typography
                      sx={{
                        fontStyle: 'italic',
                      }}
                    >
                      No downtime added
                    </Typography>
                  )}
                  {eventsFormValues.downtimeEvents.map(
                    (downtimeEventFormValues, idx) => (
                      <Grid
                        container
                        direction="row"
                        key={idx}
                        spacing={1}
                        sx={{ mb: 1 }}
                      >
                        <DowntimeEventFields
                          setFormValues={setDowntimeFormValues(idx)}
                          formValues={downtimeEventFormValues}
                          typeSelectOpts={typeSelectOpts}
                          electrolyzerSelectOpts={
                            electrolyzerSelectOpts
                          }
                          disabled={disabled}
                        />
                        <Grid item>
                          {eventsFormValues.downtimeEvents.length >
                            0 && (
                            <IconButton
                              onClick={() =>
                                removeDowntimeEventForm(idx)
                              }
                              aria-label="delete downtime event form"
                              disabled={disabled}
                            >
                              <ClearIcon />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    ),
                  )}
                </Grid>
                <StyledButton
                  size="small"
                  startIcon={<AddOutlined />}
                  onClick={addDowntimeEventForm}
                  disabled={disabled}
                >
                  Add downtime
                </StyledButton>
              </CardContent>
            </Card>
            {requestState.status === RequestStatuses.rejected && (
              <Box marginTop={0.1} marginBottom={0.1}>
                {requestState.errors &&
                  requestState.errors.map((error, index) => (
                    <Alert
                      sx={{ padding: '0.1em' }}
                      key={index}
                      severity="error"
                    >
                      {error.message}
                    </Alert>
                  ))}
              </Box>
            )}
            {isFulfilled && (
              <Alert severity="success">
                Successfully added event
              </Alert>
            )}
            {error && <Alert severity="error">{error}</Alert>}
            <Stack direction="row" spacing={2}>
              <Button variant="outlined" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="contained"
                color="primary"
                sx={{ ml: 0.5 }}
                type="submit"
                disabled={disabled}
              >
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  'Confirm'
                )}
              </Button>
            </Stack>
          </ModalForm>
        </ModalContents>
      </Modal>
    </>
  );
}
