import agent from 'axios';
import qs from 'qs';
import * as R from 'ramda';
import { handleApiError } from '../common/apiErrorHandler';
import {
  NewPowerCostEstimate,
  PowerCostEstimateViewModel,
} from './powerCostEstimateTypes';

interface FetchPowerCostEstimateResponse {
  data: { powerCostEstimate: PowerCostEstimateViewModel };
}

export async function fetchPowerCostEstimate(
  newPowerCostEstimate: NewPowerCostEstimate,
) {
  return await handleApiError<{
    data: PowerCostEstimateViewModel;
  }>(
    () =>
      agent
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/generation-units/${
            newPowerCostEstimate.generationUnitId
          }/power-cost-estimate?${qs.stringify(
            R.omit(['generationUnitId'], newPowerCostEstimate),
          )}`,
        )
        .then(({ data }: FetchPowerCostEstimateResponse) => ({
          data: data && data.powerCostEstimate,
        })),
    'Failed to create a power cost estimate',
  );
}
