import React from 'react';
import MuiChip from '@mui/material/Chip';
import TimerIcon from '@mui/icons-material/Timer';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import { styled } from '@mui/material/styles';
import { EventStatus } from '../../state/events/eventTypes';

export enum EventChipStatus {
  'GREEN',
  'AMBER',
  'RED',
}
export const fromEventStatus = (eventStatus: EventStatus) => {
  switch (eventStatus) {
    case 'CONFIRMED':
    case 'STARTED':
      return EventChipStatus.GREEN;
    case 'DRAFT':
      return EventChipStatus.AMBER;
    default:
      return EventChipStatus.RED;
  }
};
interface Props {
  label: string;
  status?: EventChipStatus;
}
const Chip = styled(MuiChip, {
  shouldForwardProp: (prop) => prop !== 'status',
})<{ status: EventChipStatus | undefined }>(({ status }) => {
  if (status === EventChipStatus.GREEN) {
    return {
      '& .MuiChip-icon': {
        color: '#5af2a1',
      },
    };
  }
  if (status === EventChipStatus.RED) {
    return {
      '& .MuiChip-icon': {
        color: '#d2222d',
      },
    };
  }
  if (status === EventChipStatus.AMBER) {
    return {
      '& .MuiChip-icon': {
        color: '#ffbf00',
      },
    };
  }
  return {};
});

const EventStatusChip = ({ label, status }: Props) => {
  return (
    <Chip
      variant="outlined"
      size="small"
      label={label}
      status={status}
      {...(status === EventChipStatus.GREEN
        ? { icon: <TimerIcon /> }
        : {})}
      {...(status === EventChipStatus.RED
        ? { icon: <TimerOffIcon /> }
        : {})}
      {...(status === EventChipStatus.AMBER
        ? { icon: <TimerIcon /> }
        : {})}
    />
  );
};

export default EventStatusChip;
