import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useAppSelector } from '../../hooks/common';
import { getElectrolyzersByGenerationUnitId } from '../../state/electrolyzers/electrolyzerSlice';
import AddElectrolyzer from '../electrolyzer/AddElectrolyzer';
import ElectrolyzerTelemetry from './ElectrolyzerTelemetry';
import DataCard from '../common/DataCard';
import ElectrolyzerSpecs from './ElectrolyzerSpecs';

interface Props {
  generationUnitId: string;
}

const ElectrolyzerActivityCard = ({ generationUnitId }: Props) => {
  const electrolyzers = useAppSelector((state) =>
    getElectrolyzersByGenerationUnitId(state, generationUnitId),
  );

  return (
    <Grid container direction="column">
      <Grid item>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography
              component="h3"
              variant="subtitle1"
              gutterBottom
            >
              Electrolyzers
            </Typography>
          </Grid>
          <Grid item>
            <AddElectrolyzer generationUnitId={generationUnitId} />
          </Grid>
        </Grid>
      </Grid>
      {electrolyzers &&
        electrolyzers.map((electrolyzer) => (
          <Grid item key={electrolyzer.id} sx={{ paddingTop: 1 }}>
            <DataCard>
              <Grid container direction="row">
                <Grid item xs={12}>
                  <Grid container direction="row">
                    <Grid item xs={10}>
                      <Typography component="h4" variant="h4">
                        {electrolyzer.manufacturer}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Grid container justifyContent="flex-end">
                        <ElectrolyzerSpecs
                          electrolyzerId={electrolyzer.id}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <ElectrolyzerTelemetry
                    electrolyzer={electrolyzer}
                  />{' '}
                </Grid>
              </Grid>
            </DataCard>
          </Grid>
        ))}
    </Grid>
  );
};

export default ElectrolyzerActivityCard;
