import { H2MeasurementUnit } from '../../common/types';

const KG_TO_NM3_CONVERSION_RATE = 11.126;
export const convertToNm3 = (value: number) =>
  Math.round(value * KG_TO_NM3_CONVERSION_RATE);

export const convertCostPerKgToCostPerNm3 = (costPerKg: number) =>
  costPerKg / KG_TO_NM3_CONVERSION_RATE;

export const convertToCostPerUserUnit = (
  costPerKg: number,
  userUnit: H2MeasurementUnit,
) =>
  userUnit === H2MeasurementUnit.NM3
    ? convertCostPerKgToCostPerNm3(costPerKg)
    : costPerKg;

export const convertToUserUnit = (
  value: number,
  userUnit: H2MeasurementUnit,
) =>
  userUnit === H2MeasurementUnit.NM3 ? convertToNm3(value) : value;
