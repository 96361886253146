import dayjs from 'dayjs';
import { useTheme } from '@mui/material/styles';
import * as R from 'ramda';
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import fullWidthChartStyles from '../common/fullWidthChartStyles';
import { useAppSelector } from '../../hooks/common';
import { getUserValuesUnit } from '../../state/user/userSlice';
import { ProductionPlanChartData } from '../../state/common/types';
import ChartTooltip from './ChartTooltip';
import { H2MeasurementUnit } from '../../common/types';

interface Props {
  data: ProductionPlanChartData;
  xAxisValues?: number[] | null;
}

const renderTooltip =
  (valuesUnit: string) =>
  ({ active, payload, label }: TooltipProps<number, string>) => {
    if (active && payload) {
      const productionRateData = payload.find(
        (item) => item.dataKey === 'productionRate',
      );

      return (
        <ChartTooltip>
          <p className="label">
            Date: {dayjs(label).format('ddd DD MMM')}
            <br />
            Time: {dayjs(label).format('HH:mm')}
            <br />
            {productionRateData && (
              <>
                Production rate: {productionRateData.value}{' '}
                {valuesUnit}/hr <br />
              </>
            )}
          </p>
        </ChartTooltip>
      );
    }
    return <></>;
  };

const ProductionChart = ({ data, xAxisValues }: Props) => {
  const theme = useTheme();

  const userValuesUnit = useAppSelector(getUserValuesUnit);
  const valuesUnit =
    userValuesUnit === H2MeasurementUnit.NM3
      ? H2MeasurementUnit.NM3
      : userValuesUnit;

  const { productionRateData } = data;
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart {...fullWidthChartStyles()}>
        <CartesianGrid
          strokeWidth={0.5}
          strokeDasharray="2 2"
          stroke={theme.palette.grey[500]}
        />
        {xAxisValues ? (
          <XAxis
            domain={[xAxisValues[0], R.last(xAxisValues) as number]}
            type="number"
            ticks={xAxisValues}
            stroke={theme.palette.grey[300]}
            dataKey="periodStart"
            allowDataOverflow={true}
            tickFormatter={(date) => dayjs(date).format('HH:mm ddd')}
            allowDuplicatedCategory={false}
          />
        ) : (
          <XAxis
            stroke={theme.palette.grey[300]}
            dataKey="periodStart"
            tickFormatter={(date) => dayjs(date).format('HH:mm ddd')}
            allowDuplicatedCategory={false}
          />
        )}
        <YAxis
          yAxisId="productionRateAxis"
          stroke={theme.palette.grey[300]}
        />
        <Tooltip
          content={renderTooltip(valuesUnit)}
          wrapperStyle={{ outline: 'none' }}
        />
        <Legend
          payload={[
            {
              value: `Production rate (${valuesUnit}/hr)`,
              type: 'line',
              color: theme.palette.primary.main,
            },
          ]}
        />
        <Line
          yAxisId="productionRateAxis"
          name={`Production rate (${valuesUnit}/hr)`}
          type="stepAfter"
          dataKey="productionRate"
          stroke={theme.palette.primary.main}
          dot={false}
          activeDot={{ fill: theme.palette.secondary.light }}
          role="production-rate-line"
          data={productionRateData}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ProductionChart;
