import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAppSelector } from '../hooks/common';
import EventsTable from '../components/events/EventsTable';
import { getSelectedGenerationUnit } from '../state/generationUnits/generationUnitsSlice';
import Stack from '@mui/material/Stack';
import AddEvents from '../components/events/AddEvents';
import RollingSchedule from '../components/events/RollingSchedule';
import PlanEventTimes from '../components/events/eventTimePlanning/PlanEventTimes';

const Events = () => {
  const unit = useAppSelector(getSelectedGenerationUnit);
  const theme = useTheme();

  if (unit) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h1"
            component="h1"
            gutterBottom
            align="center"
          >
            Events
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <RollingSchedule generationUnit={unit} />
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{ paddingTop: theme.spacing(3) }}
          >
            <PlanEventTimes generationUnitId={unit.id} />
            <AddEvents generationUnitId={unit.id} />
            {/* <HaltProduction generationUnitId={unit.id} /> Made invisible until https://trello.com/c/030Ty9D0/734-resume-production-button */}
          </Stack>
        </Grid>
        <EventsTable generationUnit={unit} />
      </Grid>
    );
  }

  return <></>;
};

export default Events;
