import React from 'react';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useAppSelector } from '../../hooks/common';
import { getGenerator } from '../../state/generationUnits/generationUnitsSlice';
import { ModalContents } from '../common/modalStyles';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import UpdateGenerationUnit from './UpdateGenerationUnit';

interface Props {
  generationUnitId: string;
  open: boolean;
  handleClose: () => void;
}

const balanceOfPlantDescription = `Balance of plant (BOP) refers to all the supporting components and auxiliary systems 
                                   of a hydrogen generation unit, other than the electrolyzer itself. This includes the 
                                   power required by the compressor, control systems and storage units.`;

const GenerationUnitSpecsModal = ({
  open,
  handleClose,
  generationUnitId,
}: Props) => {
  const generationUnit = useAppSelector((state) =>
    getGenerator(state, generationUnitId),
  );

  const modalBody = (
    <ModalContents>
      {generationUnit ? (
        <Stack spacing={1}>
          <Grid container justifyContent="flex-end">
            <UpdateGenerationUnit generationUnit={generationUnit} />
          </Grid>
          <Typography variant="body1">
            <strong>Renewable Incentive</strong>
            <span> (pence per kg): </span>
            {generationUnit.renewableIncentivePencePerKg}
          </Typography>
          <Tooltip
            title={
              <Typography variant="body1">
                {balanceOfPlantDescription}
              </Typography>
            }
          >
            <Typography variant="body1">
              <strong>Max power required for balance of plant</strong>
              <span> (kW): </span>
              {generationUnit.maxBalanceOfPlantKw}
            </Typography>
          </Tooltip>
          <Typography variant="body1">
            <strong>Max grid import power</strong>
            <span> (kW): </span>
            {generationUnit.maxGridImportKw}
          </Typography>
          <Typography variant="body1" component="div">
            <strong>
              Email list for production issue notifications:
            </strong>
            {generationUnit.productionIssueEmailList && (
              <ul>
                {generationUnit.productionIssueEmailList
                  .split(',')
                  .map((email) => (
                    <li key={email}>{email}</li>
                  ))}
              </ul>
            )}
            {!generationUnit.productionIssueEmailList && (
              <p>No email addresses configured</p>
            )}
          </Typography>
          <Typography variant="body1" component="div">
            <strong>Forecast sources:</strong>
            <ul>
              {generationUnit.forecastSources.map(({ name, id }) => (
                <li key={id}>{name}</li>
              ))}
            </ul>
          </Typography>
          <Stack direction="row" justifyContent="end">
            <Button variant="outlined" onClick={handleClose}>
              {'Close'}
            </Button>
          </Stack>
        </Stack>
      ) : (
        <Typography variant="body1">
          Generation unit not found
        </Typography>
      )}
    </ModalContents>
  );

  return (
    <Modal open={open} onClose={handleClose}>
      {modalBody}
    </Modal>
  );
};

export default GenerationUnitSpecsModal;
