import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { fetchPowerCostEstimate } from './powerCostEstimateAPI';
import { RequestStatuses, RequestState } from '../requestTypes';
import {
  NewPowerCostEstimate,
  PowerCostEstimateViewModel,
  TransformedPowerCostEstimate,
} from './powerCostEstimateTypes';
import { RootState } from '../../app/store';
import { ProductionInterval } from '../common/types';
import { selectGenerationUnitById } from '../generationUnits/generationUnitsSlice';
import { RejectValue } from '../common/errorTypes';
import { executeWithRejectValue } from '../common/executeWithRejectValue';
import transformToRollingScheduleChartProductionData, {
  transformToProductionPlanChartData,
} from '../rollingSchedules/scheduleProductionTransformer';
import * as R from 'ramda';
import { convertToNm3 } from '../common/valueUnitConvertor';
import { H2MeasurementUnit } from '../../common/types';
import { convertToCostPerUserUnit } from '../common/valueUnitConvertor';
import { roundToOneDp } from '../common/rounder';

interface PowerCostEstimateState {
  fetchPowerCostEstimateState: RequestState;
  estimate: PowerCostEstimateViewModel | null;
}

const initialState = {
  estimate: null,
  fetchPowerCostEstimateState: {
    status: RequestStatuses.idle,
    errors: [],
  },
};

export const fetchPowerCostEstimateAsync = createAsyncThunk<
  PowerCostEstimateViewModel,
  NewPowerCostEstimate,
  RejectValue
>(
  'powerCostEstimate/fetchPowerCostEstimate',
  async (newPowerCostEstimate, { rejectWithValue }) => {
    return await executeWithRejectValue(async () => {
      const response = await fetchPowerCostEstimate(
        newPowerCostEstimate,
      );
      return response.data;
    }, rejectWithValue);
  },
);

export const powerCostEstimateSlice = createSlice({
  name: 'powerCostEstimate',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchPowerCostEstimateAsync.pending,
        (state: PowerCostEstimateState) => {
          state.fetchPowerCostEstimateState = {
            status: RequestStatuses.pending,
            errors: [],
          };
        },
      )
      .addCase(
        fetchPowerCostEstimateAsync.fulfilled,
        (
          state: PowerCostEstimateState,
          action: PayloadAction<PowerCostEstimateViewModel>,
        ) => {
          state.estimate = action.payload;

          state.fetchPowerCostEstimateState = {
            status: RequestStatuses.fulfilled,
            errors: [],
          };
        },
      )
      .addCase(
        fetchPowerCostEstimateAsync.rejected,
        (state: PowerCostEstimateState, action) => {
          state.estimate = null;

          state.fetchPowerCostEstimateState = {
            status: RequestStatuses.rejected,
            errors: action.payload?.errors,
          };
        },
      )
      .addCase(
        selectGenerationUnitById,
        (state: PowerCostEstimateState) => {
          state.estimate = null;

          state.fetchPowerCostEstimateState = {
            status: RequestStatuses.idle,
            errors: [],
          };
        },
      );
  },
});

export const getFetchPowerCostEstimateState = (
  state: RootState,
): RequestState =>
  state.powerCostEstimate.fetchPowerCostEstimateState;

export const getPowerCostEstimate = (
  state: RootState,
  valuesUnit: H2MeasurementUnit,
): TransformedPowerCostEstimate | null => {
  if (state.powerCostEstimate.estimate) {
    const {
      costPerKg,
      costPerKgBeforeIncentiveDeduction,
      totalCost,
      totalCostBeforeIncentiveDeduction,
      totalIncentiveValue,
      production,
      totalIncentiveKg,
    } = state.powerCostEstimate.estimate;

    const unitConvertedProduction =
      valuesUnit === H2MeasurementUnit.NM3
        ? convertPowerCostEstimateDataToNm3(production)
        : production;

    const costPerUnit = convertToCostPerUserUnit(
      costPerKg,
      valuesUnit,
    );

    const costPerUnitBeforeIncentiveDeduction =
      convertToCostPerUserUnit(
        costPerKgBeforeIncentiveDeduction,
        valuesUnit,
      );

    return {
      costPerUnit,
      costPerUnitBeforeIncentiveDeduction,
      totalCost,
      totalCostBeforeIncentiveDeduction,
      totalIncentiveValue,
      totalIncentiveKg: roundToOneDp(totalIncentiveKg),
      productionPlanChartData: R.pipe(
        transformToRollingScheduleChartProductionData,
        transformToProductionPlanChartData,
      )(unitConvertedProduction, null),
    };
  }

  return null;
};

export const convertPowerCostEstimateDataToNm3 = (
  production: ProductionInterval[],
) =>
  production.map((interval) => ({
    ...interval,
    actions: interval.actions.map((action) => ({
      ...action,
      productionRate: convertToNm3(action.productionRate),
    })),
  }));

export default powerCostEstimateSlice.reducer;
