import React from 'react';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';

import StorageUnitSpecsModal from './StorageUnitSpecsModal';

interface Props {
  storageUnitId: string;
  generationUnitId: string;
}

const StorageUnitSpecs = ({
  storageUnitId,
  generationUnitId,
}: Props) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Grid container justifyContent="flex-end">
      <Tooltip title="View technical specs">
        <IconButton size="small" onClick={() => setOpen(true)}>
          <SettingsIcon />
        </IconButton>
      </Tooltip>
      <StorageUnitSpecsModal
        open={open}
        handleClose={() => setOpen(false)}
        storageUnitId={storageUnitId}
        generationUnitId={generationUnitId}
      />
    </Grid>
  );
};

export default StorageUnitSpecs;
