import React from 'react';

import { useAppDispatch, useAppSelector } from '../../hooks/common';
import EntityModal from '../common/EntityModal';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import {
  addOfftakerAsync,
  getAddOfftakerState,
  clearAddOfftakerStatus,
} from '../../state/offtakers/offtakerSlice';

const initialFormValues = {
  name: '',
  abbreviation: '',
};

const AddOfftaker = () => {
  const dispatch = useAppDispatch();
  const requestState = useAppSelector(getAddOfftakerState);
  const [formValues, setFormValues] =
    React.useState(initialFormValues);

  return (
    <div>
      <EntityModal
        btnConfig={{ label: 'Add offtaker' }}
        title="Add a new offtaker"
        titleId="add-offtaker-title"
        requestState={requestState}
        clearStatusAction={() => {
          dispatch(clearAddOfftakerStatus());
        }}
        clearFormValues={() => setFormValues(initialFormValues)}
        handleSubmit={() => {
          const { name, abbreviation } = formValues;

          dispatch(
            addOfftakerAsync({
              name,
              abbreviation,
            }),
          );
        }}
      >
        <Stack spacing={3}>
          <TextField
            fullWidth
            id="offtaker-name"
            required
            onInput={(e) =>
              setFormValues({
                ...formValues,
                name: (e.target as HTMLTextAreaElement).value,
              })
            }
            label="Name"
            variant="filled"
            value={formValues.name}
          />
          <TextField
            fullWidth
            id="offtaker-abbreviation"
            required
            onInput={(e) =>
              setFormValues({
                ...formValues,
                abbreviation: (e.target as HTMLTextAreaElement).value,
              })
            }
            label="Abbreviation"
            variant="filled"
            value={formValues.abbreviation}
          />
        </Stack>
      </EntityModal>
    </div>
  );
};

export default AddOfftaker;
