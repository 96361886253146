import { RequestFailedError } from './requestFailedError';

export const executeWithRejectValue = async (
  method: Function,
  rejectWithValue: Function,
) => {
  try {
    return await method();
  } catch (err) {
    if (err instanceof RequestFailedError) {
      return rejectWithValue({
        errors: (err as RequestFailedError).errors,
      });
    }
    return rejectWithValue({
      errors: [{ message: (err as Error).message }],
    });
  }
};
